import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Chart from "react-apexcharts";

function AdminPanal() {
  const options1 = {
    chart: {
      type: "bar",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["January", "February", "March", "April", "June", "July"],
      labels: {
        style: {
          colors: "#ffffff", // Set x-axis labels to white
        },
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        style: {
          colors: "#ffffff", // Set x-axis labels to white
        },
      },
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "24px",
        color: "#ffffff",
      },
    },
    fill: {
      colors: ["#156082"],
    },
    grid: {
      borderColor: "#ffffff",
    },
    theme: {
      mode: "transparent",
      palette: "palette2",
    },
  };
  const options2 = {
    chart: {
      type: "bar",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["January", "February", "March", "April", "June", "July"],
      labels: {
        style: {
          colors: "#ffffff", // Set x-axis labels to white
        },
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        style: {
          colors: "#ffffff", // Set x-axis labels to white
        },
      },
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "24px",
        color: "#ffffff",
      },
    },
    fill: {
      colors: ["#FFC000"],
    },
    grid: {
      borderColor: "#656174",
    },
    theme: {
      mode: "transparent",
      palette: "palette2",
    },
  };
  const options3 = {
    chart: {
      type: "bar",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["January", "February", "March", "April", "June", "July"],
      labels: {
        style: {
          colors: "#ffffff", // Set x-axis labels to white
        },
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        style: {
          colors: "#ffffff", // Set x-axis labels to white
        },
      },
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "24px",
        color: "#ffffff",
      },
    },
    fill: {
      colors: ["#00B0F0"],
    },
    grid: {
      borderColor: "#656174",
    },
    theme: {
      mode: "transparent",
      palette: "palette2",
    },
  };
  const options4 = {
    chart: {
      type: "bar",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["January", "February", "March", "April", "June", "July"],
      labels: {
        style: {
          colors: "#ffffff", // Set x-axis labels to white
        },
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        style: {
          colors: "#ffffff", // Set x-axis labels to white
        },
      },
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "24px",
        color: "#ffffff",
      },
    },
    fill: {
      colors: ["#00B0F0"],
    },
    grid: {
      borderColor: "#656174",
    },
    theme: {
      mode: "transparent",
      palette: "palette2",
    },
  };
  const options5 = {
    chart: {
      type: "bar",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        colors: {
          ranges: [
            { from: -100, to: 0, color: "#00B0F0" }, // Red for negative values
            { from: 0.1, to: 100, color: "#00B0F0" }, // Green for positive values
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["January", "February", "March", "April", "June", "July"],
      labels: {
        style: {
          colors: "#ffffff", // Set x-axis labels to white
        },
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        style: {
          colors: "#ffffff", // Set x-axis labels to white
        },
      },
    },
    title: {
      text: "",
      align: "center",
      style: {
        fontSize: "24px",
        color: "#ffffff",
      },
    },
    fill: {
      colors: ["#FFC000"],
    },
    grid: {
      borderColor: "#656174",
    },
    theme: {
      mode: "transparent",
      palette: "palette2",
    },
  };
  const options6 = {
    chart: {
      type: "bar",
      stacked: true, // Enable stacked bars
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        colors: {
          ranges: [
            { from: -100, to: 0, color: "#00B0F0" }, // Red for negative values
            { from: 0.1, to: 100, color: "#E97132" }, // Green for positive values
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["January", "February", "March", "April", "June", "July"],
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
    },
    grid: {
      borderColor: "#656174",
    },
    theme: {
      mode: "transparent",
      palette: "palette2",
    },
    colors: ["#FF4560", "#00E396"], // Colors for the stacks
  };
  const series1 = [
    {
      name: "Registrations",
      data: [25, 20, 50, 55, 80, 94],
    },
  ];
  const series2 = [
    {
      name: "Registrations",
      data: [8, 25, 8, 5, 11, 18],
    },
  ];
  const series3 = [
    {
      name: "Registrations",
      data: [5, 12, 8, 8, 11, 18],
    },
  ];
  const series4 = [
    {
      name: "Registrations",
      data: [5, 10, 15, 20, 25, 30],
    },
  ];
  const series5 = [
    {
      name: "Transactions",
      data: [20, -30, 40, -20, -5, 40],
    },
  ];
  const series6 = [
    {
      name: "Positive",
      data: [10, 50, 15, 35, 5, 60], // Positive values
    },
    {
      name: "Negative",
      data: [-5, -5, -5, -5, -5, -5], // Negative values
    },
  ];
  return (
    <div className="newbody">
      <div className="newsignup-div px-4">
        <Navbar />
        <p className="font-sora text-[16px] font-normal leading-[22.4px] mt-24 mb-3 tracking-[0.3em] text-primary text-center">
          Admin Panel
        </p>
        <p className="font-sora text-[44px] font-semibold leading-[49.28px] tracking-[-0.02em] text-center">
          User Statistics{" "}
        </p>
        <div className="grid grid-cols-1 mt-16 md:grid-cols-3">
          <div>
            <div className="w-[85%] mx-auto bg-[#36314E] rounded-xl py-3">
              <p className="font-sora text-[24px] font-semibold leading-[26.88px] tracking-[-0.02em] text-center">
                Registrations
              </p>
            </div>
            <div
              className="chart-container col-span-1"
              style={{
                background: "transparent",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <Chart
                options={options1}
                series={series1}
                type="bar"
                height={350}
                className="transparent"
              />
              <div
                className="buttons-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  style={{
                    background: "#ffcc00",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    margin: "0 0",
                    border: "none",
                  }}
                >
                  Main
                </button>
                <button
                  style={{
                    background: "transparent",
                    color: "#ffffff",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    margin: "0 0",
                    border: "none",
                  }}
                >
                  Weekly
                </button>
                <button
                  style={{
                    background: "transparent",
                    color: "#ffffff",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    margin: "0 0",
                    border: "none",
                  }}
                >
                  Monthly
                </button>
                <button
                  style={{
                    background: "transparent",
                    color: "#ffffff",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    margin: "0 0",
                    border: "none",
                  }}
                >
                  Yearly
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="w-[85%] mx-auto bg-[#36314E] rounded-xl py-3">
              <p className="font-sora text-[24px] font-semibold leading-[26.88px] tracking-[-0.02em] text-center">
                Active Users
              </p>
            </div>
            <div
              className="chart-container col-span-1"
              style={{
                background: "transparent",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <Chart
                options={options2}
                series={series2}
                type="bar"
                height={350}
                className="transparent"
              />
              <div
                className="buttons-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  style={{
                    background: "#ffcc00",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    margin: "0 0",
                    border: "none",
                  }}
                >
                  Main
                </button>
                <button
                  style={{
                    background: "transparent",
                    color: "#ffffff",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    margin: "0 0",
                    border: "none",
                  }}
                >
                  Weekly
                </button>
                <button
                  style={{
                    background: "transparent",
                    color: "#ffffff",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    margin: "0 0",
                    border: "none",
                  }}
                >
                  Monthly
                </button>
                <button
                  style={{
                    background: "transparent",
                    color: "#ffffff",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    margin: "0 0",
                    border: "none",
                  }}
                >
                  Yearly
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="w-[85%] mx-auto bg-[#36314E] rounded-xl py-3">
              <p className="font-sora text-[24px] font-semibold leading-[26.88px] tracking-[-0.02em] text-center">
                Redemption Rate
              </p>
            </div>
            <div
              className="chart-container col-span-1"
              style={{
                background: "transparent",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <Chart
                options={options3}
                series={series3}
                type="bar"
                height={350}
                className="transparent"
              />
              <div
                className="buttons-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  style={{
                    background: "#ffcc00",
                    color: "#000",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    margin: "0 0",
                    border: "none",
                  }}
                >
                  Main
                </button>
                <button
                  style={{
                    background: "transparent",
                    color: "#ffffff",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    margin: "0 0",
                    border: "none",
                  }}
                >
                  Weekly
                </button>
                <button
                  style={{
                    background: "transparent",
                    color: "#ffffff",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    margin: "0 0",
                    border: "none",
                  }}
                >
                  Monthly
                </button>
                <button
                  style={{
                    background: "transparent",
                    color: "#ffffff",
                    borderRadius: "20px",
                    padding: "4px 8px",
                    margin: "0 0",
                    border: "none",
                  }}
                >
                  Yearly
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[93%] h-[646px] mt-8 border-[#4E4761] border rounded-2xl bg-[#04000F] mx-auto">
        <p className="font-sora text-[25px] sm:text-[36px] md:text-[44px] pt-4 font-semibold leading-[49.28px] tracking-[-0.02em] text-center">
          User Management Dashboard
        </p>
      </div>
      <p className="font-sora text-[16px] font-normal leading-[22.4px] mt-24 mb-3 tracking-[0.3em] text-primary text-center">
        Admin Panel
      </p>
      <p className="font-sora text-[44px] font-semibold leading-[49.28px] tracking-[-0.02em] text-center">
        Financial Statistics{" "}
      </p>
      <div className="grid grid-cols-1 mt-16 md:grid-cols-3">
        <div>
          <div className="w-[85%] mx-auto bg-[#36314E] rounded-xl py-3">
            <p className="font-sora text-[24px] font-semibold leading-[26.88px] tracking-[-0.02em] text-center">
              Deposits
            </p>
          </div>
          <div
            className="chart-container col-span-1"
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <Chart
              options={options4}
              series={series4}
              type="bar"
              height={350}
              className="transparent"
            />
            <div
              className="buttons-container"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  background: "#ffcc00",
                  color: "#000",
                  borderRadius: "20px",
                  padding: "4px 8px",
                  margin: "0 0",
                  border: "none",
                }}
              >
                Main
              </button>
              <button
                style={{
                  background: "transparent",
                  color: "#ffffff",
                  borderRadius: "20px",
                  padding: "4px 8px",
                  margin: "0 0",
                  border: "none",
                }}
              >
                Weekly
              </button>
              <button
                style={{
                  background: "transparent",
                  color: "#ffffff",
                  borderRadius: "20px",
                  padding: "4px 8px",
                  margin: "0 0",
                  border: "none",
                }}
              >
                Monthly
              </button>
              <button
                style={{
                  background: "transparent",
                  color: "#ffffff",
                  borderRadius: "20px",
                  padding: "4px 8px",
                  margin: "0 0",
                  border: "none",
                }}
              >
                Yearly
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="w-[85%] mx-auto bg-[#36314E] rounded-xl py-3">
            <p className="font-sora text-[24px] font-semibold leading-[26.88px] tracking-[-0.02em] text-center">
              Withdraws
            </p>
          </div>
          <div
            className="chart-container col-span-1"
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <Chart
              options={options5}
              series={series5}
              type="bar"
              height={350}
              className="transparent"
            />
            <div
              className="buttons-container"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  background: "#ffcc00",
                  color: "#000",
                  borderRadius: "20px",
                  padding: "4px 8px",
                  margin: "0 0",
                  border: "none",
                }}
              >
                Main
              </button>
              <button
                style={{
                  background: "transparent",
                  color: "#ffffff",
                  borderRadius: "20px",
                  padding: "4px 8px",
                  margin: "0 0",
                  border: "none",
                }}
              >
                Weekly
              </button>
              <button
                style={{
                  background: "transparent",
                  color: "#ffffff",
                  borderRadius: "20px",
                  padding: "4px 8px",
                  margin: "0 0",
                  border: "none",
                }}
              >
                Monthly
              </button>
              <button
                style={{
                  background: "transparent",
                  color: "#ffffff",
                  borderRadius: "20px",
                  padding: "4px 8px",
                  margin: "0 0",
                  border: "none",
                }}
              >
                Yearly
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className="w-[85%] mx-auto bg-[#36314E] rounded-xl py-3">
            <p className="font-sora text-[24px] font-semibold leading-[26.88px] tracking-[-0.02em] text-center">
              Assets under Managmeent
            </p>
          </div>
          <div
            className="chart-container col-span-1"
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <Chart
              options={options6}
              series={series6}
              type="bar"
              height={350}
              className="transparent"
            />
            <div
              className="buttons-container"
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  background: "#ffcc00",
                  color: "#000",
                  borderRadius: "20px",
                  padding: "4px 8px",
                  margin: "0 0",
                  border: "none",
                }}
              >
                Main
              </button>
              <button
                style={{
                  background: "transparent",
                  color: "#ffffff",
                  borderRadius: "20px",
                  padding: "4px 8px",
                  margin: "0 0",
                  border: "none",
                }}
              >
                Weekly
              </button>
              <button
                style={{
                  background: "transparent",
                  color: "#ffffff",
                  borderRadius: "20px",
                  padding: "4px 8px",
                  margin: "0 0",
                  border: "none",
                }}
              >
                Monthly
              </button>
              <button
                style={{
                  background: "transparent",
                  color: "#ffffff",
                  borderRadius: "20px",
                  padding: "4px 8px",
                  margin: "0 0",
                  border: "none",
                }}
              >
                Yearly
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AdminPanal;
