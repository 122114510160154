import React, { useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
  const [icons, setIcons] = useState({
    linkdin: "/icon/linkdin.svg",
    facebook: "/icon/facebook.svg",
    twitter: "/icon/twitter.svg",
    youtube: "/icon/youtube.svg",
  });

  const handleMouseEnter = (iconName) => {
    setIcons((prevIcons) => ({
      ...prevIcons,
      [iconName]: `/icon/${iconName}1.svg`,
    }));
  };

  const handleMouseLeave = (iconName) => {
    setIcons((prevIcons) => ({
      ...prevIcons,
      [iconName]: `/icon/${iconName}.svg`,
    }));
  };

  return (
    <div className="sm:flex-row bg-[#07001D] flex flex-col pl-2 sm:ml-0 pt-20 pb-20 justify-around">
      <div>
        <img src="/icon/logo.svg" alt="logo img" />
      </div>
      <div className="mt-6 flex-col min-w-52">
        <p className="font-helvetica mt-2 text-[16px] font-semibold leading-[17.92px] text-left">
          Legal
        </p>
        <Link to="/guideline">
          <p className="  hover:text-primary hover:text-[17px] transition duration-300 ease-in-out font-helvetica mt-2 text-[16px] font-normal text-[#9B9B9B] leading-[24px] text-left">
            Data Protection Guideline
          </p>
        </Link>
        <Link to="/termsandconditions">
          <p className=" hover:text-primary hover:text-[17px] transition duration-300 font-helvetica mt-2 text-[16px] font-normal text-[#9B9B9B] leading-[24px] text-left">
            Terms and Conditions
          </p>
        </Link>
        <Link to="/privacypolicy">
          <p className=" hover:text-primary hover:text-[17px] transition duration-300 font-helvetica mt-2 text-[16px] font-normal text-[#9B9B9B] leading-[24px] text-left">
            Privacy Policy
          </p>
        </Link>
        <Link to="/imprint">
          <p className=" hover:text-primary hover:text-[17px] transition duration-300 font-helvetica mt-2 text-[16px] font-normal text-[#9B9B9B] leading-[24px] text-left">
            Imprint
          </p>
        </Link>
        <Link to="/cookies">
          <p className=" hover:text-primary hover:text-[17px] transition duration-300 font-helvetica mt-2 text-[16px] font-normal text-[#9B9B9B] leading-[24px] text-left">
            Cookie Policy
          </p>
        </Link>
        <Link to="/disclaimer">
          <p className=" hover:text-primary hover:text-[17px] transition duration-300 font-helvetica mt-2 text-[16px] font-normal text-[#9B9B9B] leading-[24px] text-left">
            Disclaimer
          </p>
        </Link>
      </div>
      <div className="mt-6 min-w-32">
        <p className="font-helvetica text-[16px] font-semibold leading-[17.92px] tracking-[-0.32px] text-left">
          Navigation
        </p>
        <Link to="/">
          <p className="hover:text-primary hover:text-[17px] transition duration-300 font-helvetica mt-2 text-[16px] font-normal text-[#9B9B9B] leading-[24px] text-left">
            Home
          </p>
        </Link>
        <Link to="/dashboard">
          <p className="hover:text-primary hover:text-[17px] transition duration-300 font-helvetica mt-2 text-[16px] font-normal text-[#9B9B9B] leading-[24px] text-left">
            Dashboard
          </p>
        </Link>
        <div className="flex sm:mt-44 ">
          <Link to="https://www.linkedin.com/" target="_blank">
            <img
              className="hover:text-primary hover:text-[18px] cursor-pointer transition duration-300 ease-in-out"
              src={icons.linkdin}
              alt="LinkdIn logo"
              width={35}
              onMouseEnter={() => handleMouseEnter("linkdin")}
              onMouseLeave={() => handleMouseLeave("linkdin")}
            />
          </Link>
          <Link to="https://www.facebook.com/" target="_blank">
            <img
              className="hover:text-primary hover:text-[18px] cursor-pointer transition duration-300 ease-in-out"
              src={icons.facebook}
              alt="Facebook logo"
              width={35}
              onMouseEnter={() => handleMouseEnter("facebook")}
              onMouseLeave={() => handleMouseLeave("facebook")}
            />
          </Link>
          <Link to="https://www.twitter.com/" target="_blank">
            <img
              className="hover:text-primary hover:text-[18px] cursor-pointer transition duration-300 ease-in-out"
              src={icons.twitter}
              alt="Twitter logo"
              width={35}
              onMouseEnter={() => handleMouseEnter("twitter")}
              onMouseLeave={() => handleMouseLeave("twitter")}
            />
          </Link>
          <Link to="https://www.youtube.com/" target="_blank">
            <img
              className="hover:text-primary hover:text-[18px] cursor-pointer transition duration-300 ease-in-out"
              src={icons.youtube}
              alt="YouTube logo"
              width={35}
              onMouseEnter={() => handleMouseEnter("youtube")}
              onMouseLeave={() => handleMouseLeave("youtube")}
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
