import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { URL } from "../constants/constant";
import Notification from "./Notification";
import { UserContext } from "../contexts/UserContext";
import { postRequest } from "../backendServices/ApiCalls";
function WaitlistCard() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: user?.name || "",
    email: user?.email || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleWaitlistCard = () => {
    // Check for internet connectivity
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return; 
    }
  
    setLoading(true); // Set loading state to true
  
    // Post request using the postRequest utility function
    postRequest("yes",
      "waitlist/joinwaitlist", // API endpoint
      formData, // Payload (formData)
      (response) => {
        const data = response?.data; // Extract response data
  
        // Handle success response
        if (response.status === 200) {
          Notification.showNotification(
            "success",
            "Success",
            "User joined Successfully waitlist"
          );
  
          // Navigate to home after a short delay
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          // Show error notification if status is not 200
          Notification.showNotification(
            "error",
            "Error",
            data.message || "An error occurred. Please try again." // Improved error message
          );
  
          console.error("Signup failed:", data.message || "Unknown error");
        }
        setLoading(false)
      },
      (error) => {
        // Handle error from the postRequest call
        Notification.showNotification("error", "Error", "An unexpected error occurred. Please try again later.");
        console.error("An error occurred:", error);
        setLoading(false)
      }
    )
  };
  
  // const handleWaitlistCard = async () => {
  //   if (!navigator.onLine) {
  //     Notification.showNotification(
  //       "error",
  //       "Network Error",
  //       "Please check your internet connection and try again."
  //     );
  //     return; 
  //   }
  //   setLoading(true);
  //   try {
  //     const response = await fetch(`${URL}/waitlist/joinwaitlist`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     const data = await response.json();

  //     if (response.ok) {
  //       // console.log(data);
  //       if (data) {
  //         Notification.showNotification(
  //           "success",
  //           "Success ",
  //           "User joined Successfully waitlist"
  //         );
  //         setTimeout(() => {
  //           navigate("/");
  //         }, 1000);
  //       }
  //     } else {
  //       Notification.showNotification(
  //         "error",
  //         "Error ",
  //         data.message   
  //       );

  //       console.error("Signup failed:", data.message || "Unknown error");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleWaitlistCard();
    }
  };

  
  return (
    <div className="mx-6">
      <h1 className="text-accent tracking-tighter  mt-16 mb-0 text-center text-6xl font-bold leading-normal">
        Join Waitlist
      </h1>
      <div className="max-w-[488px] mt-6 pt-6 h-[381px] bg-[#222222]  mx-auto rounded-3xl">
        <div className="w-16 h-16  rounded-2xl items-center mx-auto justify-center flex bg-primary bg-opacity-20">
          <img
            src="/icon/yellowProfile.png"
            className="w-6 h-6"
            alt="Profile"
          />
        </div>
        <div className="mx-8 mt-4">
          <p className="text-accent tracking-tighter mb-0 text-lg font-semibold leading-relaxed block">
            Name
          </p>
          <input
            type="text"
            value={formData.name}
            name="name"
            onChange={handleChange}
            placeholder="Enter name "
            onKeyPress={handleKeyPress}
            className="border mt-1 px-2 h-12 rounded-xl border-[#383838] w-full bg-transparent text-accent"
          />
        </div>
        <div className="mx-8 mt-4">
          <p className="text-accent tracking-tighter mb-0 text-lg font-semibold leading-relaxed block">
            Email
          </p>
          <input
            type="email"
            value={formData.email}
            name="email"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            placeholder="Enter email"
            className="border mt-1 px-2 h-12 rounded-xl border-[#383838] w-full bg-transparent text-accent"
          />
        </div>

        <button
          onClick={handleWaitlistCard}
          disabled={loading}
          onKeyPress={handleKeyPress}
          className={`bg-primary w-[87%] mt-4 text-black font-medium rounded-xl text-md py-4 mx-8 flex items-center justify-center ${
            loading ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          {" "}
          {loading ? (
            <img
              src="/icon/loader.svg"
              alt="Loading..."
              className="w-7 mx-auto h-7 animate-spin"
            />
          ) : (
            "Join Waitlist"
          )}
        </button>
      </div>
    </div>
  );
}

export default WaitlistCard;
