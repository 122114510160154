import React, { useEffect } from "react";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import ElevateBusiness from "../../components/ElevateBusiness";
import AccountFaqs from "../../components/AccountFaqs";
function Guideline() {
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="account">
      <div>
        <div className="bg-account">
          <Navbar />
          <div className="mx-4 sm:mx-12 md:mx-20 mt-20 2xl:w-4/5 2xl:mx-auto">
            <h1 className="font-sora text-5xl font-bold leading-[62.72px] tracking-[-0.03em] text-center">
              Data Protection Guideline
            </h1>
            <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-12">
            Introduction and Legal framework
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            This file includes all the relevant information regarding the
            requirements for an imprint and to fulfill the legal requirements
            for documentation, transparency and to remain within the current
            legal framework provided by the State of Germany as well as on
            European level.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Based on our core principles we see it as our duty to our customers
            to have a fully transparent framework for the use of our services.
            We want to point out that to our best efforts, we try to be
            compliant with all the herein mentioned as well as not mentioned
            standards, which are applicable for our area of services. However,
            to all our efforts, we cannot always guarantee to be compliant with
            every standard applicable in each country of business.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
          Legal Framework
          </h2>
          <p className="font-sora text-base font-bold leading-6 text-[#9B9B9B]">
          Privacy Policy
                </p>
            <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
              Responsible Parties:
            </h2>
            <div className="w-[100%] sm:w-[70%] md::w-[50%] mt-4 ">
              <div className="grid grid-cols-2 gap-2">
                <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]">
                  Name:
                </p>
                <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]">
                  AlgoX Capital GbR
                </p>

                <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]">
                  Street and Number:
                </p>
                <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]">
                  Sperberweg 13
                </p>

                <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]">
                  Zip Code and City:
                </p>
                <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]">
                  83556 Griesstätt
                </p>

                <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]">
                  Mobile:
                </p>
                <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]">
                  +49 (0) 151 6400 9853
                </p>

                <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]">
                  E-Mail:
                </p>
                <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]">
                  Support@algox.capital
                </p>

                <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]">
                  Executive Managers:
                </p>
                <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]">
                  Philipp Deutsch, Florian ABC, Lion Degen, Arben Kuqi
                </p>
                <p className="font-sora text-base font-bold leading-6 text-[#9B9B9B]">
                  Dated: July 2024
                </p>
              </div>
            </div>

            <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
              Basic information on data processing and legal basis
            </h2>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              This privacy policy explains the type, scope and purpose of the
              processing of personal data within our online offering and the
              associated websites, functions and content (hereinafter jointly
              referred to as "online offering" or "website"). The data
              protection policy applies regardless of the domains, systems,
              platforms and devices used (e.g. desktop or mobile) on which the
              online offering is executed.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              The terms used, such as "personal data" or their "processing",
              refer to the definitions in Art. 4 of the General Data Protection
              Regulation (GDPR).
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              The personal data of users processed in the context of this online
              offer includes inventory data (e.g., names and addresses of
              customers), contract data (e.g., services used, names of clerks,
              payment information), usage data (e.g., the visited web pages of
              our online offer, interest in our products) and content data
              (e.g., entries in the contact form).
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              The term "user" includes all categories of data subjects affected
              by data processing. These include our business partners,
              customers, interested parties and other visitors to our online
              offering.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              We only process users' personal data in compliance with the
              relevant data protection regulations. This means that user data
              will only be processed if we have legal permission to do so. This
              means if the data processing is necessary for the provision of our
              contractual services (e.g. processing of orders) and online
              services or is required by law, if the user has given their
              consent, as well as on the basis of our legitimate interests (i.e.
              interest in the analysis, optimization and economic operation and
              security of our online offer within the meaning of Art. 6 para. 1
              lit. f. GDPR, in particular when measuring reach, creating
              profiles for advertising and marketing purposes and collecting
              access data and using the services of third-party providers.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              We would like to point out that the legal basis for consent is
              Art. 6 para. 1 lit. a. and Art. 7 GDPR, the legal basis for
              processing to fulfill our services and carry out contractual
              measures is Art. 6 para. 1 lit. b. GDPR, the legal basis for
              processing to fulfill our legal obligations is Art. 6 para. 1 lit.
              c. GDPR. GDPR, the legal basis for processing for the fulfillment
              of our legal obligations Art. 6 para. 1 lit. c. GDPR, and the
              legal basis for the processing for the protection of our
              legitimate interests Art. 6 para. 1 lit. f. GDPR is.
            </p>
            <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
              Security measures
            </h2>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              We take organizational, contractual and technical security
              measures in accordance with the state of the art technology to
              ensure that the provisions of data protection laws are complied
              with and to protect the data processed by us against accidental or
              intentional manipulation, loss, destruction or access by
              unauthorized persons.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              The security measures include, in particular, the encrypted
              transmission of data between your browser and our server.
            </p>
          </div>
        </div>
        <div className=" mx-4 sm:mx-12 md:mx-20 mt-20 2xl:w-4/5 2xl:mx-auto">
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Disclosure of data to third parties and third-party providers
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Data is only passed on to third parties within the framework of
            legal requirements. We only pass on user data to third parties if
            this is necessary, for example, on the basis of Art. 6 para. 1 lit.
            b) GDPR for contractual purposes or on the basis of legitimate
            interests pursuant to Art. 6 para. 1 lit. f. GDPR in the economic
            and effective operation of our business operations.
          </p>
          <div className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            <p>
              If we use subcontractors to provide our services, we take
              appropriate legal precautions and appropriate technical and
              organizational measures to ensure the protection of personal data
              in accordance with the relevant legal regulations. We reserve the
              right, in the course of the audit by our partners, Bybit, Binance,
              Bitget and Nexo.io, to pass on personal data collected in the
              course of the KYC procedure, described in more detail under
              A.B.C., for random checks of compliance with our obligations when
              onboarding customers. This is done so that we, as a service
              provider, comply with the obligations incumbent on us and are
              regularly checked by our partners in connection with compliance
              with these obligations. The background to this is to avoid money
              laundering, terrorist financing and other unauthorized payments.
              We reserve the right to forward personal data in order to protect
              the public interest. For the processing of personal data, we refer
              to the guidelines of our partners on the collection and processing
              of personal data. The guidelines on the processing of personal
              data of our partners are attached:
            </p>
            <ul className="list-disc pl-5">
              <li>Bybit</li>
              <li>Binance</li>
              <li>Bitget</li>
              <li>Nexo</li>
            </ul>
          </div>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If content, tools or other means from other providers (hereinafter
            jointly referred to as "third-party providers") are used within the
            scope of this privacy policy and their registered office is located
            in a third country, it is to be assumed that data is transferred to
            the countries in which the third-party providers are based. Third
            countries are countries in which the GDPR is not directly applicable
            law, i.e. generally countries outside the EU or the European
            Economic Area. The transfer of data to third countries takes place
            either if there is an adequate level of data protection, user
            consent or other legal permission.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Provision of contractual services
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            We process user data (e.g., names and addresses as well as contact
            data of users), contract data (e.g., services used, names of contact
            persons, payment information) for the purpose of fulfilling our
            contractual obligations and services in accordance with Art. 6 para.
            1 lit. b. GDPR.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Users can optionally create an user account. As part of the
            execution of an order process, the required mandatory information is
            communicated to the users. The user accounts are not public and
            cannot be indexed by search engines. If users have canceled their
            user account, their data will be deleted with regard to the user
            account, subject to their retention is necessary for commercial or
            tax law reasons in accordance with Art. 6 para. 1 lit. c GDPR. It is
            the responsibility of users to back up their data before the end of
            the contract in the event of termination. We are entitled to
            irretrievably delete all user data stored during the term of the
            contract.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            As part of the registration and renewed logins as well as the use of
            our online services, we store the IP address and the time of the
            respective user action. This data is stored on the basis of our
            legitimate interests and those of the user in protection against
            misuse and other unauthorized use.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            This data is not passed on to third parties unless it is necessary
            to pursue our claims or there is a legal obligation to do so in
            accordance with Art. 6 para. 1 lit. c GDPR. We process usage data
            (e.g. the websites visited on our online offering, interest in our
            products) and content data (e.g. entries in the contact form or user
            profile) for advertising purposes in a user profile, e.g. to display
            product information to users based on the services they have
            previously used.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Contacting Us
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            When contacting us (via contact form or e-mail), the user's details
            are processed to process the contact request and its handling in
            accordance with Art. 6 para. 1 lit. b) GDPR.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The user's details may be stored in our customer relationship
            management system ("CRM system") or comparable inquiry organization.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Collection of access data and log files
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            We collect on the basis of our legitimate interests within the
            meaning of Art. 6 para. 1 lit. f. GDPR, data about every access to
            the server on which this service is located (so-called server log
            files). The access data includes the name of the website accessed,
            file, date and time of access, amount of data transferred,
            notification of successful access, browser type and version, the
            user's operating system, referrer URL (the previously visited page),
            IP address and the requesting provider.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Log file information are stored for a maximum of seven days for
            security reasons (e.g. to investigate misuse or fraud) and then
            deleted. Data whose further storage is required for evidentiary
            purposes is excluded from deletion until the respective incident has
            been finally clarified.{" "}
          </p>
          <h1 className="font-sora text-5xl font-bold mt-10 leading-[62.72px] tracking-[-0.03em] text-center">
            Cookies & Reach measurement
          </h1>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Unsere Social–Media Auftritte, Datenverarbeitung durch soziale
            Netzwerke
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            We maintain publicly accessible profiles on social networks. The
            individual social networks we use are listed below.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Social networks such as Facebook, Google+ etc. can generally analyse
            your user behaviour comprehensively when you visit their website or
            a website with integrated social media content (e.g. like buttons or
            advertising banners). Visiting our social media presences triggers
            numerous data protection-relevant processing operations. In detail:{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If you are logged into your social media account and visit our
            social media presence, the operator of the social media portal can
            assign this visit to your user account. However, your personal data
            may also be collected if you are not logged in or do not have an
            account with the respective social media portal. In this case, this
            data is collected, for example, via cookies that are stored on your
            device or by recording your IP address.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            With the help of the data collected in this way, the operators of
            the social media portals can create user profiles in which your
            preferences and interests are stored. In this way, interest-based
            advertising can be displayed to you inside and outside the
            respective social media presence. If you have an account with the
            respective social network, the interest-based advertising can be
            displayed on all devices on which you are logged in or have been
            logged in.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Please also note that we cannot track all processing operations on
            the social media portals. Depending on the provider, further
            processing operations may therefore be carried out by the operators
            of the social media portals. For details, please refer to the terms
            of use and data protection provisions of the respective social media
            portals.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            LinkedIn
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            We have a profile on LinkedIn. The provider is LinkedIn Ireland
            Unlimited, Wilton Plaza, Wilton Place, Dublin 2, Ireland. LinkedIn
            is certified in accordance with the EU-US Privacy Shield. LinkedIn
            uses advertising cookies.
          </p>
          <p className="font-sora text-base font-bold leading-6 text-[#9B9B9B] mt-4 text-left">
            If you want to deactivate the LinkedIn-Ad-Cookies, please use the
            following link: 
            <a
              href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
              className="underline text-[#42396D]"
              target="_blank"
            >
               Linkedin deactivate .
            </a>
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Details regarding the handling of your personal data can be found in
            the data protection declaration of LinkedIn: {" "}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              className="underline text-[#42396D]"
              target="_blank"
            >
              Linkedin Data Protection Declar­ation.
            </a>
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Other social media:
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Other social platforms may be used in the future. This results in
            the same rights and the same general conditions as, for example,
            when using LinkedIn. We reserve the right not to obtain renewed
            consent from the customer when using other social media in the
            future. In doing so, we rely on the GDPR, according to which the
            mere reference to the then legal framework is sufficient in the same
            circumstances.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Legal basis
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Our social media presence is intended to ensure the widest possible
            presence on the Internet. This is a legitimate interest within the
            meaning of Art. 6 para. 1 lit. f GDPR. The analysis processes
            initiated by the social networks may be based on different legal
            bases, which must be specified by the operators of the social
            networks (e.g. consent within the meaning of Art. 6 para. 1 lit. a
            GDPR).
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Controller and assertion of rights
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If you visit one of our social media sites (e.g. LinkedIn or our own
            WebApp (
            <a
              href="https://www.AlgoX.Capital"
              className="underline text-[#42396D]"
              target="_blank"
            >
              www.AlgoX.Capital{" "}
            </a>
            )) , we are jointly responsible with the operator of the social
            media platform for the data processing operations triggered during
            this visit. In principle, you can assert your rights (information,
            correction, deletion, restriction of processing, data
            transferability and objection) both against us and against the
            operator of the respective social media portal (e.g. LinkedIn).
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Please note that, despite the joint responsibility with the social
            media portal operators, we do not have full influence on the data
            processing operations of the social media portals.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Our options are largely determined by the company policy of the
            respective provider.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Storage period
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The data collected directly by us via the social media presence will
            be deleted from our systems as soon as the purpose for its storage
            no longer applies, you request us to delete it, revoke your consent
            to storage or the purpose for data storage no longer applies. Stored
            cookies remain on your end device until you delete them. Mandatory
            statutory provisions - in particular retention periods - remain
            unaffected.{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            We have no influence on the storage period of your data, which is
            stored by the operators of social networks for their own purposes.
            For details, please contact the operators of the social networks
            directly (e.g. in their privacy policy, see below).{" "}
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Rights of data subjects{" "}
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If your personal data is processed, you are a data subject within
            the meaning of the GDPR and you have the following rights against us
            as the responsible party:
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Right to information{" "}
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            You can request confirmation as to whether personal data concerning
            you is being processed by us.
          </p>
          <div className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            <p className="mb-3">
              If such processing is taking place, you can request the following
              information from us:
            </p>
            <ol className="pl-5 custom-counter">
              <li className="mb-3">
                {" "}
                the purposes for which the personal data is processed
              </li>
              <li className="mb-3">
                {" "}
                the categories of personal data being processed
              </li>
              <li className="mb-3">
                {" "}
                the recipients or categories of recipients to whom the personal
                data concerning you have been or will be disclosed
              </li>
              <li className="mb-3">
                {" "}
                the planned duration of storage of the personal data concerning
                you or, if specific information on this is not possible,
                criteria for determining the duration of storage
              </li>
              <li className="mb-3">
                {" "}
                the existence of a right to rectification or erasure of personal
                data concerning you, a right to restriction of processing by us
                or a right to object to such processing
              </li>
              <li className="mb-3">
                {" "}
                the existence of a right to file a complaint with a supervisory
                authority
              </li>
              <li className="mb-3">
                {" "}
                all available information about the origin of the data if the
                personal data is not collected from the data subject
              </li>
              <li className="mb-3">
                {" "}
                the existence of automated decision-making including profiling
                in accordance with Art. 22 (1) and (4) GDPR and - at least in
                these cases - meaningful information on the logic involved and
                the scope and intended effects of such processing for the data
                subject.
              </li>
            </ol>
          </div>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            You have the right to request information as to whether the personal
            data concerning you is transferred to a third country or to an
            international organization. In this context, you may request to be
            informed of the appropriate safeguards pursuant to Art. 46 GDPR in
            connection with the transfer.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Right to rectification{" "}
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            You have a right to rectification and/or completion against us if
            the processed personal data concerning you is incorrect or
            incomplete. We must make the correction without delay.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Right to restriction of processing{" "}
          </h2>
          <div className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            <p className="mb-3">
              You may request the restriction of the processing of your personal
              data under the following conditions:
            </p>
            <ol className="pl-5 custom-counter">
              <li className="mb-3">
                {" "}
                if you deny the accuracy of the personal data concerning you for
                a period that enables us to verify the accuracy of the personal
                data{" "}
              </li>
              <li className="mb-3">
                {" "}
                the processing is unlawful, and you oppose the deletion of the
                personal data and request the restriction of their use instead{" "}
              </li>
              <li className="mb-3">
                {" "}
                we no longer need the personal data for the purposes of the
                processing, but they are required by you for the establishment,
                exercise or defence of legal claims or
              </li>
              <li className="mb-3">
                {" "}
                if you have objected to the processing pursuant to Art. 21 (1)
                GDPR and it is not yet certain whether our legitimate reasons
                outweigh your reasons.
              </li>
            </ol>
          </div>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If the processing of personal data concerning you has been
            restricted, this data - apart from its storage - may only be
            processed with your consent or for the establishment, exercise or
            defence of legal claims or for the protection of the rights of
            another natural or legal person or for reasons of important public
            interest of the Union or of a Member State.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If the restriction of processing has been restricted in accordance
            with the above conditions, you will be informed by us before the
            restriction is lifted.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Right to deletion{" "}
          </h2>
          <div className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            <p className="mb-3">
              You have the right to obtain from us the erasure of personal data
              concerning you without undue delay and we are obliged to erase
              such data without undue delay where one of the following reasons
              applies:
            </p>
            <ol className="pl-5 custom-counter">
              <li className="mb-3">
                {" "}
                The personal data concerning you are no longer necessary in
                relation to the purposes for which they were collected or
                otherwise processed.{" "}
              </li>
              <li className="mb-3">
                {" "}
                You revoke your consent on which the processing was based
                pursuant to Art. 6 para. 1 lit. a, or Art. 9 para. 2 lit. a GDPR
                and there is no other legal basis for the processing.{" "}
              </li>
              <li className="mb-3">
                {" "}
                You object to the processing pursuant to Art. 21 para. 1 GDPR
                and there are no overriding legitimate reasons for the
                processing, or you object to the processing pursuant to Art. 21
                para. 2 GDPR.
              </li>
              <li className="mb-3">
                {" "}
                The personal data concerning you have been processed unlawfully.
              </li>
              <li className="mb-3">
                {" "}
                The deletion of personal data concerning you is necessary to
                fulfill a legal obligation under Union law or the law of the
                Member States to which we are subject.
              </li>
              <li className="mb-3">
                {" "}
                The personal data concerning you have been collected in relation
                to the offer of information society services referred to in Art.
                8 (1) GDPR.
              </li>
            </ol>
          </div>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Information to third parties{" "}
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If we have made the personal data concerning you, public and we are
            obliged to delete it in accordance with Art. 17 para. 1 GDPR, we
            will take appropriate measures, including technical measures,
            considering the available technology and the implementation costs,
            to inform the data controllers who process the personal data that
            you as the data subject have requested the deletion of all links to
            this personal data or of copies or replications of this personal
            data.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Exceptiones{" "}
          </h2>
          <div className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            <p className="mb-3">
              The right to deletion does not exist if the processing is
              necessary
            </p>
            <ol className="pl-5 custom-counter">
              <li className="mb-3">
                {" "}
                for exercising the right of freedom of expression and
                information{" "}
              </li>
              <li className="mb-3">
                {" "}
                for compliance with a legal obligation which requires processing
                by Union or Member State law to which we are subject or for the
                performance of a task carried out in the public interest or in
                the exercise of official authority vested in us{" "}
              </li>
              <li className="mb-3">
                {" "}
                for reasons of public interest in public health pursuant to Art.
                9 para. 2 lit. h and i and Art. 9 para. 3 GDPR
              </li>
              <li className="mb-3">
                {" "}
                for archiving purposes in the public interest, scientific or
                historical research purposes or statistical purposes in
                accordance with Art. 89 para. 1 GDPR, insofar as the right
                referred to in section a) is likely to render impossible or
                seriously impair the achievement of the objectives of that
                processing, or
              </li>
              <li className="mb-3">
                {" "}
                for the establishment, exercise or defence of legal claims.
              </li>
            </ol>
          </div>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Right to information{" "}
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If you have exercised your right to rectification, erasure or
            restriction of processing personal data, we are obliged to notify
            all recipients to whom the personal data concerning you have been
            disclosed of this rectification or erasure of the data or
            restriction of processing, unless this proves impossible or involves
            a disproportionate effort.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            You have the right to be informed about these recipients..
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Right to data transferability{" "}
          </h2>
          <div className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            <p className="mb-3">
              You have the right to receive the personal data concerning you,
              which you have provided to us, in a structured, commonly used and
              machine-readable format. You also have the right to transmit this
              data to another controller without hindrance from us, provided
              that
            </p>
            <ol className="pl-5 custom-counter">
              <li className="mb-3">
                {" "}
                the processing is based on consent pursuant to Art. 6 para. 1
                lit. a GDPR or Art. 9 para. 2 lit. a GDPR or on a contract
                pursuant to Art. 6 para. 1 lit. b GDPR and{" "}
              </li>
              <li className="mb-3">
                {" "}
                the processing is carried out by automated means.{" "}
              </li>
            </ol>
          </div>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            In exercising this right, you also have the right to obtain that the
            personal data concerning you be transferred directly by us to
            another controller, insofar as this is technically feasible. The
            freedoms and rights of other persons must not be affected by this.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The right to data transferability does not apply to the processing
            of personal data necessary for the performance of a task carried out
            in the public interest or in the exercise of official authority
            vested in us.{" "}
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Right to object{" "}
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            You have the right to object, for reasons arising from your
            particular situation, at any time to the processing of personal data
            concerning you which is carried out on the basis of Art. 6 para. 1
            lit. e or f GDPR; this also applies to profiling based on these
            provisions.{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            We will no longer process the personal data concerning you unless we
            can demonstrate compelling legitimate reasons for the processing
            which override your interests, rights and freedoms or the processing
            serves the establishment, exercise or defence of legal claims.{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If your personal data is processed for direct marketing purposes,
            you have the right to object at any time to the processing of your
            personal data for such marketing, which includes profiling to the
            extent that it is related to such direct marketing.{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If you object to processing for direct marketing purposes, the
            personal data concerning you will no longer be processed for these
            purposes.{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            In connection with the use of information technology services, you
            have the option of exercising your right to object by automated
            means using technical specifications.{" "}
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Right to revoke the declaration of consent under data protection law{" "}
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            You have the right to withdraw your declaration of consent under
            data protection law at any time. The withdrawal of consent does not
            affect the lawfulness of processing based on consent before its
            withdrawal.{" "}
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Automated decision-making in individual cases, including profiling{" "}
          </h2>
          <div className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            <p className="mb-3">
              You have the right not to be subject to a decision based solely on
              automated processing, including profiling, which produces legal
              effects concerning you or similarly significantly affects you.
              This does not apply if the decision
            </p>
            <ol className="pl-5 custom-counter">
              <li className="mb-3">
                {" "}
                is necessary for the conclusion or performance of a contract
                between you and the controller{" "}
              </li>
              <li className="mb-3">
                {" "}
                is authorized by Union or Member State law to which the
                controller is subject, and which also lays down suitable
                measures to safeguard your rights and freedoms and legitimate
                interests; or{" "}
              </li>
              <li className="mb-3"> with your express consent.</li>
            </ol>
          </div>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            However, these decisions may not be based on special categories of
            personal data pursuant to Art. 9 para. 1 GDPR, unless Art. 9 para. 2
            lit. a or g applies, and appropriate measures have been taken to
            protect the rights and freedoms as well as your legitimate interests{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Regarding the cases referred to in (1) and (3), we shall take
            reasonable measures to safeguard the rights and freedoms and your
            legitimate interests, including at least the right to obtain the
            intervention of a person authorized by us, to express your own
            position and to challenge the decision.{" "}
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Right to file a complaint with a supervisory authority{" "}
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Without prejudice to any other administrative or judicial remedy,
            you have the right to file a complaint with a supervisory authority,
            in the Member State of your habitual residence, place of work or
            place of the alleged infringement if you consider that the
            processing of personal data relating to you infringes the provisions
            of data protection law.{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The supervisory authority with which the complaint has been filed
            shall inform the complainant of the status and results of the
            complaint, including the possibility of a judicial remedy pursuant
            to Art. 78 GDPR.{" "}
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Deletion of data{" "}
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The data stored by us will be deleted as soon as it is no longer
            required for its intended purpose and the deletion does not conflict
            with any statutory retention obligations. If the user's data is not
            deleted because it is required for other and legally permissible
            purposes, its processing will be restricted. This means that the
            data is blocked and not processed for other purposes. This applies,
            for example, to user data that must be retained for commercial or
            tax law reasons.{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            In accordance with legal requirements, data is stored for 6 years in
            accordance with Section 257 (1) HGB (commercial books, inventories,
            opening balance sheets, annual financial statements, commercial
            letters, accounting documents, etc.) and for 10 years in accordance
            with Section 147 (1) AO (books, records, management reports,
            accounting documents, commercial and business letters, documents
            relevant for taxation, etc.).{" "}
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Right to object{" "}
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Users can object to the future processing of their personal data in
            accordance with the legal requirements at any time. The objection
            can be made against processing for direct marketing purposes.{" "}
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Changes to the privacy policy{" "}
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            We reserve the right to amend the data protection declaration to
            adapt it to changed legal situations or in the event of changes to
            the service and data processing. However, this only applies
            regarding declarations on data processing. If user consent is
            required or components of the privacy policy contain provisions of
            the contractual relationship with the users, the changes will only
            be made with the consent of the users.{" "}
          </p>

          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Users are requested to inform themselves regularly about the content
            of the privacy policy.
          </p>
        </div>
      </div>
      {/* <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[16px] md:hidden text-[#B0A4FF] mt-64 sm:mt-[45%] 2xl:mt-96 font-normal leading-[22.4px] tracking-[4.8px] text-center"
      >
        HAVE QUESTION?
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[44px] mt-3 md:hidden font-semibold leading-[49.28px] tracking-[-0.88px] text-center"
      >
        Frequently Asked <br />
        Question
      </p>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="lg:hidden"
      >
        <AccountFaqs question="What payment methods do you accept?" />
        <AccountFaqs question="Are there any hidden fees?" />
        <AccountFaqs question="Is there a free trial available?" />
        <AccountFaqs question="How do I get started?" />
        <AccountFaqs question="How do I access my account?" />
      </div> */}

      {/* <ElevateBusiness /> */}

      <Footer />
    </div>
  );
}
export default Guideline;
