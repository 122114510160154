// // src/api/index.js
// import axios from 'axios';

// const API_BASE_URL = 'https://algobackend.m5networkhub.com'; // Update as needed

// const apiClient = axios.create({
//   baseURL: API_BASE_URL,
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// // Function to update authorization header
// function updateAuthorizationHeader() {
//   const token = localStorage.getItem('token');
//   if (token) {
//     apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//   } else {
//     delete apiClient.defaults.headers.common['Authorization'];
//   }
// }

// // Register user
// export const registerUser = async (params) => {
//   updateAuthorizationHeader();
//   return apiClient.post('/auth/register', params);
// };

// // Authenticate user
// export const authenticateUser = async (params) => {
//   updateAuthorizationHeader();
//   return apiClient.post('/auth', params);
// };

// // Verify login
// export const loginVerify = async (params) => {
//   updateAuthorizationHeader();
//   return apiClient.post('/auth/verify', params);
// };

import axios from "axios";
import { URL } from "../constants/constant";

function updateAuthorizationHeaderauth() {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common["authorization"] = "Bearer " + token;
}
// const API_BASE_URL = "http://localhost:8000"
const API_BASE_URL = "https://backendapp.algox.capital"
function updateAuthorizationHeader() {
  const token = localStorage.getItem("token");
  if (!token) {
    handleLogout();
  } else {
    axios.defaults.headers.common["authorization"] = "Bearer " + token;
  }
}
function handleLogout() {
  localStorage.removeItem("token");
  window.location.href = "/login";
}

export function registerUser(params, callback, errorCallback) {
  axios
    .post(`${URL}/auth/register`, params, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (callback) callback(response);
    })
    .catch((error) => {
      if (errorCallback) errorCallback(error);
    });
}


// Function to fetch user profile
export const fetchUserProfile = async (token) => {
  try {
    // const response = await axios.get('https://algobackend.m5networkhub.com/auth/profile', {
    const response = await axios.get(`${URL}/auth/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export function postRequest(auth,url, params, callback, errorCallback) {
  if(auth === "yes")
    {
      updateAuthorizationHeader();
    }
  axios.post(`${URL}/${url}`, params).then((response) => {

      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function getRequest(url, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(`${URL}/${url}`)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function postRequestauth(url, params, callback, errorCallback) {
  updateAuthorizationHeaderauth();
  axios
    .post(`${URL}/${url}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export const getNfgMerchantToken = async (callback, errorCallback) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${URL}/api/nfg/auth`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (callback) {
      callback(response); // Pass the response data to the callback
    }
  } catch (error) {
    // console.log('Error occurred while fetching the NFG token');
    if (errorCallback) {
      errorCallback(error); // Pass the error to the error callback
    }
  }
};

export const paymentCheckout = async (values, callback, errorCallback) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${URL}/api/nfg/checkout`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (callback) {
      callback(response); // Pass the response data to the callback
    }
  } catch (error) {
    // console.log('Error occurred while creating checkout',error);
    if (errorCallback) {
      errorCallback(error?.response?.data); // Pass the error to the error callback
    }
  }
};

export const fiatPaymentCheckout = async (values, callback, errorCallback) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${URL}/api/nfg//fiatcheckout`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (callback) {
      callback(response); // Pass the response data to the callback
    }
  } catch (error) {
    // console.log('Error occurred while creating fiat payment checkout',error);
    if (errorCallback) {
      errorCallback(error?.response?.data); // Pass the error to the error callback
    }
  }
};

export const getUserTransactionsList = async (
  userId,
  callback,
  errorCallback
) => {
  try {
    const token = localStorage.getItem("token");
    // console.log('values: ',userId)
    const response = await axios.post(
      `${URL}/api/nfg/user-transaction-list`,
      { userId: userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (callback) {
      callback(response.data); // Pass the response data to the callback
    }
  } catch (error) {
    // console.log('Error occurred while getting user transaction list',error.response.data);
    if (errorCallback) {
      errorCallback(error); // Pass the error to the error callback
    }
  }
};

export const getPackages = async (callback, errorCallback) => {
  try {
    const token = localStorage.getItem("token");
    // console.log('values: ',userId)
    const response = await axios.get(`${URL}/api/packages`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log('get packages: ',response)
    if (callback) {
      callback(response); // Pass the response data to the callback
    }
  } catch (error) {
    // console.log('Error occurred while ---- list',error);
    if (errorCallback) {
      errorCallback(error); // Pass the error to the error callback
    }
  }
};

// export const getUserTransactionsList = async (userId, onSuccess, onError) => {
//   const token = localStorage.getItem('token');

//   try {
//     const response = await axios.post(
//       'http://localhost:8000/api/nfg/user-transaction-list',
//       { userId: userId },
//       {
//         headers: {
//           Authorization: `Bearer ${token}`, // Include the token in the Authorization header
//         },
//       }
//     );
//     onSuccess(response.data); // Call the success callback with the data
//   } catch (error) {
//     onError(error); // Call the error callback with the error
//   }
// };
