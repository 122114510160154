import React, { useContext } from 'react'
import { UserContext } from '../contexts/UserContext'
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios'
import { getNfgMerchantToken } from '../backendServices/ApiCalls';


export default function PrivateRoute({ Component }) {
  const { user } = useContext(UserContext)

  const location = useLocation();
  const token = localStorage.getItem("token");

  // const handleError = (error) => {console.error('Failed to fetch NFG Merchant Token:', error);};

  // if (token) {
  //   getNfgMerchantToken(handleSuccess, handleError);
  // }
  if (user == null && token == null) {
    return <Navigate to="/" state={{ from: location.pathname }} replace />;
  }
  return <Component />;
}
