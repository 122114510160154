import React, { useState, useEffect, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import { yellow } from "@mui/material/colors";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import ElevateBusiness from "../../components/ElevateBusiness";
import AccountFaqs from "../../components/AccountFaqs";
import {
  fiatPaymentCheckout,
  getPackages,
  paymentCheckout,
} from "../../backendServices/ApiCalls";
import { UserContext } from "../../contexts/UserContext";
import { URL } from "../../constants/constant";
// import Loader from "../../components/Loader";
import BigLoader from "../../components/BigLoader";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
function PricingPlane() {
  const { user } = useContext(UserContext);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [error, setError] = useState(null);
  const [showPaymentDialog, setShowPaymentDialog] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isloading11, setIsLoading11] = useState(false);
  const [checkedStates, setCheckedStates] = useState({
    trading: false,
    dashboard: false,
    support: false,
    services: false,
  });

  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  // Handle checkbox change
  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckedStates((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const { name } = event.target;
      setCheckedStates((prevState) => ({
        ...prevState,
        [name]: !prevState[name],
      }));
    }
  };
  const myPlans = (
    price,
    fiatCurrency,
    productName,
    description,
    index,
    planId
  ) => {
    setIsLoading11(true);
    const clickId = user?._id;
    const values = {
      productName,
      description,
      price,
      fiatCurrency,
      userId: clickId,
      planId: planId,
    };

    setLoadingIndex(index);
    setError(null);

    paymentCheckout(
      values,
      (response) => {
        const url = `${response?.data?.checkoutUrl}?clickId=${clickId}`;
        window.location.href = url; // Open URL on the same page
        // window.open(url); // Open the link in a new tab

        setLoadingIndex(null);
        // setIsLoading11(false);
      },
      (error) => {
        // Error callback
        // console.error('Error during payment process:', error);

        // Set error message and stop loading
        // setError(error.message);
        setLoadingIndex(null); // Reset loading state
        setIsLoading11(false);
        Notification.showNotification("error", "Error", error?.message);
      }
    );
  };

  const handleCardOrGooglePay = (price, fiatCurrency, planId, name, pkgid) => {
    const values = {
      customId: user?._id,
      fiatAmount: price,
      fiatCurrency: fiatCurrency,
      colorCode: "D000F2",
      screenTitle: name,
      pkgid: pkgid,
    };

    // setLoadingIndex(index);
    setError(null);
    setIsLoading11(true);

    fiatPaymentCheckout(
      values,
      (response) => {
        // Success callback
        const url = `${response?.data?.fiatCheckoutUrl}`;
        window.location.href = url;
        // window.open(url); // Open the link in a new tab

        // Reset loading state after successful process
        setLoadingIndex(null);
        // setIsLoading11(false);
      },
      (error) => {
        // Error callback
        console.error("Error during fiat payment process:", error);

        // setError(error.message);
        setLoadingIndex(null);
        setIsLoading11(false);
        Notification.showNotification("error", "Error", error?.message);
      }
    );
  };

  const handlePaymentOption = (option) => {
    if (option === "crypto") {
      myPlans(
        selectedPlan.price,
        "USD",
        selectedPlan.productName,
        selectedPlan.description,
        selectedPlan.index,
        selectedPlan.planId
      );
    } else if (option === "card_googlepay") {
      handleCardOrGooglePay(
        selectedPlan.price,
        "USD",
        selectedPlan.planId,
        selectedPlan.productName,
        selectedPlan.planId
      );
    }
    setShowPaymentDialog(null);
  };

  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [plans, setPlans] = useState([]);

  const fetchPackages = () => {
    setIsLoading(true);
    getPackages(
      (response) => {
        setPlans(response?.data?.data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        Notification.showNotification(
          "error",
          "Error",
          "Failed To Fetch Packages Data"
        );
      }
    );
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  // const data = async () => {
  //   try{
  //     setIsLoading(true);
  //     const response = await fetch(`${URL}/api/packages`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     const data = await response.json();
  //     // console.log(data)
  //     setPlans(data?.data);
  //     setIsLoading(false);
  //   }catch(error){

  //   }
  // };
  // useEffect(() => {
  //   data();
  // }, []);

  const openPaymentDialog = (plan, index) => {
    setSelectedPlan({
      price: plan.amount,
      productName: plan.name,
      description: "Plan",
      index,
      planId: plan._id,
    });
    setShowPaymentDialog(true);
  };

  return (
    <div className="account">
      <div className="bg-account px-4">
        <Navbar />
        <p className="font-sora text-5xl font-bold mt-20 leading-tight tracking-tight text-center">
          Right Plan for Your <br /> Needs
        </p>

        {isLoading ? ( // Render loader if loading
          <div
            className="flex justify-center h-full items-center"
            style={{ height: "50vh" }}
          >
            <BigLoader />
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-6 mt-12  2xl:w-4/5 sm:mx-12 mx-4 md:mx-[3%] 2xl:mx-auto sm:grid-cols-2 md:grid-cols-4">
            <div className="bg-[#1F1B2C] rounded-3xl flex flex-col items-center p-2">
              <div className="bg-[#07001D] border border-[#383737] p-6 w-full rounded-3xl  max-w-xs">
                <div className="flex gap-4  md:my-auto my-9 lg:my-9 flex-wrap">
                  <img src="/icon/phone.png" className="size-16" />
                  <div>
                    {/* <p className="font-sora font-semibold mt-3 text-[16px] leading-[17.92px] tracking-[-0.02em] text-center">
                      Contact Sales!
                    </p> */}
                    <div className="flex gap-2 mt-2 my-auto">
                      <p className="font-sora text-sm font-normal leading-5 text-[#9B9B9B] ">
                        Get Started
                      </p>
                      <img src="/icon/arrow.png" className="size-2 my-auto" />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" justify-center">
                <div className="flex ">
                  {/* <Checkbox
                    name="trading"
                    checked={checkedStates.trading}
                    onKeyPress={handleKeyPress}
                    onChange={handleChange}
                    icon={
                      <span
                        style={{
                          width: 24,
                          height: 24,
                          border: `2px solid ${yellow[700]}`,
                          borderRadius: "50%",
                          backgroundColor: "#2C2C44",
                        }}
                      />
                    }
                    checkedIcon={
                      <span
                        style={{
                          width: 24,
                          height: 24,
                          borderRadius: "50%",
                          backgroundColor: yellow[700],
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#2C2C44"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          style={{ width: 16, height: 16 }}
                        >
                          <polyline points="20 6 9 17 4 12" />
                        </svg>
                      </span>
                    }
                    sx={{ marginTop: 3 }}
                  /> */}

                  <p className="font-sora font-semibold text-[16px] leading-[19.2px] tracking-[-0.02em] mt-9 text-start">
                    Access Trading Algorithms
                  </p>
                </div>

                <div className="flex  ">
                  {/* <Checkbox
                    name="dashboard"
                    checked={checkedStates.dashboard}
                    onKeyPress={handleKeyPress}
                    onChange={handleChange}
                    icon={
                      <span
                        style={{
                          width: 24,
                          height: 24,
                          border: `2px solid ${yellow[700]}`,
                          borderRadius: "50%",
                          backgroundColor: "#2C2C44",
                        }}
                      />
                    }
                    checkedIcon={
                      <span
                        style={{
                          width: 24,
                          height: 24,
                          borderRadius: "50%",
                          backgroundColor: yellow[700],
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#2C2C44"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          style={{ width: 16, height: 16 }}
                        >
                          <polyline points="20 6 9 17 4 12" />
                        </svg>
                      </span>
                    }
                    sx={{ marginTop: 3 }}
                  /> */}
                  <p className="font-sora font-semibold text-[16px] leading-[19.2px] tracking-[-0.02em] mt-9 text-start">
                    Access Dashboard
                  </p>
                </div>
                <p className="font-sora font-semibold text-[16px] leading-[19.2px] tracking-[-0.02em] mt-9 text-start">
                  Profit Commissions (0.00%)
                </p>
                <p className="font-sora font-semibold text-[16px] leading-[19.2px] tracking-[-0.02em] mt-9 text-start">
                  Broker Fee (0.35%)
                </p>
                <div className="flex  ">
                  {/* <Checkbox
                    name="support"
                    checked={checkedStates.support}
                    onKeyPress={handleKeyPress}
                    onChange={handleChange}
                    icon={
                      <span
                        style={{
                          width: 24,
                          height: 24,
                          border: `2px solid ${yellow[700]}`,
                          borderRadius: "50%",
                          backgroundColor: "#2C2C44",
                        }}
                      />
                    }
                    checkedIcon={
                      <span
                        style={{
                          width: 24,
                          height: 24,
                          borderRadius: "50%",
                          backgroundColor: yellow[700],
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#2C2C44"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          style={{ width: 16, height: 16 }}
                        >
                          <polyline points="20 6 9 17 4 12" />
                        </svg>
                      </span>
                    }
                    sx={{ marginTop: 3 }}
                  /> */}
                  <p className="font-sora font-semibold text-[16px] leading-[19.2px] tracking-[-0.02em] mt-9 text-start">
                    Premium Customer Support
                  </p>
                </div>

                <div className="flex  ">
                  {/* <Checkbox
                    name="services"
                    checked={checkedStates.services}
                    onKeyPress={handleKeyPress}
                    onChange={handleChange}
                    icon={
                      <span
                        style={{
                          width: 24,
                          height: 24,
                          border: `2px solid ${yellow[700]}`,
                          borderRadius: "50%",
                          backgroundColor: "#2C2C44",
                        }}
                      />
                    }
                    checkedIcon={
                      <span
                        style={{
                          width: 24,
                          height: 24,
                          borderRadius: "50%",
                          backgroundColor: yellow[700],
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#2C2C44"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          style={{ width: 16, height: 16 }}
                        >
                          <polyline points="20 6 9 17 4 12" />
                        </svg>
                      </span>
                    }
                    sx={{ marginTop: 3 }}
                  /> */}
                  <p className="font-sora font-semibold text-[16px] leading-[19.2px] tracking-[-0.02em] mt-9 text-start">
                    Access To New Services
                  </p>
                </div>
                
              </div>
            </div>
            {plans?.map((plan, i) => {
              const isComingSoon = plan._id === "1" || plan._id === "3"; // Identify packages that are "Coming Soon"
              return (
                <div
                  key={i}
                  className={`bg-[#1F1B2C] rounded-3xl flex flex-col items-center p-2 ${
                    isComingSoon ? "relative" : ""
                  }`}
                >
                  {isComingSoon && (
                    <div className="absolute inset-0 bg-black opacity-50 rounded-3xl flex justify-center items-center z-10">
                      <p className="text-white font-bold text-2xl">
                        Coming Soon
                      </p>
                    </div>
                  )}
                  <div
                    className={`bg-[#07001D] border border-[#383737] p-6 w-full rounded-3xl max-w-xs ${
                      isComingSoon ? "blur-md" : ""
                    }`}
                  >
                    <p className="font-sora text-base font-semibold leading-7 tracking-tight text-start capitalize">
                      {plan.name}
                    </p>
                    <div className="flex justify-start items-baseline mt-2">
                      <p className="font-sora text-2xl font-semibold leading-tight tracking-tight">
                        ${plan.amount}
                      </p>
                      <p className="font-sora text-sm font-normal leading-5 text-[#9B9B9B] ml-2">
                        /month
                      </p>
                    </div>
                    <button
                      className={`bg-primary w-full py-4 rounded-md mt-4 ${
                        loadingIndex !== null
                          ? "cursor-not-allowed opacity-50"
                          : "hover:bg-primary hover:text-white"
                      }`}
                      onClick={() =>
                        loadingIndex === null && openPaymentDialog(plan, i)
                      }
                      disabled={loadingIndex !== null}
                    >
                      <p className="font-sans text-[14px] text-black font-semibold leading-[23.94px] hover:text-white">
                        Choose Plan
                      </p>
                    </button>
                  </div>
                  <div className="flex flex-col justify-center ">
                    {plan._id === "1" && (
                      <>
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray ", fontSize: 40, marginTop: 3 ,filter: "blur(4px)" }}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray", fontSize: 40, marginTop: 3 ,filter: "blur(4px)" }}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray", fontSize: 40, marginTop: 3 ,filter: "blur(4px)" }}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray", fontSize: 40, marginTop: 3 ,filter: "blur(4px)" }}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray", fontSize: 40, marginTop: 3 ,filter: "blur(4px)" }}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray", fontSize: 40, marginTop: 3 ,filter: "blur(4px)" }}
                        />
                      </>
                    )}
                    {plan._id === "2" && (
                      <>
                        <CheckCircleRoundedIcon
                          sx={{ color: "#FFBF00", fontSize: 40, marginTop: 3 }}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "#FFBF00", fontSize: 40, marginTop: 3 }}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "#FFBF00", fontSize: 40, marginTop: 3 }}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "#FFBF00", fontSize: 40, marginTop: 3 }}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "#FFBF00", fontSize: 40, marginTop: 3 }}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray", fontSize: 40, marginTop: 3 }}
                        />
                      </>
                    )}
                    {plan._id === "3" && (
                      <>
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray", fontSize: 40, marginTop: 3 ,filter: "blur(4px)"}}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray", fontSize: 40, marginTop: 3 ,filter: "blur(4px)"}}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray", fontSize: 40, marginTop: 3 ,filter: "blur(4px)"}}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray", fontSize: 40, marginTop: 3 ,filter: "blur(4px)"}}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray", fontSize: 40, marginTop: 3 ,filter: "blur(4px)"}}
                        />
                        <CheckCircleRoundedIcon
                          sx={{ color: "gray", fontSize: 40, marginTop: 3 ,filter: "blur(4px)"}}
                        />
                      </>
                    )}
                  </div>
                </div>
              );
            })}

            {isloading11 ? (
              <div
                className="z-101 fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50"
                style={{ zIndex: "100" }}
              >
                <div
                  className="bg-[#1F1B2C] m-2 p-6 rounded-lg shadow-lg flex flex-col items-center"
                  style={{
                    width: "400px",
                    height: "100px",
                    justifyContent: "center",
                  }}
                >
                  <div className="flex justify-center h-full items-center">
                    <Loader />
                  </div>

                  <p className="font-sans text-[14px] text-white font-semibold leading-[23.94px] pt-4">
                    Generating Payment Link
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* Payment Method Dialog */}
            {showPaymentDialog && (
              <div className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-[#1F1B2C] p-6 rounded-lg shadow-lg flex flex-col justify-center md:w-1/3">
                  <p className="text-center font-semibold">
                    Choose Payment Method
                  </p>

                  <div className="flex flex-col gap-3 lg:flex-row justify-between mt-4">
                    <button
                      className="bg-primary w-full py-4 rounded-md flex items-center justify-center"
                      onClick={() => handlePaymentOption("crypto")}
                      // style={{width: '200%'}}
                    >
                      <img
                        src="/icon/bitcoin11.svg"
                        alt="Crypto"
                        className="w-5 h-5"
                      />
                      <p className="font-sans text-[14px] text-black font-semibold leading-[23.94px] hover:text-white">
                        Crypto Wallets
                      </p>
                    </button>

                    <button
                      className="bg-primary w-full py-4 rounded-md flex items-center justify-center"
                      onClick={() => handlePaymentOption("card_googlepay")}
                    >
                      <img
                        src="/icon/dollar11.svg"
                        alt="Fiat"
                        className="w-5 h-5"
                      />
                      <p className="font-sans text-[14px] text-black font-semibold leading-[23.94px] hover:text-white">
                        Fiat Payment
                      </p>
                    </button>
                  </div>

                  <button
                    className="bg-red-600 w-full py-2 rounded-md mt-6"
                    onClick={() => setShowPaymentDialog(null)}
                  >
                    <p className="font-sans text-[14px] text-white font-semibold leading-[23.94px] hover:text-gray-200">
                      Cancel
                    </p>
                  </button>
                </div>
              </div>
            )}

            {/* Display error popup if an error occurs */}
            {error && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-[#1F1B2C] p-6 rounded-lg shadow-lg flex flex-col justify-center">
                  <p className="text-red-500 font-semibold">{error}</p>
                  <button
                    className="bg-[red] w-full py-2 rounded-md mt-4"
                    onClick={() => setError(null)} // Close error popup
                  >
                    <p className="font-sans text-[14px] text-white font-semibold leading-[23.94px] hover:text-white">
                      Close
                    </p>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[16px]  text-pribg-primary mt-52  font-normal leading-[22.4px] tracking-[4.8px] text-center"
      >
        HAVE QUESTION?
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[36px] sm:text-[44px] mt-3  font-semibold leading-[49.28px] tracking-[-0.88px] text-center"
      >
        Frequently Asked <br />
        Question
      </p>
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
        <AccountFaqs
          question="What payment and deposit methods do we accept?"
          listItems={[
            "We accept different payment- and deposits methods, including Debit- / Credit card, Apple Pay, Google Pay, as well as Crypto",
          ]}
          isExpanded={expandedIndex === 0}
        onToggle={() => handleToggle(0)}
        />{" "}
        <AccountFaqs
          question="Are there any hidden fees?"
          listItems={[
            "There are no hidden fees. All fees you as customer have to pay are listed in the table above",
          ]}
          isExpanded={expandedIndex === 1}
          onToggle={() => handleToggle(1)}
        />{" "}
        <AccountFaqs
          question="Is there a free trial / version available?"
          listItems={["As of now we do not offer any free trial / version. "]}
          paragraph="However, we are working on a model, customers can use without any upfront payments, only including a performance fee, raised, when infect a positive return flows. "
          isExpanded={expandedIndex === 2}
          onToggle={() => handleToggle(2)}
        />
        <AccountFaqs
          question="How do I get started?"
          listItems={[
            "To be able to purchase a product, you need nothing but a free to create account. For further deposits and product usage, we require you to pass the Know Your Customer (KYC) procedure. ",
          ]}
          isExpanded={expandedIndex === 3}
          onToggle={() => handleToggle(3)}
        />{" "}
      </div>

      {/* <ElevateBusiness /> */}

      <Footer />
    </div>
  );
}
export default PricingPlane;
