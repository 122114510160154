import React, { useState } from "react";
import DepositModal from "./DepositModal";

function PurchaseHeader(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handlePurchaseModalClick = () => {
    setPurchaseModal(true);
  };

  const handleClosePurchaseModal = () => {
    setPurchaseModal(false);
  };
  return (
    <div className=" grid sm:grid-cols-2 mt-[100px] sm:mt-[126px] pb-10">
      <div className="sm:ml-10 md:ml-40 ">
        <h1 className="text-Accent text-accent subtitle custom-font text-center sm:text-start text-5xl sm:text-6xl font-black leading-[76px] tracking-[1.4px] capitalize mb-[39px]">
          {props.text}
        </h1>
        <button
          className="transition duration-500 bg-yellow-500 ease-in-out hover:bg-black hover:text-Accent active:bg-Accent2 active:text-Accent bg-Accent2 border-Accent2 w-[80px] md:w-[144px] h-[45px] ml-2 mt-[20px] py-3 text-center rounded-md"
          onClick={handlePurchaseModalClick}
        >
          <h2 className="w-[80px] text-accent md:w-[143px] h-[28px] font-medium">
            Deposit
          </h2>
        </button>
      </div>
      <div className="relative flex justify-center items-center w-[350px] h-[350px] mx-auto ">
        <img
          src="./icon/purchaseglobe.svg"
          className="w-[300px] sm:w-[350px] h-[350px] mb-3 absolute outer-globe"
        />
      </div>
      {/* {isModalOpen && <DepositModal func={toggleModal} />}
      <DepositModal show={purchaseModal} onClose={handleClosePurchaseModal} /> */}
    </div>
  );
}

export default PurchaseHeader;
