import React, { useState, useEffect, useRef, useContext } from "react";
import { useSpring, animated } from "react-spring";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Partnership from "../../components/Partnership";
import Feature from "../../components/Feature";
import Getstarted from "../../components/Getstarted";
import Ourvision from "../../components/Ourvision";
import Started from "../../components/Started";
import Elevate from "../../components/Elevate";
import Faqs from "../../components/Faqs";
import Footer from "../../components/Footer";
import { UserContext } from "../../contexts/UserContext";

function Home() {
  const { user } = useContext(UserContext);
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  const imageRef = useRef(null);

  const [springProps, set] = useSpring(() => ({
    from: { transform: "perspective(600px) rotateX(-10deg) translateY(-80px)" },
    to: { transform: "perspective(600px) rotateX(0deg) translateY(0px)" },
    config: { tension: 100, friction: 20 },
  }));

  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const imageTop = imageRef.current.offsetTop;
    const halfwayScroll = imageTop + imageHeight / 2;
    setIsScrollingDown(scrollTop > halfwayScroll);
  };

  useEffect(() => {
    setImageHeight(imageRef.current.clientHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    set({
      transform: isScrollingDown
        ? "perspective(600px) rotateX(0deg) translateY(0px)"
        : "perspective(1000px) rotateX(10deg) translateY(80px)",
    });
  }, [isScrollingDown, set]);

  return (
    <div className="body ">
      <div className="background-div px-2 sm:px-4">
        <Navbar />
        <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="400">
          <h1 className="font-sora mx-2 sm:mx-0 mt-16 text-[36px] sm:text-[56px] font-bold leading-[62.72px] tracking-[-1.68px] text-center">
            INVESTING <span className="block"></span>
            IN <span className="text-primary">CRYPTO</span> WAS NEVER
            <span className="block"></span>
            THIS <span className="text-primary">EASY</span>{" "}
          </h1>
          <Link to="/login">
            <button className="bg-primary mx-auto mt-20 cursor-pointer items-center justify-center flex my-auto px-6 h-14 rounded-xl hover:bg-[#f1ae1c] hover:text-accent">
              <p className=" text-[16px] font-sora text-black font-medium leading-[23.94px] text-left hover:text-accent">
                Join Beta
              </p>
            </button>
          </Link>
        </div>
        <div className="w-[80%] lg:w-[100%] xl:w-[95%] 2xl:w-[75%] mx-auto relative">
          <div className="flex justify-end w-[85%] mx-auto items-end  relative">
            <div className="hero_section mt-16 hidden sm:block">
              <animated.div style={springProps} className={`hidden sm:block`}>
                <img
                  ref={imageRef}
                  src="/icon/main.png"
                  alt="Animated Image"
                  style={{ maxWidth: "95%", display: "block" }}
                />
              </animated.div>
            </div>
            <div className="mt-16 sm:hidden">
              <div>
                <img ref={imageRef} src="/icon/main.png" alt="Animated Image" />
              </div>
            </div>

            <div className="absolute left-[-12%] pl-2 sm:left-[-10%] md:left-[-10%] lg:left-[-10%] 3xl:left-[-1%] 4xl:left-[4%] top-1/2 transform -translate-y-1/2">
              <img
                src="/icon/fundyouraccount.png"
                className="w-16 md:w-48 lg:w-64 h-26 md:h-56 lg:h-80"
                alt="left side image"
              />
            </div>
            <div className="flex flex-col items-start absolute right-[-10%] pl-2 sm:right-[-10%] 3xl:right-[1%] 4xl:right-[6%] md:right-[-10%] lg:right-[-4%]   top-1/2 transform -translate-y-1/2">
              <img
                src="/icon/tratedvolume.png"
                className="w-14 md:w-32 lg:w-48 h-8 md:h-16 lg:h-24 mb-4"
                alt="right side image 1"
              />
              <img
                src="/icon/weekly.png"
                className="w-16 md:w-28 lg:w-40 h-14 md:h-24 mt-[70%] mb-[-100%] lg:h-36"
                alt="right side image 2"
              />
            </div>
          </div>
        </div>
      </div>
      <Partnership />
      <Feature />
      <Getstarted />
      {!user && (
        <Link to="/signup">
          <button className="bg-primary mx-auto mt-16 justify-center items-center flex my-auto px-6 h-14 rounded-xl hover:bg-[#f1ae1c] hover:text-accent">
            <p className="font-sora text-[16px] text-black font-medium leading-[23.94px] text-left hover:text-accent">
              Sign Up
            </p>
          </button>
        </Link>
      )}
      <Ourvision />
      <Started />
      <Elevate />
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[16px] text-primary mt-44 font-normal leading-[22.4px] tracking-[4.8px] text-center"
      >
        HAVE QUESTION?
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[34px] sm:text-[44px] mt-3 font-semibold leading-[49.28px] tracking-[-0.88px] text-center"
      >
        Frequently Asked <br />
        Question
      </p>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="mt-4 flex mb-20 flex-col gap-6"
      >
        <Faqs
          question="How do I get started with Algo X Capital?"
          paragraphfirst="Getting started with Algo X Capital is quick and easy:"
          listItems={[
            "Create an account: Sign up for free or choose a paid plan for extra features.",
            "Fund your account: Start with as little as $1. Most of our successful investors begin with around $2,000.",
            "Relax and earn: Our AI-powered algorithms trade 24/7, generating passive income for you.",
          ]}
          paragraph="That's it! Just sign up, fund your account, and let our advanced technology work for you."
          isExpanded={expandedIndex === 0}
          onToggle={() => handleToggle(0)}
        />{" "}
        <Faqs
          question="How much money do I need to start with Algo X Capital?"
          paragraphfirst="You can start with as little as $1 on our Free plan. However, for optimal results and to fully leverage our trading algorithms, we recommend an initial investment of around $2,000. This amount allows you to experience the full potential of our platform and see significant returns. Remember, while you can start small, larger investments typically yield more noticeable results."
          isExpanded={expandedIndex === 1}
          onToggle={() => handleToggle(1)}
        />{" "}
        <Faqs
          question="Is my money safe using Algo X Capital?"
          paragraphfirst="Your security is our top priority. Here's how we protect your funds:"
          listItems={[
            "Secure Storage: Your funds remain safely held in your personal account with ByBit, one of the world's largest and most trusted exchanges.",
            "Limited Access: Algo X Capital operates via a read-only API, meaning we can execute trades but never withdraw or hold your funds directly.",
            "Regulatory Compliance: As a German-based company, Algo X Capital fully complies with all EU regulations. We go beyond legal requirements, providing additional company insights to ensure 100% transparency.",
            "Transparency: You maintain full visibility and control of your account at all times.",
          ]}
          paragraph="While we employ robust security measures, please remember that all financial products carry inherent risks. We advise investing only what you can afford to lose."
          isExpanded={expandedIndex === 2}
          onToggle={() => handleToggle(2)}
        />
        <Faqs
          question="I have a problem, how can I contact the Algo X Capital Team? "
          paragraphfirst="We're here to help! Here are the ways you can reach our support team:"
          listItems={[
            "FAQ Section: Before reaching out, check our comprehensive FAQ section. You might find an immediate answer to your question there.",
            <span>
              Email Support: For any questions or issues, please contact us at {" "}
              <a className="text-[#523eba]" href="support@algox.capital">
                 support@algox.capital 
              </a>
              . Our dedicated team aims to respond within 24 hours.
            </span>,
          ]}
          paragraph="We're committed to providing you with prompt and effective support to ensure your experience with Algo X Capital is smooth and satisfactory."
          isExpanded={expandedIndex === 3}
          onToggle={() => handleToggle(3)}
        />{" "}
      </div>
      <Footer />
    </div>
  );
}

export default Home;
