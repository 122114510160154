import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const NotificationSidebar = () => {
  const [focusedItem, setFocusedItem] = useState("personal");

  const menuItems = [
    { id: "personal", label: "All", count: 8, link: "" },
    { id: "referral", label: "System Notification", link: "" },
    { id: "events", label: "Latest Events", count: 1, link: "" },
    { id: "announcement", label: "Announcement", link: "" },
    { id: "rewards", label: "Rewards", count: 1, link: "" },
    { id: "alerts", label: "TradingView Alerts", link: "" },
    { id: "news", label: "News", link: "" },
    { id: "signals", label: "Strategy Signals", link: "" },
  ];

  useEffect(() => {
    setFocusedItem("personal");
  }, []);

  return (
    <div className="grid bg-[#222222] md:w-44 lg:w-56 lg:px-3 pt-6 pb-16 md:pb-24 rounded-3xl md:ml-auto mt-20">
      {menuItems.map((item) => (
        <Link to={item.link} key={item.id}>
          <div
            className={`flex gap-2 cursor-pointer py-4 px-2 rounded-sm ${
              focusedItem === item.id ? "text-primary" : "text-accent"
            }`}
            tabIndex={0}
            onFocus={() => setFocusedItem(item.id)}
          >
            <img
              src={`/icon/${
                focusedItem === item.id ? "vector1.svg" : "vector2.svg"
              }`}
              alt="vector icon"
              className="mb-2"
            />
            <div className="justify-between flex w-full">
              <p
                className={`font-inter text-[10px] lg:text-[14px] mr-4 font-medium leading-[16.94px] text-left ${
                  focusedItem === item.id ? "text-primary" : ""
                }`}
              >
                {item.label}
              </p>
              {item.count && (
                <p
                  className={`${
                    focusedItem === item.id ? "text-primary" : "text-accent"
                  }`}
                >
                  {item.count}
                </p>
              )}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default NotificationSidebar;
