import React, { useEffect } from "react";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import ElevateBusiness from "../../components/ElevateBusiness";
import AccountFaqs from "../../components/AccountFaqs";
function Impirint() {
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="account">
      <div className="bg-account">
        <Navbar />

        <div className=" mx-4 sm:mx-12 md:mx-20 mt-20 2xl:w-4/5 2xl:mx-auto">
          <h1 className="font-sora text-5xl font-bold leading-[62.72px] tracking-[-0.03em] text-center">
            Impring
          </h1>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-12">
            Introduction and Legal framework
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            This file includes all the relevant information regarding the
            requirements for an imprint and to fulfill the legal requirements
            for documentation, transparency and to remain within the current
            legal framework provided by the State of Germany as well as on
            European level.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Based on our core principles we see it as our duty to our customers
            to have a fully transparent framework for the use of our services.
            We want to point out that to our best efforts, we try to be
            compliant with all the herein mentioned as well as not mentioned
            standards, which are applicable for our area of services. However,
            to all our efforts, we cannot always guarantee to be compliant with
            every standard applicable in each country of business.
          </p>

          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            This imprint applies to the website{" "}
            <a
              target="_blank"
              href="/www.AlgoX.Capital"
              className="text-[#42396D]"
            >
              www.AlgoX.Capital
            </a>{" "}
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
            Information according to § 5 TMG:
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            AlgoX Capital GbR <br />
            Sperberweg 13
            <br />
            83556 Griesstätt
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Represented by the founders and managing directors: Philipp Deutsch,
            Florian ABC, Lion Degen, Arben Kuqi
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
            Contact:
          </h2>
          <div className="flex gap-12">
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-3 text-left">
              Mobile:
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-3 text-left">
              +49 (0) 151 6400 9853
            </p>
          </div>
          <div className="flex gap-12">
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]  text-left">
              E-Mail:
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B]  text-left">
              Support@algox.capital, Official@algox.capital
            </p>
          </div>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
            Register entries
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            No registration due to the impossibility of registering a German GbR
            in the corresponding regsiters.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
            VAT identification number
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            No VAT entrepreneur at the time the document was created.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Responsible for the content according to § 55 Abs. 2 RStV:
            <br />
            Philipp Deutsch <br />
            AlgoX Capital GbR <br />
            Sperberweg 13 <br />
            83556 Griesstätt
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            We hereby expressly prohibit the use of contact data published
            within the scope of the imprint obligation by third parties for
            sending unsolicited advertising and information material. The
            operators of the site expressly reserve the right to take legal
            action in the event of the unsolicited sending of advertising
            information, such as spam e-mails.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
            Dispute resolution procedure before a consumer arbitration board
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The online dispute resolution platform set up by the European
            Commission as a contact point for consumers and traders who wish to
            settle disputes arising from legal transactions concluded online out
            of court can be accessed via the following link:
          </p>
          <a
            href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE"
            target="_blank"
            className="text-[#42396D] mt-2"
          >
            https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE
          </a>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-2 text-left">
            However, we would like to point out that we do not participate in
            dispute resolution proceedings before a consumer arbitration board.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
            Disclaimer
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Trading in currencies, futures, options or securities in general is
            not only associated with opportunities, but also with risks. Only
            persons and institutions that can bear any losses should trade on
            the exchanges.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Our products, strategies and signals as well as other publications
            are merely information and not a recommendation to buy or sell any
            kinds of assets. Speculation in the cryptocurrency market in
            particular should only be traded with real money by experienced
            investors. None of the information on this website constitutes
            financial advice, nor should it be construed as such.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            No guarantee can be given that any trading account will generate
            profits or losses in the manner presented on this website (or via
            any other publications distributed by us). All information
            disseminated is for illustrative and educational purposes only.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Other incalculable risks may arise, such as disruptions to Internet
            availability, software problems, changing framework of law, or
            other.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            By using this website or our services, you accept that we shall
            assume no liability whatsoever, even if all information has been
            compiled with the greatest possible care and to the best of our
            knowledge and belief. If you use information on this website or in
            our other publications to trade independently on whatever exchange,
            you are responsible for doing so. Losses of any kind incurred cannot
            be claimed against AlgoX Capital.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
            Copyrights
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            All texts, graphics, images and all interactive elements are subject
            to copyright and other laws that serve to protect intellectual
            property. Unauthorized reproduction, disclosure to third parties,
            publication on other websites or other use is prohibited without the
            written permission of AlgoX Capital.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The copyright for published objects remains solely with AlgoX
            Capital. Reproduction, distribution or use of graphics, text
            documents, video sequences and texts in other electronic or printed
            publications is not permitted without the express consent of AlgoX
            Capital.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
          @AlgoX Capital 2024
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
          All rights reserved
          </p>
        </div>
      </div>
      {/* <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[16px] md:hidden text-[#B0A4FF] mt-64 sm:mt-[45%] 2xl:mt-96 font-normal leading-[22.4px] tracking-[4.8px] text-center"
      >
        HAVE QUESTION?
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[44px] mt-3 md:hidden font-semibold leading-[49.28px] tracking-[-0.88px] text-center"
      >
        Frequently Asked <br />
        Question
      </p>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="lg:hidden mt-4 flex flex-col gap-6"
      >
       <AccountFaqs question="What payment methods do you accept?" />
        <AccountFaqs question="Are there any hidden fees?" />
        <AccountFaqs question="Is there a free trial available?" />
        <AccountFaqs question="How do I get started?" />
        <AccountFaqs question="How do I access my account?" />
      </div> */}

      {/* <ElevateBusiness /> */}

      <Footer />
    </div>
  );
}
export default Impirint;
