import React, { useState, useRef, useEffect, useContext } from "react";
import Navbar from "../../components/Navbar";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import Faqs from "../../components/Faqs";
import { URL } from "../../constants/constant";
import { UserContext } from "../../contexts/UserContext";
import BigLoader from "../../components/BigLoader";
import Loader from "../../components/Loader";
import { postRequest } from "../../backendServices/ApiCalls";
import Notification from "../../components/Notification";

function NotificationSetting() {
  const { user, setUser } = useContext(UserContext);
  const location = useLocation();
  const [focusedItem, setFocusedItem] = useState(null);
  const [loadingStates, setLoadingStates] = useState({});
  const [switchStates, setSwitchStates] = useState({
    promotionnotification: false,
    bybitLearnnotification: false,
    bybitInsightsnotification: false,
    depositnotification: false,
    productsnotification: false,
    promotionGeneralnotification: false,
    systemUpdatenotification: false,
    campaignnotification: false,
    otcLendingnotification: false,
  });
  const referralRef = useRef(null);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("usersetting")) {
      setFocusedItem("personal");
    } else if (path.includes("referral")) {
      setFocusedItem("referral");
    } else if (path.includes("notificationsetting")) {
      setFocusedItem("notification");
    }
  }, [location]);

  const handleFocus = (item) => {
    setFocusedItem(item);
  };

  const handleBlur = () => {
    setFocusedItem(null);
  };

  const darkYellow = "#FFCC00";
  const YellowSwitch = styled(Switch)(({ theme }) => ({
    width: 70,
    height: 45,
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(28px)",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: alpha(darkYellow, theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-thumb": {
      width: 28,
      height: 28,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: darkYellow,
    },
  }));
  useEffect(() => {
    if (user) {
      setSwitchStates({
        promotionnotification: user.promotionnotification || false,
        bybitLearnnotification: user.bybitLearnnotification || false,
        bybitInsightsnotification: user.bybitInsightsnotification || false,
        depositnotification: user.depositnotification || false,
        productsnotification: user.productsnotification || false,
        promotionGeneralnotification:
          user.promotionGeneralnotification || false,
        systemUpdatenotification: user.systemUpdatenotification || false,
        campaignnotification: user.campaignnotification || false,
        otcLendingnotification: user.otcLendingnotification || false,
      });
    }
  }, [user]);

  const label = { inputProps: { "aria-label": "Color switch demo" } };
  const handleSwitchChange = (name) => async (event) => {
    const newCheckedState = event.target.checked;
      setLoadingStates((prevLoading) => ({
      ...prevLoading,
      [name]: true,
    }));
  
    setSwitchStates((prevState) => ({
      ...prevState,
      [name]: newCheckedState,
    }));
  
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Unauthorized access. Please log in first.");
      setLoadingStates((prevLoading) => ({
        ...prevLoading,
        [name]: false,
      }));
      return;
    }
      const params = {
      name,
      newCheckedState,
    };
    postRequest("yes",
      "auth/notification", 
      params, 
      (response) => {
        const data = response?.data;
        setUser((prevUser) => ({
          ...prevUser,
          [name]: newCheckedState,
        }));
        Notification.showNotification(
          "success",
          "success",
          `${name} ${newCheckedState === true ? "ON": "OFF"}`
        );
      },
      (error) => {
        console.error("Error while updating switch state:", error);
      }
    );
  
    // Stop loader after the API call
    setLoadingStates((prevLoading) => ({
      ...prevLoading,
      [name]: false,
    }));
  };
  
  // const handleSwitchChange = (name) => async (event) => {
  //   const newCheckedState = event.target.checked;

  //   // Start loader for the current switch
  //   setLoadingStates((prevLoading) => ({
  //     ...prevLoading,
  //     [name]: true,
  //   }));

  //   // Update switch states
  //   setSwitchStates((prevState) => ({
  //     ...prevState,
  //     [name]: newCheckedState,
  //   }));

  //   try {
  //     const token = localStorage.getItem("token");
  //     if (!token) {
  //       alert("Unauthorized access. Please log in first.");
  //       return;
  //     }
  //     const response = await fetch(`${URL}/auth/notification`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({ name, newCheckedState }),
  //     });

  //     if (!response.status === 200) {
  //       throw new Error("Failed to update notification settings");
  //     }

  //     const data = await response.json();
  //     setUser((prevUser) => ({
  //       ...prevUser,
  //       [name]: newCheckedState,
  //     }));
  //   } catch (error) {
  //     console.error("Error while updating switch state:", error);
  //   } finally {
  //     // Stop loader after the API call
  //     setLoadingStates((prevLoading) => ({
  //       ...prevLoading,
  //       [name]: false,
  //     }));
  //   }
  // };

  return (
    <div className="body">
      <div className=" navbar-bg-color px-4">
        <Navbar />
        <div className="grid grid-cols-1 sm:grid-cols-5">
          <div className="col-span-1 hidden md:block  min-h-screen">
            <div className="grid bg-[#222222] w-56 px-3 pt-6 pb-24 rounded-3xl ml-auto mt-20">
            
            <Link to="/usersetting">
                <div>
                  <div
                    className={`flex gap-2  cursor-pointer py-3 px-2  rounded-sm ${
                      focusedItem === "personal"
                        ? "text-primary "
                        : "text-accent "
                    }`}
                    tabIndex={0}
                    onFocus={() => handleFocus("personal")}
                    onBlur={handleBlur}
                  >
                    <img
                      src={`/icon/${
                        focusedItem === "notification"
                          ? "vector2.svg"
                          : "vector1.svg"
                      }`}
                      alt="vector icon"
                    />
                    <p
                      className={`font-inter text-[10px] lg:text-[14px] mr-4 font-medium leading-[16.94px] text-left ${
                        focusedItem === "personal" ? "text-primary" : ""
                      }`}
                    >
                      Personal Settings
                    </p>
                    {/* <img
                      src={`/icon/${
                        focusedItem === "notification"
                          ? "downarrow2.svg"
                          : "downarrow1.svg"
                      }`}
                      alt="vector icon"
                    /> */}
                  </div>
                </div>
              </Link><Link to="/notificationsetting">
                <div>
                  <div
                    ref={referralRef}
                    className={`flex gap-2  cursor-pointer py-3 px-2  rounded-sm ${
                      focusedItem === "notification"
                        ? "text-primary "
                        : "text-accent "
                    }`}
                    tabIndex={0}
                    onFocus={() => handleFocus("notification")}
                    onBlur={handleBlur}
                  >
                    <img
                      src={`/icon/${
                        focusedItem === "notification"
                          ? "vector1.svg"
                          : "vector2.svg"
                      }`}
                      alt="vector icon"
                    />

                    <p
                      className={`font-inter text-[10px] lg:text-[14px] mr-4 font-medium leading-[16.94px] text-left ${
                        focusedItem === "notification" ? "text-primary" : ""
                      }`}
                    >
                      Notification Centre
                    </p>
                    {/* <img
                      src={`/icon/${
                        focusedItem === "notification"
                          ? "downarrow1.svg"
                          : "downarrow2.svg"
                      }`}
                      alt="vector icon"
                    /> */}
                  </div>
                </div>
              </Link>
              
              <Link to="/referral">
                <div>
                  <div
                    className={`flex gap-2  cursor-pointer py-3 px-2  rounded-sm ${
                      focusedItem === "referral"
                        ? "text-primary "
                        : "text-accent "
                    }`}
                    tabIndex={0}
                    onFocus={() => handleFocus("referral")}
                    onBlur={handleBlur}
                  >
                    <img
                      src='/icon/vector2.svg'
                      alt="vector icon"
                    />
                    <p
                      className={`font-inter text-[10px] lg:text-[14px] mr-4 font-medium leading-[16.94px] text-left ${
                        focusedItem === "referral" ? "text-primary" : ""
                      }`}
                    >
                      Referral Program
                    </p>
                  </div>
                </div>
              </Link>
            
            </div>
          </div>
          <div className="col-span-4">
            {!user ? (
              <div
                className="flex justify-center h-full items-center"
                style={{ height: "50vh" }}
              >
                <BigLoader />
              </div>
            ) : (
              <>
                <div className=" mb-3 mr-3 lg:mb-0 sm:mx-28 mt-20">
                  <p className="font-inter text-[24px] font-bold leading-[22px] mb-4">
                    Email Subscription
                  </p>
                  <div className="flex flex-row sm:flex-row w-full lg:w-[70%] mt-10 justify-between">
                    <p className="font-inter text-base font-normal leading-6 text-left">
                      Promotion: Our latest events, offers and more
                    </p>
                    <div className="min-w-6 mt-4 sm:mt-0">
                      {loadingStates.promotion ? (
                        <Loader /> // Your loader component
                      ) : (
                        <YellowSwitch
                          {...label}
                          checked={switchStates.promotionnotification}
                          onChange={handleSwitchChange("promotionnotification")}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className=" mb-3 lg:mb-0 mr-3 sm:mx-28">
                  <div className="flex flex-row sm:flex-row w-full lg:w-[70%] justify-between">
                    <p className="font-inter text-base font-normal leading-6 text-left">
                      Bybit Learn: The fundamentals of crypto trading
                    </p>
                    <div className="min-w-6 mt-4 sm:mt-0">
                      {loadingStates.bybitLearn ? (
                        <Loader /> // Your loader component
                      ) : (
                        <YellowSwitch
                          {...label}
                          checked={switchStates.bybitLearnnotification}
                          onChange={handleSwitchChange(
                            "bybitLearnnotification"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className=" mb-3 lg:mb-0 sm:mx-28">
                  <div className="flex flex-row sm:flex-row w-full lg:w-[70%] justify-between">
                    <p className="font-inter text-base font-normal leading-6 text-left">
                      Bybit Insights: Our latest crypto analysis
                    </p>
                    <div className="min-w-6 mt-4 sm:mt-0">
                      {loadingStates.bybitInsights ? (
                        <Loader /> // Your loader component
                      ) : (
                        <YellowSwitch
                          {...label}
                          checked={switchStates.bybitInsightsnotification}
                          onChange={handleSwitchChange(
                            "bybitInsightsnotification"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="mr-6 sm:mx-28 mt-16">
                  <p className="font-inter text-[24px] font-bold leading-[22px] mb-4">
                    Deposit
                  </p>
                  <div className="flex flex-row sm:flex-row w-full lg:w-[70%] mt-10 justify-between">
                    <p className="font-inter text-base font-normal leading-6 text-left">
                      Receive notifications about Deposit and Withdrawal State
                    </p>
                    <div className="min-w-6 mt-4 sm:mt-0">
                      {loadingStates.deposit ? (
                        <Loader /> // Your loader component
                      ) : (
                        <YellowSwitch
                          {...label}
                          checked={switchStates.depositnotification}
                          onChange={handleSwitchChange("depositnotification")}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className=" sm:mx-28 mt-4">
                  <p className="font-inter text-[22px] font-bold leading-[22px] mb-4">
                    General Notification Settings
                  </p>
                  <div className="flex flex-row sm:flex-row w-full lg:w-[70%] mt-10 justify-between">
                    <p className="font-inter text-base font-normal leading-6 text-left">
                      Products
                    </p>
                    <div className="min-w-6 mt-4 sm:mt-0">
                      {loadingStates.products ? (
                        <Loader /> // Your loader component
                      ) : (
                        <YellowSwitch
                          {...label}
                          checked={switchStates.productsnotification}
                          onChange={handleSwitchChange("productsnotification")}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className=" sm:mx-28">
                  <div className="flex flex-row sm:flex-row w-full lg:w-[70%] justify-between">
                    <p className="font-inter text-base font-normal leading-6 text-left">
                      Promotion
                    </p>
                    <div className="min-w-6 mt-4 sm:mt-0">
                      {loadingStates.promotionGeneral ? (
                        <Loader /> // Your loader component
                      ) : (
                        <YellowSwitch
                          {...label}
                          checked={switchStates.promotionGeneralnotification}
                          onChange={handleSwitchChange(
                            "promotionGeneralnotification"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="sm:mx-28">
                  <div className="flex flex-row sm:flex-row w-full lg:w-[70%] justify-between">
                    <p className="font-inter text-base font-normal leading-6 text-left">
                      System Update
                    </p>
                    <div className="min-w-6 mt-4 sm:mt-0">
                      {loadingStates.systemUpdate ? (
                        <Loader /> // Your loader component
                      ) : (
                        <YellowSwitch
                          {...label}
                          checked={switchStates.systemUpdatenotification}
                          onChange={handleSwitchChange(
                            "systemUpdatenotification"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className=" sm:mx-28">
                  <div className="flex flex-row sm:flex-row w-full lg:w-[70%] justify-between">
                    <p className="font-inter text-base font-normal leading-6 text-left">
                      Campaign
                    </p>
                    <div className="min-w-6 mt-4 sm:mt-0">
                      {loadingStates.campaign ? (
                        <Loader /> // Your loader component
                      ) : (
                        <YellowSwitch
                          {...label}
                          checked={switchStates.campaignnotification}
                          onChange={handleSwitchChange("campaignnotification")}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className=" sm:mx-28 mb-10">
                  <div className="flex flex-row sm:flex-row w-full lg:w-[70%] justify-between">
                    <p className="font-inter text-base font-normal leading-6 text-left">
                      OTC Lending
                    </p>
                    <div className="min-w-6 mt-4 sm:mt-0">
                      {loadingStates.otcLending ? (
                        <Loader /> // Your loader component
                      ) : (
                        <YellowSwitch
                          {...label}
                          checked={switchStates.otcLendingnotification}
                          onChange={handleSwitchChange(
                            "otcLendingnotification"
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* <p className="font-sora text-[34px] sm:text-[44px] mt-3 font-semibold leading-[49.28px] tracking-[-0.88px] text-center">
          Frequently Asked <br />
          Question
        </p>
        <div className="mt-4 flex flex-col gap-6">
          <Faqs question="How do I get started?" />
          <Faqs question="How much money do I need to start?" />
          <Faqs question="Is my money safe using Algo X Capital?" />
          <Faqs question="I have a problem, how can I contact the Team?" />
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default NotificationSetting;
