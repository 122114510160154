import React from 'react';
import { notification } from 'antd';

const Notification = () => {
  const [api, contextHolder] = notification.useNotification();
  
  Notification.api = api;
  
  return <div>{contextHolder}</div>;
};

Notification.showNotification = (type, message, description) => {
  Notification.api[type]({
    message: message,
    description: description,
  });
};

export default Notification;
