import React from "react";
import StartedCard from "./StartedCard";

function Started() {
  return (
    <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
      <p className="font-sora text-[16px] text-primary mt-44 font-normal leading-[22.4px] tracking-[4.8px] text-center">
        HOW IT WORKS
      </p>
      <p className="font-sora text-[34px] sm:text-[44px] mt-3 font-semibold leading-[49.28px] tracking-[-0.88px] text-center">
        Want to Get <br /> Started?
      </p>
      <div className="scroll-container mt-12 ">
        <div className="scroll-track gap-4">
          {/* First row (original) */}
          <StartedCard
            image="/icon/person4.svg"
            p1="I couldn't be happier with the"
            p2="outcome. Their team is talented,"
            p3="responsive, and a pleasure to work with."
            name="William Richard"
            work="President of Sales"
          />
          <StartedCard
            image="/icon/person.svg"
            p1="Highly recommend to anyone
looking "
            p2=" for top-notch design and
marketing"
            p3=" services. They are the
best in the business!"
            name="Joseph Thomas"
            work="Web Designer"
          />
          <StartedCard
            image="/icon/person3.svg"
            p1="Exceptional work! They
transformed"
            p2=" my website into a
sleek and professional "
            p3=" platform
that drives traffic and conversions."
            name="Robert Kathryn"
            work="Nursing Assistant"
          />
          <StartedCard
            image="/icon/person2.svg"
            p1="Working with them was a game-
changer"
            p2=" for my brand. Their
attention to detail "
            p3="and creative
solutions set them apart."
            name="Darlene Robertson"
            work="Medical Assistant"
          />
          <StartedCard
            image="/icon/person1.svg"
            p1="Creative, innovative, and strategic.
We have"
            p2=" great achievements made
togethe "
            p3=" and are looking to
enhance"
            name="Mark David"
            work="Medical Assistant"
          />
          <StartedCard
            image="/icon/person4.svg"
            p1="I couldn't be happier with the"
            p2="outcome. Their team is talented,"
            p3="responsive, and a pleasure to work with."
            name="William Richard"
            work="President of Sales"
          />
          <StartedCard
            image="/icon/person.svg"
            p1="Highly recommend to anyone
looking "
            p2=" for top-notch design and
marketing"
            p3=" services. They are the
best in the business!"
            name="Joseph Thomas"
            work="Web Designer"
          />
          <StartedCard
            image="/icon/person3.svg"
            p1="Exceptional work! They
transformed"
            p2=" my website into a
sleek and professional "
            p3=" platform
that drives traffic and conversions."
            name="Robert Kathryn"
            work="Nursing Assistant"
          />
          <StartedCard
            image="/icon/person2.svg"
            p1="Working with them was a game-
changer"
            p2=" for my brand. Their
attention to detail "
            p3="and creative
solutions set them apart."
            name="Darlene Robertson"
            work="Medical Assistant"
          />
          <StartedCard
            image="/icon/person1.svg"
            p1="Creative, innovative, and strategic.
We have"
            p2=" great achievements made
togethe "
            p3=" and are looking to
enhance"
            name="Mark David"
            work="Medical Assistant"
          />
        </div>
        {/* Second row (new) */}
        <div className="scroll-track gap-4 mt-4 reverse-scroll">
          <StartedCard
            image="/icon/person4.svg"
            p1="I couldn't be happier with the"
            p2="outcome. Their team is talented,"
            p3="responsive, and a pleasure to work with."
            name="William Richard"
            work="President of Sales"
          />
          <StartedCard
            image="/icon/person.svg"
            p1="Highly recommend to anyone
looking "
            p2=" for top-notch design and
marketing"
            p3=" services. They are the
best in the business!"
            name="Joseph Thomas"
            work="Web Designer"
          />
          <StartedCard
            image="/icon/person3.svg"
            p1="Exceptional work! They
transformed"
            p2=" my website into a
sleek and professional "
            p3=" platform
that drives traffic and conversions."
            name="Robert Kathryn"
            work="Nursing Assistant"
          />
          <StartedCard
            image="/icon/person2.svg"
            p1="Working with them was a game-
changer"
            p2=" for my brand. Their
attention to detail "
            p3="and creative
solutions set them apart."
            name="Darlene Robertson"
            work="Medical Assistant"
          />
          <StartedCard
            image="/icon/person1.svg"
            p1="Creative, innovative, and strategic.
We have"
            p2=" great achievements made
togethe "
            p3=" and are looking to
enhance"
            name="Mark David"
            work="Medical Assistant"
          />
          <StartedCard
            image="/icon/person4.svg"
            p1="I couldn't be happier with the"
            p2="outcome. Their team is talented,"
            p3="responsive, and a pleasure to work with."
            name="William Richard"
            work="President of Sales"
          />
          <StartedCard
            image="/icon/person.svg"
            p1="Highly recommend to anyone
looking "
            p2=" for top-notch design and
marketing"
            p3=" services. They are the
best in the business!"
            name="Joseph Thomas"
            work="Web Designer"
          />
          <StartedCard
            image="/icon/person3.svg"
            p1="Exceptional work! They
transformed"
            p2=" my website into a
sleek and professional "
            p3=" platform
that drives traffic and conversions."
            name="Robert Kathryn"
            work="Nursing Assistant"
          />
          <StartedCard
            image="/icon/person2.svg"
            p1="Working with them was a game-
changer"
            p2=" for my brand. Their
attention to detail "
            p3="and creative
solutions set them apart."
            name="Darlene Robertson"
            work="Medical Assistant"
          />
          <StartedCard
            image="/icon/person1.svg"
            p1="Creative, innovative, and strategic.
We have"
            p2=" great achievements made
togethe "
            p3=" and are looking to
enhance"
            name="Mark David"
            work="Medical Assistant"
          />
        </div>
      </div>
    </div>
  );
}

export default Started;
