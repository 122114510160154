import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";
import Faqs from "../../components/Faqs";
import Footer from "../../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../components/Navbar";

function FaqsModal() {
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  const imageRef = useRef(null);

  const [springProps, set] = useSpring(() => ({
    from: { transform: "perspective(600px) rotateX(-10deg) translateY(-80px)" },
    to: { transform: "perspective(600px) rotateX(0deg) translateY(0px)" },
    config: { tension: 100, friction: 20 },
  }));

  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  useEffect(() => {
    set({
      transform: isScrollingDown
        ? "perspective(600px) rotateX(0deg) translateY(0px)"
        : "perspective(1000px) rotateX(10deg) translateY(80px)",
    });
  }, [isScrollingDown, set]);
  return (
    <div className="body">
      <Navbar/>
      <div>
        <p
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
          className="font-sora text-[16px] text-primary font-normal pt-[3%] leading-[22.4px] tracking-[4.8px] text-center"
        >
          HAVE QUESTION?
        </p>
        <p
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
          className="font-sora text-[34px] sm:text-[44px] mt-3 font-semibold leading-[49.28px] tracking-[-0.88px] text-center"
        >
          Frequently Asked <br />
          Question
        </p>
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="400"
          className="mt-4 flex mb-20 flex-col gap-6"
        >
          <Faqs
            question="How do I get started with Algo X Capital?"
            paragraphfirst="Getting started with Algo X Capital is quick and easy:"
            listItems={[
              "Create an account: Sign up for free or choose a paid plan for extra features.",
              "Fund your account: Start with as little as $1. Most of our successful investors begin with around $2,000.",
              "Relax and earn: Our AI-powered algorithms trade 24/7, generating passive income for you.",
            ]}
            paragraph="That's it! Just sign up, fund your account, and let our advanced technology work for you."
            isExpanded={expandedIndex === 0}
            onToggle={() => handleToggle(0)}
          />{" "}
          <Faqs
            question="How much money do I need to start with Algo X Capital?"
            paragraphfirst="You can start with as little as $1 on our Free plan. However, for optimal results and to fully leverage our trading algorithms, we recommend an initial investment of around $2,000. This amount allows you to experience the full potential of our platform and see significant returns. Remember, while you can start small, larger investments typically yield more noticeable results."
            isExpanded={expandedIndex === 1}
            onToggle={() => handleToggle(1)}
          />{" "}
          <Faqs
            question="Is my money safe using Algo X Capital?"
            paragraphfirst="Your security is our top priority. Here's how we protect your funds:"
            listItems={[
              "Secure Storage: Your funds remain safely held in your personal account with ByBit, one of the world's largest and most trusted exchanges.",
              "Limited Access: Algo X Capital operates via a read-only API, meaning we can execute trades but never withdraw or hold your funds directly.",
              "Regulatory Compliance: As a German-based company, Algo X Capital fully complies with all EU regulations. We go beyond legal requirements, providing additional company insights to ensure 100% transparency.",
              "Transparency: You maintain full visibility and control of your account at all times.",
            ]}
            paragraph="While we employ robust security measures, please remember that all financial products carry inherent risks. We advise investing only what you can afford to lose."
            isExpanded={expandedIndex === 2}
            onToggle={() => handleToggle(2)}
          />
          <Faqs
            question="I have a problem, how can I contact the Algo X Capital Team? "
            paragraphfirst="We're here to help! Here are the ways you can reach our support team:"
            listItems={[
              "FAQ Section: Before reaching out, check our comprehensive FAQ section. You might find an immediate answer to your question there.",
              <span>
                Email Support: For any questions or issues, please contact us at{" "}
                <a className="text-[#523eba]" href="support@algox.capital">
                  support@algox.capital
                </a>
                . Our dedicated team aims to respond within 24 hours.
              </span>,
            ]}
            paragraph="We're committed to providing you with prompt and effective support to ensure your experience with Algo X Capital is smooth and satisfactory."
            isExpanded={expandedIndex === 3}
            onToggle={() => handleToggle(3)}
          />{" "}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default FaqsModal;
