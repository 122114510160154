import React from "react";

function StartedCard(props) {
  return (
    <div className="w-96 h-72  bg-[#222222]  rounded-3xl">
      <img src="/icon/comma.svg" className="mt-6 ml-6" alt="comma img" />
      <p className="font-sora text-[14px] mt-4 ml-6 2xl:text-[16px] font-semibold leading-[28px] tracking-[-0.4px] text-left">
        {props.p1} <br/> {props.p2} <br/> {props.p3}
      </p>
      <div className="flex gap-3 mt-12 ml-6">
        <img src={props.image} alt="person img" />
        <div>
          <p className="font-sora text-[16px] font-semibold leading-[17.92px] tracking-[-0.32px] text-left">
           {props.name}{" "}
          </p>
          <p className="font-sora text-[14px] font-normal leading-[19.6px] text-left">
            {" "}
            {props.work}{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default StartedCard;
