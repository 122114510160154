import React, { useState, useEffect } from "react";
import Notification from "./Notification";
import QRCode from "react-qr-code";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { postRequest } from "../backendServices/ApiCalls";

const Images = {
  close: "./icon/CDM.svg",
  dolphin: "./icon/dolphin.svg",
};

function CryptoDepositModal({ show, onClose }) {
  const [coin, setCoin] = useState("USDT");
  const [coinOptions, setCoinOptions] = useState([]);
  const [network, setNetwork] = useState("");
  const [networkOptions, setNetworkOptions] = useState([]);
  const [addressDeposit, setAddressDeposit] = useState("");
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCoin, setLoadingCoin] = useState(false);
  const [loadingChain, setLoadingChain] = useState(false);

  useEffect(() => {
    const handleDeposit = async () => {
      setLoadingCoin(true);

      if (!navigator.onLine) {
        Notification.showNotification(
          "error",
          "Network Error",
          "Please check your internet connection and try again."
        );
        setLoadingCoin(false);
        return;
      }

      postRequest(
        "yes",
        "api/bybit/getAllowedDepositCoinInfo",
        {},
        (response) => {
          const allowedCoins = ["USDT"];
          const filteredCoins = response?.data?.result?.configList?.filter(
            (coin, index, self) =>
              allowedCoins.includes(coin.coin) &&
              index === self.findIndex((c) => c.coin === coin.coin)
          );
          setCoinOptions(filteredCoins || []);
          setLoadingCoin(false);
        },
        (error) => {
          Notification.showNotification("error", "Error", "Error");
          console.error("Error making deposit request:", error);
          setLoadingCoin(false);
        }
      );
    };

    handleDeposit();
  }, []);

  // const chain = async (selectedCoin) => {
  //   if (!navigator.onLine) {
  //     Notification.showNotification(
  //       "error",
  //       "Network Error",
  //       "Please check your internet connection and try again."
  //     );
  //     return;
  //   }
  //   try {
  //     setLoadingChain(true);
  //     const response = await axios.post(`${URL}/api/bybit/getCoinInfo`, {
  //       coin: selectedCoin,
  //     });
  //     // Do something with the response if needed
  //     // console.log(response?.data[0]?.chains);
  //     setNetworkOptions(response?.data[0]?.chains);
  //   } catch (error) {
  //     Notification.showNotification("error", "Error", "Error");
  //     setLoading(false);
  //   } finally {
  //     setLoadingChain(false);
  //   }
  // };
  const chain = async (selectedCoin) => {
    // Check for network connectivity
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }

    setLoadingChain(true);

    postRequest(
      "yes",
      "api/bybit/getCoinInfo",
      { coin: selectedCoin },
      (response) => {
        const allowedChains = ["ETH", "TRX", "TON"];
        const filteredChains = response?.data[0]?.chains?.filter((chain) =>
          allowedChains.includes(chain.chain)
        );

        setNetworkOptions(filteredChains || []);
        setLoadingChain(false);
      },
      (error) => {
        Notification.showNotification("error", "Error", "Error");
        console.error("Error fetching coin info:", error);
        setLoadingChain(false);
      }
    );
  };

  // const deposit = async () => {
  //   if (!navigator.onLine) {
  //     Notification.showNotification(
  //       "error",
  //       "Network Error",
  //       "Please check your internet connection and try again."
  //     );
  //     return;
  //   }
  //   try {
  //     //   console.log('coin==>',coin)
  //     // console.log("network==>",network)
  //     setLoading(true);
  //     const response = await axios.post(
  //       `${URL}/api/bybit/getSubDepositAddress`,
  //       {
  //         coin,
  //         network,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     // console.log(response.data);
  //     setAddressDeposit(response.data);
  //     // Notification.showNotification(
  //     //   "success",
  //     //   "Success",
  //     //   "Deposit Address is now available"
  //     // );
  //   } catch (error) {
  //     Notification.showNotification("error", "Error", error.message);
  //     console.error("Error making deposit request:", error);
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const deposit = async () => {
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }
    setLoading(true);
    postRequest(
      "yes",
      "api/bybit/getSubDepositAddress",
      { coin, network },
      (response) => {
        // console.log(response?.data?.address);
        setAddressDeposit(response?.data?.address);
        Notification.showNotification(
          "success",
          "Success",
          "Deposit Address is now available"
        );
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          error?.data?.error || "An unexpected error occurred.";
        Notification.showNotification("error", "Error", errorMessage);
        setLoading(false);
      }
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      deposit();
    }
  };
  useEffect(() => {
    if (coin) {
      chain(coin);
    }
  }, [coin]);

  if (!show) {
    return null;
  }
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(addressDeposit);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      console.error("Clipboard copy failed:", err);
    }
  };
  const handleverifymodel = () => {
    if (coin && network) {
      deposit();
      setError("");
    } else {
      setError("Please enter a value before proceeding.");
    }
  };

  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center">
      <div className="bg-Accent p-4 rounded-lg w-[400px]  sm:w-[472px] animate-fadeIn">
        <div className="bg-[#211E34BF] pt-6 border rounded-3xl border-[#FFFFFF26]">
          <div className="relative flex justify-between mx-[4%]">
            <p className="font-sans text-base font-bold leading-[1.26] text-left">
              AlgoX Capital
            </p>
            <img
              src={Images.close}
              width={20}
              height={20}
              onClick={onClose}
              className=" right-6 cursor-pointer"
              alt="close-icon"
            />
          </div>
          <div>
            <p className="font-sans text-xl my-4 font-bold leading-[1.26] text-center text-primary">
              Crypto Payment
            </p>
            {addressDeposit ? (
              <div className="flex flex-col items-center gap-2 px-2 relative">
                <p>
                  You can Deposit{" "}
                  <b className="text-primary">
                    {" "}
                    {coin} {`(${network})`}{" "}
                  </b>
                  below this address
                </p>
                <div className="relative" onClick={handleCopy}>
                  {copied && (
                    <div className="absolute top-[-25px] left-1/2 transform -translate-x-1/2 bg-black text-accent text-sm rounded-md px-2 py-1">
                      Copied!
                    </div>
                  )}
                  <input
                    type="text"
                    value={addressDeposit}
                    readOnly
                    className="w-full cursor-pointer md:w-96 text-center bg-black text-white border border-gray-600 rounded-md pl-2 pr-9 py-2"
                    onClick={(e) => e.target.select()}
                  />
                  <button
                    className="absolute right-2 top-2 text-white ml-2"
                    aria-label="Copy secret key"
                  >
                    <ContentCopyIcon
                      className="cursor-pointer"
                      onClick={handleCopy}
                    />
                  </button>
                </div>
                {/* <div className="flex gap-x-2">
                  <p className="text-primary text-[11px] sm:text-[16px]">
                    {addressDeposit}
                  </p>
                  <ContentCopyIcon
                    className="cursor-pointer"
                    onClick={handleCopy}
                  />
                </div>
                {copied && (
                  <div className="absolute top-[-25px] left-1/2 transform -translate-x-1/2 bg-black text-accent text-sm rounded-md px-2 py-1">
                    Copied!
                  </div>
                )} */}
                <QRCode
                  value={addressDeposit}
                  className="size-[50%] mb-10 2xl:size-[40%]"
                />
              </div>
            ) : (
              <>
                <p className="font-poppins ml-[15%] text-[16px] font-normal leading-[30px] text-left text-accent">
                  Select coin
                </p>
                <div className="flex justify-center">
                  {loadingCoin ? (
                    <img
                      src="/icon/yellowloader.svg"
                      alt="Loading..."
                      className="w-7 mx-auto h-7 animate-spin"
                    />
                  ) : (
                    // <select
                    //   className="bg-gray text-accent w-[70%] rounded-lg h-12 px-4"
                    //   value={coin}
                    //   onChange={(e) => setCoin(e.target.value)}
                    // >
                    //   <option value="" disabled>
                    //     Select Coin
                    //   </option>
                    //   {coinOptions?.map((coinOption, index) => (
                    //     <option key={index} value={coinOption?.coin}>
                    //       {coinOption?.coin}
                    //     </option>
                    //   ))}
                    // </select>
                    <input
                      type="text"
                      className="bg-black  text-primary font-semibold w-[70%] rounded h-12 px-4"
                      value={coin}
                      readOnly
                      onChange={(e) => setCoin(e.target.value)}
                      placeholder="Select Coin"
                    />
                  )}
                </div>
                <p className="font-poppins ml-[15%] text-[16px] font-normal mt-4 leading-[30px] text-left text-accent">
                  Select network
                </p>
                <div className="flex justify-center">
                  {loadingChain ? (
                    <img
                      src="/icon/yellowloader.svg"
                      alt="Loading..."
                      className="w-7 mx-auto h-7 animate-spin"
                    />
                  ) : (
                    // <select
                    //   className="bg-gray text-accent w-[70%] rounded-lg h-12 px-4"
                    //   value={network}
                    //   onKeyPress={handleKeyPress}
                    //   onChange={(e) => setNetwork(e.target.value)}
                    // >
                    //   <option value="" disabled>
                    //     Select Network
                    //   </option>
                    //   {networkOptions?.map((networkOption, index) => (
                    //     <option key={index} value={networkOption?.chain}>
                    //       {networkOption?.chain}
                    //     </option>
                    //   ))}
                    // </select>
                    <select
                      className="bg-black  text-primary font-semibold w-[70%] rounded h-12 px-4"
                      value={network}
                      onKeyPress={handleKeyPress}
                      onChange={(e) => {
                        setNetwork(e.target.value);
                        setError("");
                      }}
                    >
                      <option value="" disabled>
                        Select Network
                      </option>
                      {networkOptions?.map((networkOption, index) => {
                        let displayValue = networkOption?.chain;
                        if (networkOption?.chain === "ETH") {
                          displayValue = "ETH(ERC20)";
                        } else if (networkOption?.chain === "TRX") {
                          displayValue = "TRX(TRC20)";
                        } else if (networkOption?.chain === "TON") {
                          displayValue = "TON";
                        }
                        return (
                          <option key={index} value={networkOption?.chain}>
                            {displayValue}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </div>
              </>
            )}
          </div>
          <p className="mt-2  text-red-600 text-center">{error}</p>
          <div className="mx-[4%] flex gap-6 my-8">
            <button
              className={`w-full ${
                addressDeposit ? "md:w-[50%]" : ""
              } mx-auto border border-[#FFFFFF80] text-[#FFFFFF80] py-2 rounded`}
              onClick={onClose}
            >
              Back
            </button>
            {!addressDeposit && (
              <button
                className={`${
                  loading ? "cursor-not-allowed opacity-50" : ""
                } w-full border border-primary text-black font-semibold bg-primary py-2 rounded`}
                onClick={handleverifymodel}
              >
                {loading ? (
                  <img
                    src="/icon/loader.svg"
                    alt="Loading..."
                    className="w-7 mx-auto h-7 animate-spin"
                  />
                ) : (
                  " Deposit Now"
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CryptoDepositModal;
