import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { URL } from "../constants/constant";
import Notification from "./Notification";
import { UserContext } from "../contexts/UserContext";
import { postRequest } from "../backendServices/ApiCalls";

const Images = {
  close: "./icon/CDM.svg",
  dolphin: "./icon/dolphin.svg",
};
function CryptoWithdrawModal({
  show,
  onClose,
  closemainmodal,
  amount,
  setTransferBalance,
  transferBalance,
}) {
  const { user } = useContext(UserContext);
  const [coin, setCoin] = useState("USDT");
  const [coinOptions, setCoinOptions] = useState([]);
  const [network, setNetwork] = useState("");
  const [networkOptions, setNetworkOptions] = useState([]);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingCoin, setLoadingCoin] = useState(false);
  const [loadingChain, setLoadingChain] = useState(false);
  const [withdrawOTP, setWithdrawOTP] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [message, setMessage] = useState("");
  // useEffect(() => {
  //   const handleDeposit = async () => {
  //     try {
  //       setLoadingCoin(true);
  //       const response = await axios.post(
  //         `${URL}/api/bybit/getAllowedDepositCoinInfo`,
  //         {}
  //       );
  //       setCoinOptions(response?.data?.result?.configList); // Assuming response.data contains a 'coins' array
  //       // console.log(response.data.result.configList);
  //     } catch (error) {
  //       Notification.showNotification("error", "Error", "Error");
  //       console.error("Error making deposit request:", error);
  //     } finally {
  //       setLoadingCoin(false);
  //     }
  //   };

  //   handleDeposit(); // Call the API once on component mount
  // }, []);

  // const chain = async (selectedCoin) => {
  //   if (!navigator.onLine) {
  //     Notification.showNotification(
  //       "error",
  //       "Network Error",
  //       "Please check your internet connection and try again."
  //     );
  //     return;
  //   }
  //   try {
  //     setLoadingChain(true);
  //     const response = await axios.post(`${URL}/api/bybit/getCoinInfo`, {
  //       coin: selectedCoin,
  //     });
  //     // Do something with the response if needed
  //     // console.log(response?.data[0]?.chains);
  //     setNetworkOptions(response?.data[0]?.chains);
  //   } catch (error) {
  //     Notification.showNotification("error", "Error", "Error");
  //     setLoading(false);
  //   } finally {
  //     setLoadingChain(false);
  //   }
  // };
  useEffect(() => {
    const handleDeposit = () => {
      setLoadingCoin(true);
      postRequest(
        "yes",
        "api/bybit/getAllowedDepositCoinInfo",
        {},
        (response) => {
          const allowedCoins = ["USDT"];
          const filteredCoins = response?.data?.result?.configList?.filter(
            (coin, index, self) =>
              allowedCoins.includes(coin.coin) &&
              index === self.findIndex((c) => c.coin === coin.coin)
          );
          setCoinOptions(filteredCoins || []);
          setLoadingCoin(false);
        },
        (error) => {
          Notification.showNotification("error", "Error", "Error");
          console.error("Error making deposit request:", error);
          setLoadingCoin(false);
        }
      );
    };
    handleDeposit();
  }, []);

  const chain = async (selectedCoin) => {
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }

    setLoadingChain(true);

    postRequest(
      "yes",
      "api/bybit/getCoinInfo",
      { coin: selectedCoin },
      (response) => {
        const allowedChains = ["ETH", "TRX", "TON"];
        const filteredChains = response?.data[0]?.chains?.filter((chain) =>
          allowedChains.includes(chain.chain)
        );

        setNetworkOptions(filteredChains || []);
        setLoadingChain(false);
      },
      (error) => {
        Notification.showNotification("error", "Error", "Error");
        console.error("Error fetching coin info:", error);
        setLoadingChain(false);
      },
      {}
    );
  };

  const handleWithdraw = () => {
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }

    setLoading(true);

    postRequest(
      "yes",
      "api/bybit/submitWithdrawal",
      {
        amount,
        coin,
        network,
        address,
        otp: otp.join(""),
      },
      (response) => {
        if (response.data.status === "success") {
          if (response.data.data.retMsg !== "success") {
            Notification.showNotification(
              "error",
              "Error",
              response.data.data.retMsg
            );
          } else {
            Notification.showNotification(
              "success",
              "Success",
              response.data.data.retMsg
            );
            setTransferBalance(transferBalance - amount);
            setLoading(false);
            onClose();
            closemainmodal();
          }
          setLoading(false);
        } else {
          Notification.showNotification("error", "Error", response.data.msg);
        }
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          error?.data?.error || "An unexpected error occurred.";
        Notification.showNotification("error", "Error", errorMessage);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (coin) {
      chain(coin);
    }
    if (otp.join("").length === 6) {
      handleWithdraw();
    }
  }, [coin, otp]);

  if (!show) {
    return null;
  }
  // const handleKeyPress = (e) => {
  //   if (e.key === "Enter") {
  //     handleWithdraw();
  //   }
  // };

  // const handleWithdraw = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${URL}/api/bybit/submitWithdrawal`,
  //       {
  //         coin,
  //         chain,
  //         address,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     console.log("Withdrawal successful:", response.data);
  //     Notification.showNotification(
  //       "success",
  //       "Success",
  //       "Payment Successfully Deposit"
  //     );
  //   } catch (error) {
  //     Notification.showNotification("error", "Error", error.message);
  //     console.error("Error during withdrawal:", error);
  //   }
  // };

  // const [loading, setLoading] = useState(false);

  const handleOtpChange = (e, index) => {
    const { value } = e.target;

    if (e.nativeEvent.inputType === "deleteContentBackward") {
      const newOtp = [...otp];
      newOtp[index] = "";
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
      setOtp(newOtp);
    } else if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleverifymodel = () => {
    // if (user?.FAverified) {
    if (address && network) {
      setWithdrawOTP(true);
      setError("");
    } else {
      setError("Please enter a value before proceeding.");
    }
    // }
  };

  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center">
      <div className="bg-Accent p-4 rounded-lg w-[400px] sm:w-[472px] animate-fadeIn">
        {withdrawOTP ? (
          <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center z-50 mx-4">
            <div className="w-full max-w-lg px-6 py-8 bg-[#211E34BF] border-[#FFFFFF26] rounded-3xl relative">
              <h1 className="text-white text-2xl font-bold text-center">
                2FA Verification
              </h1>
              <div className="flex justify-center mt-6 space-x-2">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    value={digit}
                    onChange={(e) => handleOtpChange(e, index)}
                    maxLength="1"
                    className="w-10 h-10 border border-primary text-primary bg-black rounded-md text-center text-lg"
                  />
                ))}
              </div>
              {message && (
                <p className="text-center mt-2 text-red-500">{message}</p>
              )}
              <div className="flex justify-center">
                <button
                  className={`w-full md:w-[50%] mx-auto mt-4 border border-primary text-black font-semibold bg-primary py-2 rounded ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={handleWithdraw}
                  disabled={loading}
                >
                  {loading ? "Verifying..." : "Verify"}
                </button>
              </div>

              <button
                onClick={() => setWithdrawOTP(false)}
                className="absolute top-2 right-2 p-1 rounded-full text-white hover:bg-gray-800"
              >
                X
              </button>
            </div>
          </div>
        ) : (
          <div
            className={`bg-[#211E34] pt-6 border rounded-3xl border-[#FFFFFF26] ${
              withdrawOTP ? "hidden" : ""
            }`}
          >
            <div className="relative flex justify-between mx-[4%]">
              <p className="font-sans text-base font-bold leading-[1.26] text-left">
                AlgoX Capital
              </p>
              <img
                src={Images.close}
                width={20}
                height={20}
                onClick={onClose}
                className=" right-6 cursor-pointer"
                alt="close-icon"
              />
            </div>

            <>
              <div>
                <p className="font-sans text-xl my-4 font-bold leading-[1.26] text-center text-primary">
                  Crypto Withdraw
                </p>
                <p className="font-poppins ml-[15%] text-[16px] font-normal leading-[30px] text-left text-accent">
                  Select coin
                </p>
                <div className="flex justify-center">
                  {loadingCoin ? (
                    <img
                      src="/icon/yellowloader.svg"
                      alt="Loading..."
                      className="w-7 mx-auto h-7 animate-spin"
                    />
                  ) : (
                    // <select
                    //   className="bg-gray text-accent w-[70%] rounded-lg h-12 px-4"
                    //   value={coin}
                    //   onChange={(e) => setCoin(e.target.value)}
                    // >
                    //   <option value="" disabled>
                    //     Select Coin
                    //   </option>
                    //   {coinOptions?.map((coinOption, index) => (
                    //     <option key={index} value={coinOption?.coin}>
                    //       {coinOption?.coin}
                    //     </option>
                    //   ))}
                    // </select>
                    <input
                      type="text"
                      className="bg-black  text-primary font-semibold w-[70%] rounded h-12 px-4"
                      value={coin}
                      readOnly
                      onChange={(e) => setCoin(e.target.value)}
                      placeholder="Select Coin"
                    />
                  )}
                </div>
              </div>
              <p className="font-poppins ml-[15%] text-[16px] font-normal mt-4 leading-[30px] text-left text-accent">
                Select network
              </p>
              <div className="flex justify-center">
                {loadingChain ? (
                  <img
                    src="/icon/yellowloader.svg"
                    alt="Loading..."
                    className="w-7 mx-auto h-7 animate-spin"
                  />
                ) : (
                  <select
                    className="bg-black  text-primary font-semibold w-[70%] rounded h-12 px-4"
                    value={network}
                    onChange={(e) => {
                      setNetwork(e.target.value);
                      setError("");
                    }}
                  >
                    <option value="" disabled>
                      Select Network
                    </option>
                    {networkOptions?.map((networkOption, index) => {
                      let displayValue = networkOption?.chain;
                      if (networkOption?.chain === "ETH") {
                        displayValue = "ETH(ERC20)";
                      } else if (networkOption?.chain === "TRX") {
                        displayValue = "TRX(TRC20)";
                      } else if (networkOption?.chain === "TON") {
                        displayValue = "TON";
                      }
                      return (
                        <option key={index} value={networkOption?.chain}>
                          {displayValue}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>

              <p className="font-poppins ml-[15%] mt-4 text-[16px] font-normal leading-[30px] text-left text-accent">
                Wallet ID
              </p>
              <div className="flex justify-center">
                <input
                  value={address}
                  // onKeyPress={handleKeyPress}
                  onChange={(e) => setAddress(e.target.value)}
                  className="bg-black  text-primary font-semibold w-[70%] rounded h-12 px-4"
                />
              </div>
              <p className="mt-2  text-red-600 text-center">{error}</p>
              <div className="mx-[4%] flex gap-6 my-8">
                <button
                  className={`w-full mx-auto border border-[#FFFFFF80] text-[#FFFFFF80] py-2 rounded`}
                  onClick={onClose}
                >
                  Back
                </button>
                <button
                  className={`${
                    loading ? "cursor-not-allowed opacity-50" : ""
                  } w-full border border-primary text-black font-semibold bg-primary py-2 rounded`}
                  onClick={handleverifymodel}
                >
                  {loading ? (
                    <img
                      src="/icon/loader.svg"
                      alt="Loading..."
                      className="w-7 mx-auto h-7 animate-spin"
                    />
                  ) : (
                    " Withdraw Now"
                  )}
                </button>
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  );
}

export default CryptoWithdrawModal;
