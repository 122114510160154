

import React, { createContext, useState, useEffect } from 'react';
import { fetchUserProfile } from '../backendServices/ApiCalls'; 

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const fetchUserFromAPI = async (token) => {
    try {
      const response = await fetchUserProfile(token);
      if (response.data.status === 'success') {
        setUser(response.data.user);
      } else {
        setUser(null); 
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.removeItem("token");
        setUser(null);
      }
    }
  };
  const verifyToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserFromAPI(token);
    } else {
      setUser(null); 
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, verifyToken }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
