import React, { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { Link } from "react-router-dom";

function DepositVerificationModal({ show, onClose, type }) {
  const { user } = useContext(UserContext);

  if (!show) {
    return null;
  }

  const Images = {
    close: "./icon/CDM.svg",
  };

  const isFAEnabled = user?.kycStatus !== "success";
  const actionText = type === "deposit" ? "deposit" : "withdraw";
  const title =
    user?.kycStatus !== "success"
      ? "You will need to complete KYC first."
      : "You have to enable 2FA verification";
  const name =
    user?.kycStatus !== "success"
      ? " you must need to complete KYC from your"
      : " you have to enable google 2FA verification from your";
  const message = (
    <span>
      To {actionText}, {name}{" "}
      <Link to={`/usersetting`} className="text-primary hover:underline">
        account settings
      </Link>
      .
    </span>
  );

  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-[#211E34BF] p-4 rounded-3xl w-[400px] sm:w-[472px] border border-[#FFFFFF26] animate-fadeIn">
        <div className="relative flex justify-end items-center p-4">
          <img
            src={Images.close}
            width={20}
            height={20}
            onClick={onClose}
            className="absolute top-4 right-4 cursor-pointer"
            alt="close-icon"
          />
        </div>

        <p className="font-poppins text-primary mt-6 font-semibold text-center">
          {title}
        </p>
        <p className="text-white px-4 mx-auto mt-6 mb-10 text-center max-w-80">
          {message}
        </p>

        <div className="flex justify-center">
          <button
            type="button"
            className="w-full md:w-[40%] mx-4 border border-[#FFFFFF80] text-[#FFFFFF80] py-2 rounded"
            onClick={onClose}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}

export default DepositVerificationModal;
