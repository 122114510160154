import React, { useEffect } from "react";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import ElevateBusiness from "../../components/ElevateBusiness";
import AccountFaqs from "../../components/AccountFaqs";
function TermsandConditions() {
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="account">
      <div>
        <div className="bg-account">
          <Navbar />
          <div className="mx-4 sm:mx-12 md:mx-20 mt-20 2xl:w-4/5 2xl:mx-auto">
            <h1 className="font-sora text-5xl font-bold leading-[62.72px] tracking-[-0.03em] text-center">
              Terms and Conditions
            </h1>
            <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-12">
              Introduction and Legal framework
            </h2>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              This file includes all the relevant information regarding the
              requirements for an imprint and to fulfill the legal requirements
              for documentation, transparency and to remain within the current
              legal framework provided by the State of Germany as well as on
              European level in connection with the general terms and
              conditions.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              Based on our core principles we see it as our duty to our
              customers to have a fully transparent framework for the use of our
              services. We want to point out that to our best efforts, we try to
              be compliant with all the herein mentioned as well as not
              mentioned standards, which are applicable for our area of
              services. However, to all our efforts, we cannot always guarantee
              to be compliant with every standard applicable in each country of
              business.
            </p>
            <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
              Information on remote sales and the closure of contracts in
              electronic commerce
            </h2>
            <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
              Contractual partner
            </h2>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              Your contractual partner for all orders on the website{" "}
              <a
                target="_blank"
                href="/www.AlgoX.Capital"
                className="text-[#42396D]"
              >
                www.AlgoX.Capital
              </a>{" "}
              is AlgoX Capital GbR, Sperberweg 13, 83556 Griesstätt, Germany
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              Legally represented by the managing directors jointly authorized
              to represent the company: Philipp Deutsch, Florian ABC, Lion Degen
              and Arben Kuqi.
            </p>
            <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
              Validity/General
            </h2>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              The business relationship between AlgoX Capital GbR (hereinafter
              referred to as "AlgoX Capital" or "we") and its contractual
              partner (hereinafter referred to as "Customer" or "you") shall be
              governed exclusively by the following General Terms and Conditions
              (hereinafter referred to as "GTC"). The GTC shall apply to all
              services and offers of AlgoX Capital (hereinafter referred to as
              "Service Offer"), unless otherwise contractually agreed.
            </p>
            <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-4">
              Services offered
            </h2>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              AlgoX Capital offers fee- and commission-internet-based trading
              services that allow you to gain an insight into the strategies
              offered in their live application.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              The services offered on the Internet pages of AlgoX Capital are
              expressly not addressed to persons in countries that prohibit the
              provision or retrieval of the content posted therein, in
              particular not to persons located in countries restricted by us.
              Persons who are subject to such restrictions may not enter into a
              contractual relationship with AlgoX Capital. In case of doubt,
              please contact AlgoX Capital.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              We reserve the right to conclude contracts only with customers who
              have reached full legal maturity in their country of residence. We
              temporarily reserve the right not to enter into business with
              commercial parties.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              The services included in the range of services can be used
              lifelong with appropriate payment. Various models are offered,
              models that can be used without a monthly basic fee and models
              where a basic fee is payable on a monthly basis. Profit sharing
              can also be charged additionally. Customers are free to combine
              the various products as they wish. If a subscription is purchased,
              the customer has a 14-day right of revocation of the purchase
              contract against reimbursement of the purchase price. Any losses
              incurred up to that point from the use of our product that are not
              attributable to gross negligence or faulty functioning of our
              product are not refundable.
            </p>
            <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
              Conclusion of the contract in electronic business transactions
            </h2>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              Depending on the payment option selected, the contract is
              concluded as described below:
            </p>
          </div>
        </div>
        <div className=" mx-4 sm:mx-12 md:mx-20 mt-2 2xl:w-4/5 2xl:mx-auto">
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Free services
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The contract is concluded when the customer registers for a free
            service offer (offer) by sending the order form to AlgoX Capital and
            AlgoX Capital confirms the conclusion of the contract to the
            customer in any expressly and clearly recognizable written form
            (acceptance).
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Chargeable services
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The contract is concluded when the customer registers for a service
            offered by AlgoX Capital (offer). The offer of services is submitted
            by the customer clicking on the "Purchase now" (or similar
            designation) button after entering the mandatory information
            requested there. As soon as we have received the offer, we check the
            entry and confirm the conclusion of the contract with a confirmation
            e-mail or in another written form (acceptance).
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Irrespective of our acceptance, we will immediately confirm receipt
            of the offer by e-mail or any other depressingly written consent.
            Please note that the confirmation of receipt does not yet constitute
            our acceptance.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Information about your consumer right of withdrawal and statutory
            right of revocation
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Our customers who are consumers within the meaning of § 13 BGB are
            entitled to the right of withdrawal listed below.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Cancellation policy and right of revocation
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            You have the right to revoke this contract within fourteen days
            without giving reasons. The revocation period is fourteen days from
            the date of conclusion of the contract. To exercise the right of
            revocation, you must inform us (AlgoX Capital GbR, Sperberweg 13,
            83556 Griesstätt, Support@algox.capital) of your decision to revoke
            this contract by an unequivocal statement (e.g. a letter sent by
            post, fax or e-mail). You can use the attached sample withdrawal
            form, but this is not mandatory.{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            To meet the withdrawal deadline, it is sufficient for you to send
            your notification of exercising your right of withdrawal before the
            withdrawal period has expired.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Consequences of revocation
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If you revoke this contract, we shall reimburse you for all payments
            we have received from you, including delivery costs (with the
            exception of additional costs resulting from the fact that you have
            chosen a different type of delivery than the cheapest standard
            delivery offered by us), immediately and at the latest within
            fourteen days from the day on which we receive notification of your
            revocation of this contract. For this repayment, we will use the
            same means of payment that you used for the original transaction,
            unless expressly agreed otherwise with you; under no circumstances
            will you be charged any fees for this repayment. If you have
            requested that the services should commence during the withdrawal
            period, you must pay us a reasonable amount corresponding to the
            proportion of the services already provided up to the time at which
            you inform us of the exercise of the right of withdrawal with regard
            to this contract compared to the total scope of the services
            provided for in the contract. The right of revocation shall expire
            prematurely if we have provided the service in full and have only
            begun to provide the service after you have given your express
            consent and at the same time confirmed your knowledge that you will
            lose your right of revocation if we fulfill the contract in full..
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Sample revocation form (If you wish to revoke the contract, please
            use this form by filling it out and sending it back to us per e-mail
            or post).
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-12 text-left">
            AlgoX Capital GbR,
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-2 text-left">
            Sperberweg 13,
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-2 text-left">
            83556 Griesstätt
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Other information (possibilities of correction, storage of the
            contract text, contract language)
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The text of the contract is stored by us but can no longer be
            retrieved from us after completion of the order process. However,
            upon our acceptance, the customer will receive the text of the
            contract by e-mail or any other written form. This can be printed
            out and/or archived by the customer.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The contract is concluded exclusively in English. Before sending
            your order, i.e. before clicking on the "Buy now" (or similar)
            button, you will be given the opportunity to recognize and correct
            any input errors. Please check your selection carefully before
            completing the order process.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            IT requirements{" "}
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Unless otherwise specified, a suitable end device and access to the
            Internet are required to use the services.{" "}
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            General Terms and Conditions (GTC)
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The following terms and conditions apply to all orders placed via
            the website{" "}
            <a
              target="_blank"
              href="/www.AlgoX.Capital"
              className="text-[#42396D]"
            >
              www.AlgoX.Capital
            </a>{" "}
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Prices
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The amount of the fee to be paid is based on the prices quoted at
            the time the contract is concluded. Accompanying materials are
            included to the extent specified in the service description
            ("service offer").
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            All prices are quoted in EURO and include VAT at the statutory rate.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Due date and payment options
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Payment for the services offered depends on the selected payment
            method. After completion of the order process, the customer will
            receive an invoice by e-mail or via any other written form from
            AlgoX Capital.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If the customer grants AlgoX Capital a SEPA mandate, payment will be
            made by direct debit from the bank account specified therein.{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The agreed fee is due for payment immediately, otherwise upon
            receipt of the invoice by the customer. A direct debit shall be
            collected ten days after the invoice date. The period for
            pre-notification shall be reduced to five days. The customer assures
            to ensure that the specified account is covered.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The form of invoicing is at the discretion of AlgoX Capital, in
            particular electronic invoicing is also permissible.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Start and end of contracts
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Unless the service offer specifies a contract term to the contrary,
            the customer receives access to services valid at that time for an
            open-ended period.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Extraordinary termination
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The right to extraordinary termination of the contract for important
            reasons remains unaffected.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Important reason shall be deemed to exist in particular if the
            customer attempts to circumvent or overcome devices which AlgoX
            Capital has set up to protect against the use of services beyond the
            contractually permissible scope or has repeatedly and significantly
            violated the rights of use granted and does not cease this behaviour
            despite a warning from AlgoX Capital.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Blocking of access
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            AlgoX Capital is entitled to block access to the services provided
            after termination of the contractual relationship with the customer.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Warranty
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The warranty for defects shall be governed by the statutory
            provisions, modified by the following provisions:{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            AlgoX Capital shall plan and perform the services offered with the
            care to be expected. AlgoX Capital does not guarantee the
            correctness, completeness and accuracy of the content of the
            services offered or the fulfilment of a purpose or success pursued
            by the customer with the use of the services offered.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The product descriptions of the service offer contained in the
            respective descriptions or otherwise made known to the customer in
            connection with the conclusion of the contract do not constitute a
            guarantee or assurance of properties.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The Customer shall only be entitled to terminate the contract in
            accordance with Section 543 (2) sentence 1 of the German Civil Code
            (BGB) for failure to grant use in the event that AlgoX Capital has
            not remedied the defect within a reasonable period of time, or the
            remedy of the defect is deemed to have failed.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The customer shall only be entitled to claim damages for defects to
            the extent that liability is not excluded or limited in accordance
            with the following provisions:
          </p>
          <div className="ml-[4%]">
            <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
              Liability
            </h2>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              We shall be liable without limitation for damages resulting from
              injury to life, body or health caused by our breach of duty, our
              legal representative or vicarious agent, as well as for damages
              caused by the absence of a quality guaranteed by us.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              We shall be liable without limitation for damages caused by us or
              a legal representative or one of our vicarious agents
              intentionally or through gross negligence. This does not apply to
              independent commercial agents acting on our behalf but on their
              own account.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              In the event of a breach of material contractual obligations
              caused by slight negligence, i.e. contractual obligations, our
              liability shall be limited to the amount of foreseeable damage
              typical for the contract. "Essential contractual obligations" are
              abstractly such obligations, the fulfilment of which makes the
              proper execution of the contract possible in the first place and
              on the observance of which a contracting party may regularly rely.{" "}
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              Liability under the Product Liability Act remains unaffected.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              Any further liability for damages is excluded.
            </p>
            <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
              Copyright and rights of use
            </h2>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              Upon conclusion of the contract, the customer is granted the right
              to use the services offered within the scope of the statutory or
              contractual provisions.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              For information and database products, non-transferable and
              non-exclusive rights of use are transferred for a limited period
              for the duration of the agreed term of the contract.{" "}
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              The services offered are protected by copyright as database works
              and databases. Individual documents are also protected by
              copyright. Insofar as the actual use of these services is
              unreasonably contrary to our legitimate interests, we are entitled
              to restrict or prevent access to the databases. This applies in
              particular to the duplication, distribution or public reproduction
              of essential components or the repeated and systematic
              duplication, distribution or public reproduction of non-essential
              components of the database. All copyrights, rights of use and
              other intellectual property rights to the information and database
              works not expressly listed below shall remain with AlgoX Capital.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              The Customer acquires the right to access information and
              databases from any terminal device that is suitable for this
              purpose.
            </p>
            <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
              The customer agrees to use the services only for his own purposes
              and not to make them available to third parties, either free of
              payment or in return for payment, or to enable access to them. The
              services may only be used by one person per membership.{" "}
            </p>
          </div>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Default/right of retention
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            If the customer is in default of payment, AlgoX Capital reserves the
            right to immediately and completely restrict access to the services
            provided. A delay is deemed to exist if payment is not received (in
            the case of use of the subscription service) within 10 calendar days
            of receipt of the order
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            In connection with this, we do not charge any fees or interest in
            the event of default.
          </p>
          <div className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            <p>The customer is only entitled to a right of retention</p>
            <ul className="list-disc pl-5">
              <li>
                insofar as it is based on the same contractual relationship;
                and/or
              </li>
              <li>
                insofar as the counterclaims on which the assertion of the right
                of retention is based are undisputed or have been legally
                established.,
              </li>
            </ul>
          </div>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Transfer of the contract
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            AlgoX Capital is entitled to transfer rights and obligations arising
            from this contractual relationship in whole or in part to a third
            party with a notice period of four weeks. In this case, the customer
            is entitled to terminate the contract within two weeks of
            notification of the transfer of the contract.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Disclaimer
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            AlgoX Capital accepts no liability for the trading suggestions
            provided. These in no way constitute a call for individual or
            general replication, not even implicitly. Trading suggestions based
            on trading signals or other information do not constitute an
            encouragement to buy or sell securities or derivative financial
            products. Liability for direct or indirect consequences of the
            published content is therefore excluded, unless otherwise stated in
            these GTC.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Our services are based on sources that we consider to be reputable,
            trustworthy and reliable. Although the evaluations and statements
            contained in the analyses and market assessments of AlgoX Capital
            have been prepared with reasonable care, we assume no responsibility
            or liability for the correctness, errors, accuracy, completeness and
            appropriateness of the facts, omissions or misstatements presented.
            The background information, market assessments and analyses
            published by AlgoX Capital constitute neither an offer to buy or
            sell the securities discussed nor a solicitation to buy or sell
            securities in general.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            AlgoX Capital is not responsible for any consequences, especially
            for losses, which may result from the use of or failure to use the
            opinions and conclusions contained in the publications. AlgoX
            Capital does not guarantee that the expected profit or the stated
            price targets will be achieved. We do not provide investment advice.
            We advise you to contact a bank or financial advisor, a licensed
            asset manager or any other specialist for information on the risks
            and opportunities of possible investment strategies.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Every investment involves risks. Customers who make investment
            decisions or carry out transactions on the basis of the published
            content do so entirely at their own risk. In particular, AlgoX
            Capital draws attention to the particularly high risks involved in
            transactions with warrants, derivatives and derivative financial
            instruments. Political, economic or other changes can lead to
            considerable price losses, and in the worst case even to a total
            loss.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Miscellaneous
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The law of the Federal Republic of Germany shall apply to the
            contract concluded with us to the exclusion of the UN Convention on
            Contracts for the International Sale of Goods. In business
            transactions with consumers within the European Union, the law of
            the consumer's place of residence may also be applicable, insofar as
            consumer law provisions are mandatory.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The place of performance and jurisdiction for entrepreneurs is
            Munich.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The European Commission has set up a platform for (out-of-court)
            online dispute resolution, which can be accessed via the following
            link:{" "}
            <a
              href=" https://ec.europa.eu/consumers/odr"
              target="_blank"
              className="text-[#42396D] mt-2"
            >
              https://ec.europa.eu/consumers/odr
            </a>{" "}
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            However, we would like to point out that we do not participate in a
            dispute resolution procedure before a consumer arbitration board{" "}
          </p>
        </div>
      </div>
      {/* <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[16px] md:hidden text-[#B0A4FF] mt-64 sm:mt-[45%] 2xl:mt-96 font-normal leading-[22.4px] tracking-[4.8px] text-center"
      >
        HAVE QUESTION?
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[44px] mt-3 md:hidden font-semibold leading-[49.28px] tracking-[-0.88px] text-center"
      >
        Frequently Asked <br />
        Question
      </p>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="lg:hidden"
      >
       <AccountFaqs question="What payment methods do you accept?" />
        <AccountFaqs question="Are there any hidden fees?" />
        <AccountFaqs question="Is there a free trial available?" />
        <AccountFaqs question="How do I get started?" />
        <AccountFaqs question="How do I access my account?" />
      </div> */}

      {/* <ElevateBusiness /> */}

      <Footer />
    </div>
  );
}
export default TermsandConditions;
