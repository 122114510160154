import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { URL } from "../constants/constant";
import Notification from "./Notification";
import { UserContext } from "../contexts/UserContext";
import ErrorIcon from "@mui/icons-material/Error"; // Import Material-UI error icon
import { postRequest } from "../backendServices/ApiCalls";

function ChangePasswordModal({ isOpen, onClose }) {
  const { user } = useContext(UserContext);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [faotp, setfaOtp] = useState(Array(6).fill(""));
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpLoading2, setOtpLoading2] = useState(false);
  const [otpMessage, setOtpMessage] = useState("");
  const [seconds, setSeconds] = useState(53);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [expired, setExpired] = useState(false);

  const f1 = () => {
    const interval = setInterval(() => {
      setSeconds((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          setExpired(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const resetOtpStates = () => {
    setOtp(Array(6).fill(""));
    setfaOtp(Array(6).fill(""));
    setOtpMessage("");
    setOtpLoading(false);
    setSeconds(53);
    setExpired(false);
  };

  // const handleChangePassword = async () => {
  //   if (newPassword !== confirmPassword) {
  //     setMessage("New password and confirmation do not match");
  //     return;
  //   }
  //   if (!navigator.onLine) {
  //     Notification.showNotification(
  //       "error",
  //       "Network Error",
  //       "Please check your internet connection and try again."
  //     );
  //     return;
  //   }

  //   setLoading(true);
  //   setMessage("");

  //   try {
  //     const token = localStorage.getItem("token");

  //     if (!token) {
  //       setMessage("User is not authenticated");
  //       setLoading(false);
  //       return;
  //     }

  //     const response = await axios.post(
  //       `${URL}/auth/change-password`,
  //       { oldPassword, newPassword, confirmPassword },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setShowOtpModal(true); // Open OTP modal
  //       Notification.showNotification(
  //         "success",
  //         "Success",
  //         "OTP has been sent to your email"
  //       );
  //       f1();
  //     }
  //   } catch (error) {
  //     setMessage(
  //       error.response?.data?.message ||
  //         "Error changing password. Please try again."
  //     );
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleChangePassword = async () => {
    setLoading(true);
    if (newPassword !== confirmPassword) {
      setMessage("New password and confirmation do not match");
      return;
    }

    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      setLoading(false);
      return;
    }
    setMessage("");
    postRequest("yes",
      "auth/change-password", // API endpoint
      { oldPassword, newPassword, confirmPassword }, // Parameters
      (response) => {
        if (response.status === 200) {
          setShowOtpModal(true); // Open OTP modal
          Notification.showNotification(
            "success",
            "Success",
            "OTP has been sent to your email"
          );
          f1(); // Call additional function if needed
        } else {
          // Handle unsuccessful response
          setMessage(
            response.data.message ||
              "Error changing password. Please try again."
          );
        }
        setLoading(false);
      },
      (error) => {
        setMessage(
          error.response?.data?.message ||
            "Error changing password. Please try again."
        );
        setLoading(false);
      },
      {}
    );
  };
  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    // Focus the next input if available
    if (e.target.nextSibling && e.target.nextSibling.tagName === "INPUT") {
      e.target.nextSibling.focus();
    }
  };

  const handleOtpPaste = (e) => {
    e.preventDefault(); // Prevent default paste behavior
    const pastedData = e.clipboardData.getData("Text").slice(0, otp.length); // Limit to OTP length
    const otpArray = pastedData.split("");

    // Update the OTP state with the pasted values
    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      otpArray.forEach((char, idx) => {
        updatedOtp[idx] = char;
      });
      return updatedOtp;
    });
    const nextInput = document.querySelector(
      `input#otp-input-${otpArray.length}`
    );
    if (nextInput) {
      nextInput.focus();
    }
  };
  // const handleOtpChange = (e, index) => {
  //   const newOtp = [...otp];
  //   newOtp[index] = e.target.value;
  //   setOtp(newOtp);

  //   if (e.target.nextSibling) {
  //     e.target.nextSibling.focus();
  //   }

  // };

  // const handleOtpSubmit = async () => {
  //   if (!navigator.onLine) {
  //     Notification.showNotification(
  //       "error",
  //       "Network Error",
  //       "Please check your internet connection and try again."
  //     );
  //     return;
  //   }
  //   setOtpLoading(true);
  //   setOtpMessage("");

  //   try {
  //     const token = localStorage.getItem("token");

  //     const response = await axios.post(
  //       `${URL}/auth/verify-password`,
  //       { otp: otp.join(""), newPassword, faotp: faotp.join("") },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (response.data.success) {
  //       Notification.showNotification(
  //         "success",
  //         "Success",
  //         "Password has been changed successfully"
  //       );
  //       setTimeout(() => {}, 1000);
  //       setShowOtpModal(false); // Close OTP modal after success
  //       onClose(); // Close the modal after success
  //     } else {
  //       setOtpMessage(response.data.message);
  //     }
  //   } catch (error) {
  //     setOtpMessage(
  //       error.response?.data?.message ||
  //         "Error verifying OTP. Please try again."
  //     );
  //     setOtp(Array(6).fill(""));
  //     setLoading(false);
  //   } finally {
  //     setOtpLoading(false);
  //     setOtpLoading2(false)

  //   }
  // };

  const handleOtpSubmit = async () => {
    setOtpLoading(true);
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }

    setOtpMessage("");

    // Use postRequest utility function for verifying password with OTP
    postRequest("yes",
      "auth/verify-password", // API endpoint
      { otp: otp.join(""), newPassword, faotp: faotp.join("") }, // Parameters
      (response) => {
        // Handle success response
        if (response.data.success) {
          Notification.showNotification(
            "success",
            "Success",
            "Password has been changed successfully"
          );
          setShowOtpModal(false); // Close OTP modal after success
          onClose(); // Close the modal after success
        } else {
          setOtpMessage(response.data.message);
        }
        setOtpLoading(false); // Ensure loading state is reset
      },
      (error) => {
        // Handle error response
        setOtpMessage(
          error.response?.data?.message ||
            "Error verifying OTP. Please try again."
        );
        setOtp(Array(6).fill("")); // Reset OTP input fields
        setOtpLoading(false); // Ensure loading state is reset
      },
      {
        headers: {},
      }
    );
  };

  const handleResendCode = () => {
    setOtpLoading2(true);
    resetOtpStates();
    handleChangePassword(); // Resend the OTP by calling the change password API
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleChangePassword();
    }
  };

  const handleOtpPress = (e) => {
    if (e.key === "Enter") {
      handleOtpSubmit();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "" && e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  const obfuscateEmail = () => {
    const [username, domain] = user?.email.split("@");
    const maskedUsername = username.replace(/./g, "*");
    return ` ${maskedUsername}@${domain}`;
  };

  const handlefaOtpChange = (e, index) => {
    const { value } = e.target;

    if (e.nativeEvent.inputType === "deleteContentBackward") {
      const newfaOtp = [...faotp];
      newfaOtp[index] = "";
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
      setfaOtp(newfaOtp);
    } else if (/^[0-9]$/.test(value)) {
      const newfaOtp = [...faotp];
      newfaOtp[index] = value;
      setfaOtp(newfaOtp);
      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };
  useEffect(() => {
    if (otp.join("").length === 6) {
      handleOtpSubmit();
    }
  }, [otp]);
  const maskedEmail = (() => {
    const email = user?.email || "No email available";
    const [localPart, domainPart] = email.split("@");
    const firstPart = localPart.slice(0, 3);
    const maskedLocal = `${firstPart}***`;
    const maskedDomain =
      domainPart?.length > 3 ? `${domainPart.slice(0, 3)}***` : domainPart;
    return `${maskedLocal}@${maskedDomain}`;
  })();
  const Images = {
    close: "./icon/CDM.svg",
  };
  return (
    isOpen && (
      <>
        {!showOtpModal && (
          <div className="fixed inset-0  flex items-center mx-4 justify-center z-50 backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
            <div className="w-full max-w-md  px-4 sm:px-6 lg:px-8 h-auto rounded-3xl  bg-[#211E34BF] border-[#FFFFFF26] border mx-auto relative">
              <h1 className="text-accent font-helvetica tracking-tighter mt-4 mb-0 text-center text-2xl md:text-2xl xl:text-4xl font-bold leading-tight">
                Change Password
              </h1>
              <div className="max-w-[488px] mt-6 md:mt-4 pt-6 md:pt-4 h-auto  mx-auto rounded-3xl">
                {/* <div className="w-12 h-12 md:w-16 md:h-16 rounded-2xl items-center mx-auto justify-center flex bg-primary bg-opacity-20">
                  <img
                    src="/icon/yellowProfile.png"
                    className="w-5 h-5 md:w-6 md:h-6"
                    alt="Profile"
                  />
                </div> */}
                <div className="relative mx-4 md:mx-8 ">
                  <p className="text-accent font-helvetica tracking-tighter mb-0 text-base md:text-md font-semibold leading-relaxed block">
                    Old Password
                  </p>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="oldPassword"
                    onKeyPress={handleKeyPress}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    className="bg-black w-[96%] h-10 ml-[1%] rounded text-primary font-semibold"
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-accent"
                  >
                    {showPassword ? (
                      <img src="/icon/openeye.svg" className="size-5 mt-7" />
                    ) : (
                      <img src="/icon/closeeye.svg" className="size-5 mt-7" />
                    )}
                  </span>
                </div>
                <div className="relative mx-4 md:mx-8 mt-4">
                  <p className="text-accent font-helvetica tracking-tighter mb-0 text-base md:text-md font-semibold leading-relaxed block">
                    New Password
                  </p>
                  <input
                    type={showNewPassword ? "text" : "password"}
                    name="newPassword"
                    value={newPassword}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="bg-black w-[96%] h-10 ml-[1%] rounded text-primary font-semibold"
                  />
                  <span
                    onClick={toggleNewPasswordVisibility}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-accent"
                  >
                    {showNewPassword ? (
                      <img src="/icon/openeye.svg" className="size-5 mt-7" />
                    ) : (
                      <img src="/icon/closeeye.svg" className="size-5 mt-7" />
                    )}
                  </span>
                </div>

                <div className="relative mx-4 md:mx-8 mt-4">
                  <p className="text-accent font-helvetica tracking-tighter mb-0 text-base md:text-md font-semibold leading-relaxed block">
                    Confirm Password
                  </p>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={confirmPassword}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="bg-black w-[96%] h-10 ml-[1%] rounded text-primary font-semibold"
                  />
                  <span
                    onClick={toggleConfirmPasswordVisibility}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-accent"
                  >
                    {showConfirmPassword ? (
                      <img src="/icon/openeye.svg" className="size-5 mt-7" />
                    ) : (
                      <img src="/icon/closeeye.svg" className="size-5 mt-7" />
                    )}
                  </span>
                </div>
                {message && (
                  <p className="text-center mt-2 text-red-500">{message}</p>
                )}
                <div className="mx-[4%] flex gap-4 my-8">
                  <button
                    className={`w-full mx-auto border border-[#FFFFFF80] text-[#FFFFFF80] py-2 rounded`}
                    onClick={onClose}
                  >
                    Back
                  </button>
                  <button
                    className={`${
                      loading ? "cursor-not-allowed opacity-50" : ""
                    } w-full border border-primary text-black font-semibold bg-primary py-2 rounded`}
                    onClick={handleChangePassword}
                    disabled={loading}
                  >
                    {loading ? (
                      <img
                        src="/icon/loader.svg"
                        alt="Loading..."
                        className="w-7 mx-auto h-7 animate-spin"
                      />
                    ) : (
                      "Change Password"
                    )}
                  </button>
                </div>
                {/* <button
                  disabled={loading}
                  onClick={handleChangePassword}
                  className={`border-primary text-black font-semibold bg-primary w-[85%] md:w-[60%] mt-6 mb-6 rounded text-sm md:text-md py-3 md:py-4 mx-auto flex items-center justify-center ${
                    loading ? "cursor-not-allowed opacity-50" : ""
                  }`}
                >
                  {loading ? (
                    <img
                      src="/icon/loader.svg"
                      alt="Loading..."
                      className="w-5 md:w-7 mx-auto h-5 md:h-7 animate-spin"
                    />
                  ) : (
                    "Change Password"
                  )}
                </button> */}
              </div>
              <button
                onClick={onClose}
                className="absolute top-4 right-4 p-1 rounded-full text-accent hover:bg-gray-800 focus:outline-none"
              >
                <img
                  src={Images.close}
                  width={20}
                  height={20}
                  alt="close-icon"
                />
              </button>
            </div>
          </div>
        )}

        {showOtpModal && (
          <div className="fixed inset-0 flex items-center mx-4 justify-center z-50 backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
            <div className="w-full max-w-md sm:max-w-lg lg:max-w-xl px-4 sm:px-6 lg:px-8 h-auto rounded-3xl bg-[#211E34BF] border-[#FFFFFF26] border mx-auto relative">
              <h1 className="text-accent font-helvetica tracking-tighter mt-6 mb-0 text-center text-xl sm:text-2xl lg:text-3xl font-bold leading-tight">
                Password Verification Code
              </h1>
              <div className=" my-6 md:my-4 py-6 md:py-4 h-auto  mx-auto rounded-3xl">
                {/* <div className="w-12 h-12 md:w-16 md:h-16 rounded-2xl items-center mx-auto justify-center flex bg-primary bg-opacity-20">
                  <img
                    src="/icon/yellowProfile.png"
                    className="w-5 h-5 md:w-6 md:h-6"
                    alt="OTP"
                  />
                </div> */}
                {otpMessage && (
                  <div className="flex items-center bg-red-200 border border-red-500 mx-auto rounded-lg p-2 mt-2 shadow-md">
                    <div className="flex mx-auto gap-4">
                      <ErrorIcon className="text-center text-red-500 " />{" "}
                      {/* Material-UI icon */}
                      <p className="text-red-600">{otpMessage}</p>
                    </div>
                  </div>
                )}
                <div className="flex justify-center mb-4 sm:mb-4 items-center">
                  <img src="/icon/email.svg" alt="Email Icon" />
                  <p className="font-poppins text-sm font-normal ml-2 leading-5 sm:leading-6 text-left">
                    A verification code will be sent to off{" "}
                    <span className="font-poppins text-sm font-bold leading-5 sm:leading-6 text-left">
                      {/* {obfuscateEmail(user.email)} */}
                      {maskedEmail}
                    </span>
                  </p>
                </div>
                <div className="mx-4 md:mx-8 mt-4 text-center">
                  <div className="flex justify-center mt-6 space-x-2 sm:space-x-3">
                    {otp.map((digit, index) => (
                      <input
                        className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 border border-primary text-primary bg-black rounded-md text-center text-lg sm:text-xl"
                        type="text"
                        name="otp"
                        maxLength="1"
                        // onKeyPress={handleOtpPress}
                        onPaste={handleOtpPaste}
                        key={index}
                        value={digit}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                      />
                    ))}
                  </div>
                  <p className="font-poppins text-xs sm:text-sm mt-2 font-normal leading-5 sm:leading-6 text-end">
                    Didn’t able to receive verification code?
                    {expired ? (
                      <span className="text-red-500 font-semibold">
                        {" "}
                        Code Expired
                      </span>
                    ) : (
                      <span> {seconds}s</span>
                    )}
                  </p>
                  {user?.FAverified && (
                    <>
                      <p className="font-poppins mb-2 mt-4 sm:mb-2 text-sm sm:text-base font-normal ml-2 leading-5 sm:leading-6 text-left">
                        Google Two-Factor Authentication Code
                      </p>
                      <div className="flex justify-center mt-6 space-x-2">
                        {faotp.map((dig, ind) => (
                          <input
                            key={ind}
                            id={`otp-input-${ind}`}
                            type="text"
                            value={dig}
                            onChange={(e) => handlefaOtpChange(e, ind)}
                            maxLength="1"
                            className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 border border-primary text-primary bg-black rounded-md text-center text-lg sm:text-xl"
                          />
                        ))}
                      </div>
                    </>
                  )}
                  <p className="font-poppins text-xs sm:text-sm font-semibold mt-8 sm:mt-6 pb-2  leading-5 sm:leading-6 text-start text-primary">
                    Having Problems With Verification?
                  </p>

                  <div className="flex">
                    <button
                      disabled={otpLoading}
                      onClick={!expired ? handleOtpSubmit : null}
                      className={`border-primary text-black font-semibold bg-primary w-[35%] md:w-[30%] mt-4  rounded text-sm md:text-md py-3 md:py-4 mx-auto flex items-center justify-center ${
                        otpLoading ? "cursor-not-allowed opacity-50" : ""
                      }`}
                    >
                      {otpLoading ? (
                        <img
                          src="/icon/loader.svg"
                          alt="Loading..."
                          className="w-5 md:w-7 mx-auto h-5 md:h-7 animate-spin"
                        />
                      ) : (
                        "Verify OTP"
                      )}
                    </button>
                    {expired && (
                      <button
                        disabled={otpLoading2}
                        onClick={expired && handleResendCode}
                        className={`border-primary text-black font-semibold bg-primary w-[55%] md:w-[30%] mt-4 rounded text-sm md:text-md py-3 md:py-4 mx-auto flex items-center justify-center ${
                          otpLoading ? "cursor-not-allowed opacity-50" : ""
                        }`}
                      >
                        {otpLoading2 ? (
                          <img
                            src="/icon/loader.svg"
                            alt="Loading..."
                            className="w-5 md:w-7 mx-auto h-5 md:h-7 animate-spin"
                          />
                        ) : (
                          "Resend Code"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <button
                onClick={onClose}
                className="absolute top-2 right-2 p-1 rounded-full text-accent hover:bg-gray-800 focus:outline-none"
              >
                <img
                  src={Images.close}
                  width={20}
                  height={20}
                  alt="close-icon"
                />
              </button>
            </div>
          </div>
        )}
      </>
    )
  );
}

export default ChangePasswordModal;
