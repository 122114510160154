import Navbar from "../../components/Navbar";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import ChangePasswordModal from "../../components/ChangePasswordModal";
import VarifyEmail from "../../components/VarifyEmail";
import { URL } from "../../constants/constant";
import Notification from "../../components/Notification";
import ChangeEmailModal from "../../components/ChangeEmailModal";
import Varification2FaModal from "../../components/Varification2FaModal";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Tooltip from "@mui/material/Tooltip";
import BigLoader from "../../components/BigLoader";
import { postRequest } from "../../backendServices/ApiCalls";
import React, { useState, useRef, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import { UserContext } from "../../contexts/UserContext";
const darkYellow = "#FFBF00";
const YellowSwitch = styled(Switch)(({ theme }) => ({
  width: 70,
  height: 45,
  "& .MuiSwitch-switchBase.Mui-checked": {
    transform: "translateX(28px)",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: alpha(darkYellow, theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-thumb": {
    width: 28,
    height: 28,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: darkYellow,
  },
}));

function UserSetting() {
  const { user, setUser } = useContext(UserContext);
  const [faModalOpen, setFaModalOpen] = useState(false);
  const [secret, setSecret] = useState(null);
  const [focusedItem, setFocusedItem] = useState("personal");
  const [isChecked, setIsChecked] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const referralRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const openEmailModal = () => setIsEmailModalOpen(true);
  const closeEmailModal = () => setIsEmailModalOpen(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [showFullEmail, setShowFullEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [loadingfa, setLoadingfa] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);

  // const open2FaModal = async () => {
  //   setFaModalOpen(true);
  //   const token = localStorage.getItem("token");
  //   const response = await axios.post(
  //     `${URL}/auth/generatesecret`,
  //     {},
  //     {
  //       headers: { Authorization: `Bearer ${token}` },
  //     }
  //   );
  //   setSecret(response?.data?.secret);
  // };

  // Function to close the 2FA modal
  const open2FaModal = async () => {
    setLoadingfa(true);
    setFaModalOpen(true);
    const headers = {};
    postRequest(
      "yes",
      "auth/generatesecret",
      {},
      (response) => {
        setSecret(response?.data?.secret);
        setLoadingfa(false);
      },
      (error) => {
        console.error("Error generating secret:", error);
        setLoadingfa(false);
      },
      headers
    );
  };
  const closeverificationModal = () => {
    setFaModalOpen(false);
  };
  const handleCloseModal = () => setModalOpen(false);
  // const handleOpenModal = async () => {
  //   try {
  //     setLoadingVerify(true);
  //     const token = localStorage.getItem("token");
  //     const response = await axios.post(
  //       `${URL}/auth/sendotp-email`,
  //       {},
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     );

  //     if (response.data.success) {
  //       Notification.showNotification(
  //         "success",
  //         "Success",
  //         "OTP has been sent to your email"
  //       );
  //       setModalOpen(true);
  //       // setIsEmailVerified(true);
  //     }
  //   } catch (error) {}
  //   finally{
  //     setLoadingVerify(false)
  //   }
  // };
  const handleOpenModal = async () => {
    setLoadingVerify(true); // Start loading before making the request

    postRequest(
      "yes",
      "auth/sendotp-email",
      {},
      (response) => {
        if (response.data.success) {
          Notification.showNotification(
            "success",
            "Success",
            "OTP has been sent to your email"
          );
          setModalOpen(true);
          // Optionally set email verified if needed
          // setIsEmailVerified(true);
        } else {
          // Optionally handle unsuccessful response
          Notification.showNotification("error", "Error", "Failed to send OTP");
        }
        // Reset loading state after handling the response
        setLoadingVerify(false);
      },

      (error) => {
        console.error("Error sending OTP:", error);
        Notification.showNotification("error", "Error", "Failed to send OTP");
        // Reset loading state after handling the error
        setLoadingVerify(false);
      }
    );
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleEmailVerified = () => {
    handleCloseModal();
  };
  useEffect(() => {
    if (user?.varifiedemailotp == "verified") {
      setIsEmailVerified(true);
      handleCloseModal();
    }
    if (!user) {
      setMainLoader(true);
    } else {
      setMainLoader(false);
    }
  }, [user, handleCloseModal]);

  const label = { inputProps: { "aria-label": "Color switch demo" } };
  useEffect(() => {
    if (referralRef.current) {
      referralRef.current.focus();
      setFocusedItem("personal");
    }
  }, []);

  const toggleEmailVisibility = () => {
    setShowFullEmail(!showFullEmail);
  };

  const maskedEmail = (() => {
    const email = user?.email || "No email available";
    const [localPart, domainPart] = email.split("@");
    const firstPart = localPart.slice(0, 3);
    const maskedLocal = `${firstPart}***`;
    const maskedDomain =
      domainPart?.length > 3 ? `${domainPart.slice(0, 3)}**` : domainPart;
    return `${maskedLocal}@${maskedDomain}`;
  })();
  const handleVerify = async () => {
    setLoading1(true);
    const headers = {};
    postRequest(
      "yes",
      "auth/userAccessCode",
      {},
      (data) => {
        if (data?.data?.success) {
          window.location.href = `https://verify.authenticating.com/?token=${data?.data?.kyctoken}`;
        } else {
          console.error("Failed to store data in backend:", data);
          Notification.showNotification(
            "error",
            "Verification Error",
            data.message || "Could not verify user."
          );
        }
        setLoading1(false);
      },
      (error) => {
        console.error("An error occurred during verification:", error);
        Notification.showNotification(
          "error",
          "Verification Error",
          "Could not verify user."
        );
        setLoading1(false);
      },
      headers
    );
  };
  const expirecheck = async () => {
    setLoading(true);
    try {
      const currentTime = new Date();
      const expirationTime = new Date(user?.expiresOn);
      if (currentTime < expirationTime) {
        window.location.href = `https://verify.authenticating.com/?token=${user.kyctoken}`;
      } else {
        postRequest(
          "yes",
          "auth/recreatetoken",
          {},
          (data) => {
            window.location.href = `https://verify.authenticating.com/?token=${data?.data?.kyctoken}`;
          },
          (error) => {
            console.error("Failed to store data in backend:", error);
            Notification.showNotification(
              "error",
              "Verification Error",
              error.message || "Could not verify user."
            );
          },
          { "Content-Type": "application/json" }
        );
      }
    } catch (error) {
      console.error("An error occurred during token expiration check:", error);
    } finally {
      setLoading(false);
    }
  };
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("usersetting")) {
      setFocusedItem("personal");
    } else if (path.includes("referral")) {
      setFocusedItem("referral");
    } else if (path.includes("notificationsetting")) {
      setFocusedItem("notification");
    }
    if (user?.FAverified) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [location, user]);

  const handleFocus = (item) => {
    setFocusedItem(item);
  };

  const handleBlur = () => {
    setFocusedItem(null);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(user.bybituid).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  };

  const remove2fa = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Unauthorized access. Please log in first.");
        return;
      }
      setLoadingfa(true);
      const fetchResponse = await fetch(`${URL}/auth/removefa`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
      });

      const data = await fetchResponse.json();

      if (data?.success) {
        setLoadingfa(false);
        setUser(data?.user);
        // setUser(prevUser => {
        //   const { FAverified, ...rest } = prevUser;
        //   return rest;
        // });
        Notification.showNotification(
          "success",
          "Google 2FA Removed",
          data?.msg
        );
      }
    } catch (error) {
      setLoadingfa(false);
      Notification.showNotification(
        "error",
        "Unable to remove Google 2FA",
        error?.msg
      );
    }
  };
  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      open2FaModal();
    } else {
      remove2fa();
    }
  };
  return (
    <div className="body">
      <div className=" navbar-bg-color px-4">
        <Navbar />
        <div className="grid grid-cols-1 sm:grid-cols-5">
          <div className="col-span-1 hidden md:block  min-h-screen">
            <div className="grid bg-[#222222] w-56 px-3 pt-6 pb-24 rounded-3xl ml-auto mt-20">
              <Link to="/usersetting">
                <div>
                  <div
                    className={`flex gap-2 border border-white rounded-lg cursor-pointer py-3 px-2  ${
                      focusedItem === "personal"
                        ? "text-primary "
                        : "text-accent "
                    }`}
                    tabIndex={0}
                    onFocus={() => handleFocus("personal")}
                    onBlur={handleBlur}
                  >
                    <img src="/icon/ps.svg" alt="vector icon" />
                    <p
                      className={`font-helvetica text-[10px] text-accent lg:text-[14px] mr-4 font-medium leading-[16.94px] text-left ${
                        focusedItem === "personal" ? "text-accent" : ""
                      }`}
                    >
                      Personal Settings
                    </p>
                    {/* <img
                      src={`/icon/${
                        focusedItem === "notification"
                          ? "downarrow2.svg"
                          : "downarrow1.svg"
                      }`}
                      alt="vector icon"
                    /> */}
                  </div>
                </div>
              </Link>
              {/* <Link to="/notificationsetting">
              <div>
                <div
                  ref={referralRef}
                  className={`flex gap-2  cursor-pointer py-3 px-2  rounded-sm ${
                    focusedItem === "notification"
                      ? "text-primary "
                      : "text-accent "
                  }`}
                  tabIndex={0}
                  onFocus={() => handleFocus("notification")}
                  onBlur={handleBlur}
                >
                  <img src="/icon/nc.svg" alt="vector icon" />

                  <p
                    className={`font-helvetica text-[10px] lg:text-[14px] mr-4 text-[#9B9B9B]  font-medium leading-[16.94px] text-left ${
                      focusedItem === "notification" ? "text-accent" : ""
                    }`}
                  >
                    Notification Centre
                  </p>
                   <img
                      src={`/icon/${
                        focusedItem === "notification"
                          ? "downarrow1.svg"
                          : "downarrow2.svg"
                      }`}
                      alt="vector icon"
                    />
                </div>
              </div>
              </Link> */}

              {/* <Link to="/referral">
              <div>
                <div
                  className={`flex gap-2  cursor-pointer py-3 px-2  rounded-sm ${
                    focusedItem === "referral"
                      ? "text-primary "
                      : "text-accent "
                  }`}
                  tabIndex={0}
                  onFocus={() => handleFocus("referral")}
                  onBlur={handleBlur}
                >
                  <img src="/icon/rp.svg" alt="vector icon" />
                  <p
                    className={`font-helvetica text-[10px] lg:text-[14px] text-[#9B9B9B] mr-4 font-medium leading-[16.94px] text-left ${
                      focusedItem === "referral" ? "text-[#9B9B9B]" : ""
                    }`}
                  >
                    Referral Program
                  </p>
                </div>
              </div>
              </Link> */}
            </div>
          </div>
          <div className="col-span-4">
            {!user ? (
              <div
                className="flex justify-center h-full items-center"
                style={{ height: "50vh" }}
              >
                <BigLoader />
              </div>
            ) : (
              <>
                <div className="mt-24 mx-4 sm:ml-[20%] lg:ml-[10%]">
                  <p className="font-helvetica text-3xl sm:text-4xl font-bold leading-[22px] text-left">
                    General Settings
                  </p>
                  <p className="font-helvetica text-xl font-bold leading-6 text-left mt-16">
                    E-Mail Authentification
                  </p>
                  <p className="font-helvetica text-base font-normal mt-4 text-[#9B9B9B]  lg:max-w-[50%] 2xl:max-w-[40%] leading-6 text-left">
                    For login, withdrawal, password retrieval, security settings
                    change, and API management verification.
                  </p>
                  <div className="flex gap-4 mt-4">
                    <div className="flex items-center w-72 h-12 rounded-xl my-auto bg-black gap-4">
                      <p className="font-sora  2xl:text-[14px] text-[12px] sm:min-w-60 text-[#9B9B9B] text-center font-medium leading-[22px]">
                        {showFullEmail ? user.email : maskedEmail}
                      </p>
                      {/* <Tooltip
                         title={
                           isEmailVerified
                             ? "Email Verified"
                             : "Email Not Verified"
                         }
                       >
                         <CheckCircleRoundedIcon
                           sx={{
                             color: isEmailVerified ? "#065f46" : "gray",
                             fontSize: 16,
                           }}
                         />
                       </Tooltip> */}
                      {showFullEmail ? (
                        <img
                          src="/icon/openeye.svg"
                          alt="Eye Icon"
                          className="size-4 cursor-pointer"
                          onClick={toggleEmailVisibility}
                        />
                      ) : (
                        <img
                          src="/icon/closeeye.svg"
                          alt="Eye Icon"
                          className="size-4 cursor-pointer"
                          onClick={toggleEmailVisibility}
                        />
                      )}
                    </div>
                    {!isEmailVerified ? (
                      ""
                    ) : (
                      <div className="flex gap-2">
                        <img
                          src="./icon/vtick.svg"
                          className="w-6 h-3 my-auto "
                        />
                        <p className="font-sora text-xs font-bold leading-[23.94px] text-center text-primary my-auto">
                          verified
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="mt-4 flex gap-4">
                    <div
                      className="flex gap-2 px-1 sm:px-6 py-2 border w-44 rounded cursor-pointer border-primary"
                      onClick={openEmailModal}
                    >
                      <img src="./icon/cemail.svg" className="size-5 my-auto" />
                      <p className="font-sora text-[10px] sm:text-[12px] font-bold text-center text-primary">
                        Change E-Mail
                      </p>
                    </div>

                    {!isEmailVerified ? (
                      <>
                        {loadingVerify ? (
                          <img
                            src="/icon/loaderwhite.svg"
                            alt="Loading..."
                            className="w-7 ml-[5%] mt-1 h-7 animate-spin"
                          />
                        ) : (
                          <div
                            className="flex gap-2 px-1 sm:px-6 py-2 border w-44 rounded cursor-pointer border-[#9B9B9B]"
                            onClick={handleOpenModal}
                            disabled={isEmailVerified}
                          >
                            <img
                              src="./icon/vemail.svg"
                              className="size-5 my-auto"
                            />
                            <p className="font-sora text-[10px] sm:text-[12px]font-bold leading-6 text-center text-[#9B9B9B]">
                              Verify E-Mail
                            </p>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="gap-4 flex mt-16">
                    <p className="font-helvetica text-lg font-bold leading-6 text-left">
                      {" "}
                      Identity Verification
                    </p>
                    <div>
                      {user?.kycStatus === "success" ? (
                        <div className="flex gap-2">
                          <img
                            src="./icon/vtick.svg"
                            className="w-6 h-3 my-auto "
                          />
                          <p className="font-sora text-xs font-bold leading-[23.94px] text-center text-primary my-auto">
                            verified
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <p className="font-helvetica text-base font-normal leading-6 md:max-w-[40%] 2xl:max-w-[30%] mt-4 text-left text-[#9B9B9B]">
                    Complete verification to increase daily withdrawal limit
                  </p>
                  <div
                    className="flex gap-2 px-6 py-2 mt-4 border w-44 rounded cursor-pointer border-primary"
                    // onClick={
                    //   user?.kycStatus === "success" || user?.kyctoken
                    //     ? null
                    //     : handleVerify
                    // }
                    onClick={() => {
                      if (!user?.kyctoken) {
                        handleVerify();
                      } else if (
                        user?.kyctoken &&
                        user?.kycStatus !== "success"
                      ) {
                        expirecheck();
                      } else {
                        return;
                      }
                    }}
                  >
                    {!loading1 ? (
                      <>
                        <img
                          src="./icon/verifyidentity.svg"
                          className="size-5 my-auto"
                        />
                        <p className="font-sora text-xs font-bold leading-6 text-center text-primary">
                          {user?.kycStatus === "success" && "Verified"}
                          {!user?.kyctoken && "Verify Identity"}
                          {user?.kyctoken && (
                            <p className="font-sora text-xs font-bold leading-6 text-center text-primary">
                              {loading ? (
                                <img
                                  src="/icon/loaderwhite.svg"
                                  alt="Loading..."
                                  className="w-7 ml-[10%] h-7 animate-spin"
                                />
                              ) : (
                                `${
                                  user?.kycStatus === "success"
                                    ? ""
                                    : "Resume Here"
                                }`
                              )}
                            </p>
                          )}
                        </p>
                      </>
                    ) : (
                      <img
                        src="/icon/loaderwhite.svg"
                        alt="Loading..."
                        className="w-7 ml-[5%] h-7 animate-spin"
                      />
                    )}
                  </div>
                  <p className="font-helvetica text-xl font-bold leading-6 text-left mt-16">
                    Login Password
                  </p>
                  <p className="font-helvetica text-base font-normal mt-4 text-[#9B9B9B] md:max-w-[50%] 2xl:max-w-[40%] leading-6 text-left">
                    For Login, withdraw, password retrieval and API management
                    verification.
                  </p>
                  <div
                    className="flex gap-2 px-6 py-2 border w-48 mt-4 rounded cursor-pointer border-primary"
                    onClick={openModal}
                  >
                    <img src="./icon/cemail.svg" className="size-5 my-auto" />
                    <p className="font-sora text-xs font-bold leading-6 text-center text-primary">
                      Change Password
                    </p>
                  </div>
                  <div className="border-t border-[#404040] mt-16 lg:w-[65%]">
                    <p className="font-helvetica text-2xl sm:text-4xl font-bold leading-[22px] text-left mt-16">
                      Advanced Protection
                    </p>
                    <div className="flex mt-16 justify-between">
                      <div>
                        <p className="font-helvetica text-lg font-bold leading-6 text-left">
                          Google Two Factor Authntification
                        </p>
                        <p className="font-helvetica text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
                          For login, withdrawal, password reset and API
                          management verification
                        </p>
                      </div>
                      {loadingfa ? (
                        <img
                          src="/icon/loaderwhite.svg"
                          alt="Loading..."
                          className="w-7 ml-[1%] h-7 animate-spin"
                        />
                      ) : (
                        <YellowSwitch
                          checked={user?.FAverified ? isChecked : false}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="border-t border-[#404040] mb-96 mt-16 lg:w-[65%]">
                    <p className="font-helvetica text-2xl sm:text-4xl font-bold leading-[22px] text-left mt-16">
                      Withdrawal Security
                    </p>
                    <div className="flex mt-16 justify-between">
                      <div>
                        <p className="font-helvetica text-lg font-bold leading-6 text-left">
                          Withdraw Adress Whitelist
                        </p>
                        <p className="font-helvetica text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
                          Once enabled, you can only withdraw to addresses saved
                          in your address book.
                        </p>
                      </div>
                      <YellowSwitch {...label} defaultChecked={false} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <Varification2FaModal
        faModalOpen={faModalOpen}
        closeverificationModal={closeverificationModal}
        secret={secret}
      />
      <ChangePasswordModal isOpen={isModalOpen} onClose={closeModal} />
      <ChangeEmailModal isOpen={isEmailModalOpen} onClose={closeEmailModal} />
      {modalOpen && <VarifyEmail onClose={handleEmailVerified} />}
    </div>
  );
}

export default UserSetting;
