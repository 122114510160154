import React from "react";

function Partnership() {
  return (
    <div>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="font-sora text-[16px] text-primary mt-44 font-normal leading-[22.4px] tracking-[4.8px] text-center"
      >
        WORKING WITH
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="font-sora mt-3 font-semibold leading-[49.28px] tracking-[-0.88px] text-center text-[34px] sm:text-[50px]"
      >
        PARTNERSHIPS & INTEGRATIONS
      </p>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4  sm:gap-6 mx-4 sm:mx-40"
      >
        <div className="sm:py-8  py-8  transform transition-transform duration-300 hover:-translate-y-2 2xl:py-9 rounded-3xl mt-12  bg-[#222222]">
          <img
            src="/icon/nexo.png"
            className="2xl:w-32 my-auto w-32 sm:w-24 mx-auto"
            alt="nexo"
          />
        </div>
        <div className="sm:py-6  py-8  transform  transition-transform duration-300 hover:-translate-y-2 2xl:py-9 rounded-3xl mt-8 sm:mt-12  bg-[#222222]">
          <img
            src="/icon/bitgit.png"
            className="2xl:w-32 my-4 w-32 sm:w-24 mx-auto"
            alt="nexo"
          />
        </div>
        <div className="sm:py-5  py-8  transform transition-transform duration-300 hover:-translate-y-2 2xl:py-8 rounded-3xl mt-8 sm:mt-12  bg-[#222222]">
          <img
            src="/icon/tradingview.png"
            className="2xl:w-36 my-6 w-40 sm:w-28 mx-auto"
            alt="nexo"
          />
        </div>
        <div className="sm:py-5  py-8  transform transition-transform duration-300 hover:-translate-y-2 2xl:py-8 rounded-3xl mt-8 sm:mt-12  bg-[#222222]">
          <img
            src="/icon/bybit.png"
            className="2xl:w-24 my-4 w-24 sm:w-16 mx-auto"
            alt="nexo"
          />
        </div>
       
      </div>
    </div>
  );
}

export default Partnership;
