import React from "react";
import Featurecard from "./Featurecard";

function Feature() {
  return (
    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">
      <p className="font-sora text-[16px] text-primary mt-44 font-normal leading-[22.4px] tracking-[4.8px] text-center">
        FEATURES
      </p>
      <p className="font-sora mx-2  mt-3 font-semibold leading-[49.28px] tracking-[-0.88px] text-center text-[34px] sm:text-[50px]">
        OUR FEATURES FOR EASY PROFITS
      </p>
      <div className=" flex lg:flex-row flex-col mt-12  justify-center gap-8 items-center  ">
        <Featurecard
          img="/icon/1.svg"
          h1="No experience"
          p="Profit from crypto, whether you're a
seasoned trader or a complete beginner"
        />
        <Featurecard
          img="/icon/2.svg"
          h1="Security first"
          p="We dont own or hold your funds, they
are safe with a third party exchange
(ByBit)"
        />
        <Featurecard
          img="/icon/3.svg"
          h1="Set & forget"
          p="Set up our smart trading bot in just
10 minutes and let it work for you 24/7"
        />
      </div>
      <div className=" flex lg:flex-row flex-col mt-8 sm:mt-0   justify-center gap-8 items-center  ">
        <Featurecard
          img="/icon/4.svg"
          h1="Long-term"
          p="Our primary focus is on maximizing your
profits over the long run"
        />
        <Featurecard
          img="/icon/5.svg"
          h1="Optimization"
          p="Our algorithms are constantly refined to
deliver peak performance"
        />
        <Featurecard
          img="/icon/6.svg"
          h1="Transparency"
          p="Monitor your investment easily at anytime
with our intuitive dashboard"
        />
      </div>
    </div>
  );
}

export default Feature;
