import React, { useContext, useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import axios from "axios";
import { URL } from "../constants/constant";
import Notification from "./Notification";
import { UserContext } from "../contexts/UserContext";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BigLoader from "./BigLoader";
import { postRequest } from "../backendServices/ApiCalls";

function Varification2FaModal({ faModalOpen, closeverificationModal, secret }) {
  const { user, setUser } = useContext(UserContext);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (otp.join("").length === 6) {
      handleOtpSubmit();
    }
  }, [otp]);

  const handleOtpChange = (e, index) => {
    const { value } = e.target;

    if (e.nativeEvent.inputType === "deleteContentBackward") {
      const newOtp = [...otp];
      newOtp[index] = "";
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
      setOtp(newOtp);
    } else if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };
  // const handleOtpSubmit = async () => {
  //   if (otp.join("").length === 6) {
  //     setLoading(true);
  //     const token = localStorage.getItem("token");
  //     const otpToken = otp.join("");

  //     try {
  //       const response = await axios.post(
  //         `${URL}/auth/verifyfa`,
  //         { token: otpToken },
  //         {
  //           headers: { Authorization: `Bearer ${token}` },
  //         }
  //       );

  //       if (response.data.isVerified) {
  //         Notification.showNotification(
  //           "success",
  //           "Success",
  //           "2FA Verification enabled successfully on enable google 2fa."
  //         );
  //         closeverificationModal();
  //         setUser((prevUser) => ({
  //           ...prevUser,
  //           FAverified: response.data.isVerified,
  //         }));
  //         setOtp(Array(6).fill(""));
  //       } else {
  //         Notification.showNotification("error", "Error", "Invalid Code.");
  //         setOtp(Array(6).fill(""));
  //       }
  //     } catch (error) {
  //       console.error("Error verifying OTP:", error);
  //       Notification.showNotification(
  //         "error",
  //         "Error",
  //         "An error occurred during verification."
  //       );
  //       setOtp(Array(6).fill(""));
  //     } finally {
  //       setLoading(false);
  //     }
  //   } else {
  //     setMessage("Please enter a valid 6-digit OTP.");
  //   }
  // };
  const handleOtpSubmit = () => {
    if (otp.join("").length === 6) {
        setLoading(true);
        const otpToken = otp.join("");
        postRequest("yes",
            "auth/verifyfa", // API endpoint
            { token: otpToken }, // Request payload
            (response) => {
                if (response.data.isVerified) {
                    Notification.showNotification(
                        "success",
                        "Success",
                        "2FA Verification enabled successfully on enable Google 2FA."
                    );
                    closeverificationModal();
                    setUser((prevUser) => ({
                        ...prevUser,
                        FAverified: response.data.isVerified,
                    }));
                    setOtp(Array(6).fill("")); // Reset OTP fields
                } else {
                    Notification.showNotification("error", "Error", "Invalid Code.");
                    setOtp(Array(6).fill("")); // Reset OTP fields
                }
                setLoading(false); // Reset loading state
            },
            (error) => {
                // Handle error response
                console.error("Error verifying OTP:", error);
                Notification.showNotification(
                    "error",
                    "Error",
                    "An error occurred during verification."
                );
                setOtp(Array(6).fill("")); // Reset OTP fields
                setLoading(false); // Reset loading state
            }
        );
    } else {
      setMessage("Please enter a valid 6-digit OTP.");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(secret.base32)
      .then(() => {
        // alert("Secret key copied to clipboard!");
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleOtpSubmit();
    }
  };
  const Images = {
    close: "./icon/CDM.svg",
  };
  return (
    faModalOpen && (
      <div className="fixed inset-0  flex items-center justify-center z-50 mx-4 backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
        <div className="w-full max-w-lg px-6 py-8 bg-[#211E34BF] border-[#FFFFFF26] border rounded-3xl relative">
          <div className="relative flex justify-end items-center p-4">
            <img
              src={Images.close}
              width={20}
              height={20}
              onClick={closeverificationModal}
              className="absolute cursor-pointer"
              alt="close-icon"
            />
          </div>

          {secret ? (
            <>
              <h1 className="text-white font-helvetica mb-2 text-2xl font-bold text-center">
                2FA Verification
              </h1>
              <div className="flex flex-col items-center">
                <QRCodeSVG
                  value={secret.otpauth_url}
                  bgColor="#ffffff"
                  fgColor="#000000"
                  level="Q"
                  includeMargin={true}
                  className=""
                  width={150}
                  height={150}
                />
                <div className="mt-4 text-white text-center">
                  <h2 className="text-xl">Scan the QR Code</h2>
                  <p className="break-words mb-2">Secret Key:</p>
                  <div className="relative" onClick={copyToClipboard}>
                    {copied && (
                      <div className="absolute top-[-25px] left-1/2 transform -translate-x-1/2 bg-black text-accent text-sm rounded-md px-2 py-1">
                        Copied!
                      </div>
                    )}
                    <input
                      type="text"
                      value={secret.base32}
                      readOnly
                      className="w-full cursor-pointer md:w-96 text-center bg-black text-white border border-gray-600 rounded-md pl-2 pr-9 py-2"
                      onClick={(e) => e.target.select()}
                    />
                    <button
                      className="absolute right-2 top-2 text-white ml-2"
                      aria-label="Copy secret key"
                    >
                      <ContentCopyIcon />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-6 space-x-2">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    value={digit}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => handleOtpChange(e, index)}
                    maxLength="1"
                    className="w-10 h-10 border border-primary text-primary bg-black rounded-md text-center text-lg"
                  />
                ))}
              </div>
              {message && (
                <p className="text-center mt-2 text-red-500">{message}</p>
              )}
              <div className="mx-[4%] flex gap-4 my-8">
                <button
                  className={`w-full mx-auto border border-[#FFFFFF80] text-[#FFFFFF80] py-2 rounded`}
                  onClick={closeverificationModal}
                >
                  Back
                </button>
                <button
                  className={`${
                    loading ? "cursor-not-allowed opacity-50" : ""
                  } w-full border border-primary text-black font-semibold bg-primary py-2 rounded`}
                  onClick={handleOtpSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <img
                      src="/icon/loader.svg"
                      alt="Loading..."
                      className="w-7 mx-auto h-7 animate-spin"
                    />
                  ) : (
                    "Verify"
                  )}
                </button>
              </div>
              {/* <div className="flex justify-center items-center">
          <button
            className={`w-full sm:w-[50%] mt-4 mx-auto border border-primary text-black font-semibold bg-primary py-2 rounded ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleOtpSubmit}
            disabled={loading}
          >
            {loading ? "Verifying..." : "Verify"}
          </button></div> */}
              {/* <button
            onClick={closeverificationModal}
            className="absolute top-2 right-2 p-1 rounded-full text-white hover:bg-gray-800"
          >
            X
          </button> */}
            </>
          ) : (
            <div className="flex justify-center mt-2">
              <BigLoader />
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default Varification2FaModal;
