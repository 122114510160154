import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

function Elevate() {
  const {user} = useContext(UserContext)

  return (
    <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" className= "w-[90%] 2xl:w-[70%] mx-auto h-auto rounded-3xl  mt-40 bg-[#222222]">
    <div className="flex flex-col gap-2 lg:flex-row"> 
      <div>
        <p className="font-sora text-[16px] mt-16 ml-4 sm:ml-12 text-primary font-normal leading-[22.4px] tracking-[4.8px] text-left">
          Elevate Your Profits
        </p>
        <p className="font-sora text-[36px] 2xl:text-[44px] mt-4 ml-4 sm:ml-12 font-semibold leading-[49.28px] tracking-[-0.88px] text-left">
          ARE YOU READY TO PROFIT FROM CRYPTO?
        </p>
        <p className="font-sora text-[14px] 2xl:text-[16px] mt-6 ml-4 sm:ml-12 font-medium leading-[24px] tracking-[-0.32px] text-left">
          Step into the future of investing today. Our powerful algorithms work
          tirelessly to maximize your crypto profits while you sit back and
          watch your wealth grow. Start your journey to financial freedom with
          AlgoX Capital - where smart technology meets smarter investing.
        </p>
        {!user && (
        <Link to="/signup">
        <button className="bg-primary  mt-10 ml-4 sm:ml-10 mb-2 lg:block my-auto px-8 h-14 rounded-md hover:bg-[#f1ae1c] hover:text-accent">
        <p className="font-sora text-[14px] text-black font-semibold leading-[23.94px] text-left hover:text-accent">
        Sign Up
        </p>
      </button>
      </Link>
        )}
      </div>
      <img src="/icon/elevate.svg" className=" w-[90%] sm:w-[550px] lg:w-[650px] ml-auto pt-32 sm:pt-40 lg:pt-20 h-[90%px] sm:h-[580px] lg:h-[518px]" alt="elevate img" />
    </div>
    </div>
  );
}

export default Elevate;
