import React, { useContext, useRef, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import DepositModal from "../../components/DepositModal";
import WithdrawModal from "../../components/WithdrawModal";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { Dropdown, Space, Select } from "antd";
import { Switch } from "antd";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getRequest, postRequest } from "../../backendServices/ApiCalls";
import Notification from "../../components/Notification";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import BigLoader from "../../components/BigLoader";
function Dashboard() {
  const { user, setUser } = useContext(UserContext);
  // console.log(user)
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [depositModal, setDepositModal] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const timePeriods = ["1W", "1M", "3M", "6M", "1Y", "MAX"];
  const [selected, setSelected] = useState("1W");
  const savedCurrency = localStorage.getItem("selectedCurrency") || "USD";
  const [selectedCurrency, setSelectedCurrency] = useState(savedCurrency);
  const location = useLocation();
  const [total, setTotal] = useState(0);
  const [transferBalance, setTransferBalance] = useState(0);
  const [dailybalance, setDailybalance] = useState(0);
  const [totalInvest, setTotalInvest] = useState(0);
  const [totalGain, setTotalGain] = useState(0);
  const [usdValue, setUsdValue] = useState(0);
  const [difference, setDifference] = useState(0);
  const [totalGainDiff, setTotalGainDiff] = useState(0);
  const [monthlyvalue, setMonthlyvalue] = useState(0);
  const [monthlyValueDiff, setMonthlyValueDiff] = useState(0);
  const [createddate, setCreateddate] = useState([]);
  const [walletBalances, setWalletBalances] = useState([]);
  const [visible, setVisible] = useState(false);
  const [trades, setTrades] = useState([]);
  const [activeTab, setActiveTab] = useState("content");
  const [isModalOpenPro, setIsModalOpenPro] = useState(false);
  const [loadingkyc, setLoadingkyc] = useState(false);
  const [expireloadingkyc, setExpireLoadingkyc] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState("standard");
  const openModalpro = () => setIsModalOpenPro(true);
  const closeModalpro = () => setIsModalOpenPro(false);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("1D");
  const [selectedSymbol, setSelectedSymbol] = useState("SWDA");
  const widgetRef = useRef(null);
  const [switches, setSwitches] = useState(Array(11).fill(false));
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleTogglecurrency = () => {
    setIsOpen(!isOpen);
  };
  const formattedDates = createddate.map((dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  });
  const handleSelectCurrency = (currency) => {
    setSelectedCurrency(currency);
    setIsOpen(false);
  };
  const togglewithdrawModal = () => {
    setIsWithdrawModalOpen(!isWithdrawModalOpen);
  };
  const handleCloseWithdrawModal = () => {
    setWithdrawModal(false);
  };
  const handleWithdrawModalClick = () => {
    setWithdrawModal(true);
  };

  const handleClick = () => {
    setVisible(!visible);
  };

  const handleToggle = (index) => {
    const newSwitches = Array(11).fill(false);
    newSwitches[index] = !switches[index];
    setSwitches(newSwitches);

    const symbols = [
      "SWDA",
      "SWDA",
      "BTCUSD",
      "GOLD",
      "NDX",
      "ETHUSD",
      "SILVER",
      "SPX",
      "SOLUSD",
      "COPPER",
      "DJI",
      "DOGEUSD",
      "PLATINUM",
    ];

    if (newSwitches[index]) {
      setSelectedSymbol(symbols[index]);
    }
  };

  const content = (
    <div className="w-[472px] h-[369px] relative">
      <div className="mt-4 ml-2 flex justify-between">
        <p className="font-sora text-[16px] text-white font-bold leading-[20.16px] text-left">
          Filter Settings
        </p>
        <img
          onClick={() => setVisible(false)}
          src="/icon/crossd.svg"
          size="10px"
          className="cursor-pointer mr-2"
        />
      </div>
      <div className="flex p-4 space-x-4">
        <div
          onClick={() => setActiveTab("content")}
          className={`cursor-pointer font-medium pb-1 ${
            activeTab === "content"
              ? "text-[#FFFFFFBF] border-b-2 border-[#FFFFFFBF]"
              : "text-[#FFFFFF40]"
          }`}
        >
          Content
        </div>
        <div
          onClick={() => setActiveTab("timeframe")}
          className={`cursor-pointer font-medium pb-1 ${
            activeTab === "timeframe"
              ? "text-[#FFFFFFBF] border-b-2 border-[#FFFFFFBF]"
              : "text-[#FFFFFF40]"
          }`}
        >
          Timeframe
        </div>
      </div>
      <div className="p-4 text-white">
        {activeTab === "content" ? (
          <div>
            <div className="flex gap-2">
              <Switch
                checked={switches[0]}
                onChange={() => handleToggle(0)}
                style={{
                  backgroundColor: switches[0] ? "#455C90" : "#9B9B9B",
                  borderColor: "#404040",
                }}
              />
              <p className="font-sora text-xs font-bold leading-6 text-left text-[#FFFFFFBF]">
                Show All
              </p>
            </div>
            <hr className="text-[#FFFFFF26] mt-6" />
            <div className="mt-6">
              <div className="grid grid-cols-3 gap-6 mt-4">
                {["MSCI World", "Bitcoin", "Gold"].map((item, index) => (
                  <div className="flex gap-2 items-center" key={index}>
                    <Switch
                      checked={switches[index + 1]} // index + 1 since 0 is used for Show All
                      onChange={() => handleToggle(index + 1)}
                      style={{
                        backgroundColor: switches[index + 1]
                          ? "#455C90"
                          : "#9B9B9B",
                        borderColor: "#404040",
                      }}
                    />
                    <p className="font-sora text-xs font-normal leading-6 text-left text-[#FFFFFFBF]">
                      {item}
                    </p>
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-3 gap-6 mt-4">
                {["NASDAQ-100", "Ethereum", "Silver"].map((item, index) => (
                  <div className="flex gap-2 items-center" key={index + 3}>
                    <Switch
                      checked={switches[index + 4]} // index + 4 for the next set of switches
                      onChange={() => handleToggle(index + 4)}
                      style={{
                        backgroundColor: switches[index + 4]
                          ? "#455C90"
                          : "#9B9B9B",
                        borderColor: "#404040",
                      }}
                    />
                    <p className="font-sora text-xs font-normal leading-6 text-left text-[#FFFFFFBF]">
                      {item}
                    </p>
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-3 gap-6 mt-4">
                {["S&P 500", "Solana", "Copper"].map((item, index) => (
                  <div className="flex gap-2 items-center" key={index + 6}>
                    <Switch
                      checked={switches[index + 7]} // index + 7 for the next set of switches
                      onChange={() => handleToggle(index + 7)}
                      style={{
                        backgroundColor: switches[index + 7]
                          ? "#455C90"
                          : "#9B9B9B",
                        borderColor: "#404040",
                      }}
                    />
                    <p className="font-sora text-xs font-normal leading-6 text-left text-[#FFFFFFBF]">
                      {item}
                    </p>
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-3 gap-6 mt-4">
                {["Dow Jones", "Dodge", "Platinum"].map((item, index) => (
                  <div className="flex gap-2 items-center" key={index + 9}>
                    <Switch
                      checked={switches[index + 10]} // index + 10 for the last set of switches
                      onChange={() => handleToggle(index + 10)}
                      style={{
                        backgroundColor: switches[index + 10]
                          ? "#455C90"
                          : "#9B9B9B",
                        borderColor: "#404040",
                      }}
                    />
                    <p className="font-sora text-xs font-normal leading-6 text-left text-[#FFFFFFBF]">
                      {item}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className=" rounded-lg text-gray-300 max-w-md mx-auto">
            {/* Standard Option */}
            <div>
              <div className="flex items-center mb-3">
                <input
                  type="radio"
                  name="rangeOption"
                  value="standard"
                  className="custom-radio mr-2"
                  checked={selectedOption === "standard"}
                  onChange={() => setSelectedOption("standard")}
                />
                <span className="font-sora text-[14px] font-bold leading-[17.64px] text-[#FFFFFF80] text-center pl-6">
                  Standard
                </span>
              </div>
              <div className="flex space-x-3 w-[47%] ml-[10%] py-1 pl-2 rounded-2xl bg-[#FFFFFF0D]">
                <button className="font-sora text-sm font-bold text-[#FFFFFF80] focus:text-white leading-[17.64px] text-center">
                  1W
                </button>
                <button className="font-sora text-sm font-bold text-[#FFFFFF80] focus:text-white leading-[17.64px] text-center">
                  1M
                </button>
                <button className="font-sora text-sm font-bold text-[#FFFFFF80] focus:text-white leading-[17.64px] text-center">
                  3M
                </button>
                <button className="font-sora text-sm font-bold text-[#FFFFFF80] focus:text-white leading-[17.64px] text-center">
                  6M
                </button>
                <button className="font-sora text-sm font-bold text-[#FFFFFF80] focus:text-white leading-[17.64px] text-center">
                  1Y
                </button>
                <button className="font-sora text-sm font-bold text-[#FFFFFF80] focus:text-white leading-[17.64px] text-center">
                  MAX
                </button>
              </div>
            </div>
            <hr className="text-[#FFFFFF26] my-6" />
            <div>
              <div className="flex items-center mb-3">
                <input
                  type="radio"
                  name="rangeOption"
                  value="custom"
                  className="custom-radio mr-2"
                  checked={selectedOption === "custom"}
                  onChange={() => setSelectedOption("custom")}
                />
                <span className="font-sora text-[14px] font-bold leading-[17.64px] text-[#FFFFFF80] text-center pl-6">
                  Custom
                </span>
              </div>
              <div className="mb-2 ml-[10%]">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Start Date"
                      sx={{
                        width: "300px",
                        "& .MuiInputBase-root": {
                          color: "#9B9B9B", // Text color
                          borderColor: "#9B9B9B", // Border color
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#9B9B9B", // Border outline color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#9B9B9B", // Border color on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#9B9B9B", // Border color when focused
                        },
                        "& .MuiInputLabel-root": {
                          color: "#9B9B9B", // Label color
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#9B9B9B", // Focused label color
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#9B9B9B", // Icon color
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="mb-2 ml-[10%]">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="End Date"
                      sx={{
                        width: "300px",
                        "& .MuiInputBase-root": {
                          color: "#9B9B9B", // Text color
                          borderColor: "#9B9B9B", // Border color
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#9B9B9B", // Border outline color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#9B9B9B", // Border color on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#9B9B9B", // Border color when focused
                        },
                        "& .MuiInputLabel-root": {
                          color: "#9B9B9B", // Label color
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#9B9B9B", // Focused label color
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#9B9B9B", // Icon color
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const [selectedAssets, setSelectedAssets] = useState([]);

  const assets = [
    { symbol: "MSCIWORLD", name: "MSCI World" },
    { symbol: "BTCUSD", name: "Bitcoin" },
    { symbol: "XAUUSD", name: "Gold" },
    { symbol: "SOLUSD", name: "Solana" },
  ];

  const fetchExchangeRate = () => {
    getRequest(
      "auth/getsettings",
      (response) => {
        if (response?.data?.success) {
          setExchangeRate(response?.data?.exchangeRate);
        } else {
          setError(response?.data?.message);
        }
      },
      (error) => {
        setError("Error fetching exchange rate");
        // console.error("An error occurred:", error);
      }
    );
  };

  const totalinvest = () => {
    try {
      postRequest(
        "yes",
        "api/bybit/getSubDepositRecords",
        "",
        (response) => {
          if (response?.data?.rows) {
            const totalAmount = response.data.rows.reduce((sum, row) => {
              return sum + parseFloat(row?.amount || 0);
            }, 0);
            setTotalInvest(totalAmount);
          } else {
            setError("No rows available in the response.");
          }
        },
        (error) => {
          console.error("Unexpected Error:", error.message);
        }
      );
    } catch (error) {
      console.error("Unexpected Error:", error.message);
      setError("An unexpected error occurred.");
    }
  };

  // const getWalletBalanceDaily = () => {
  //   postRequest(
  //     "yes",
  //     "api/bybit/getDailyBalance",
  //     "",
  //     (response) => {
  //       const walletBalances = response?.data?.balance?.map(
  //         (item) => item.amount
  //       );
  //       if (walletBalances && walletBalances.length > 1) {
  //         const firstValue = walletBalances[0];
  //         setMonthlyvalue(parseFloat(walletBalances[29]?.amount));
  //         const secondLastValue = walletBalances[walletBalances.length - 2];
  //         setTotalGain(firstValue);
  //         setDailybalance(secondLastValue);
  //       } else {
  //         console.error("Insufficient data or error fetching balances");
  //         setDailybalance(null);
  //       }
  //     },
  //     (error) => {
  //       setError("Error fetching exchange rate");
  //       console.error("An error occurred:", error.message);
  //     }
  //   );
  // };
  const getAllCoinsBalance = () => {
    postRequest(
      "yes",
      "api/bybit/getAllCoinsBalance",
      { accountType: "FUND" },
      (response) => {
        if (response.data.response.result.balance) {
          const balanceData = response.data.response.result.balance;
          let totalAmount = parseFloat(balanceData?.[0].walletBalance);
          let transferBalance = parseFloat(balanceData?.[0].transferBalance);

          postRequest(
            "yes",
            "api/bybit/getAllCoinsBalance",
            { accountType: "UNIFIED" },
            (response2) => {
              if (response2.data.response.result.balance) {
                const balanceData2 = response2.data.response.result.balance;
                totalAmount += parseFloat(balanceData2?.[0].walletBalance);

                setTotal(totalAmount);
                setUsdValue(totalAmount);
                setTransferBalance(parseFloat(transferBalance));
              } else {
                setError(
                  response?.data?.message || "No balance data available"
                );
              }
            },
            (error) => {
              setError("Error fetching exchange rate");
              console.error("An error occurred:", error.message);
            }
          );
        } else {
          setError(response?.data?.message || "No balance data available");
        }
      },
      (error) => {
        setError("Error fetching exchange rate");
        console.error("An error occurred:", error.message);
      }
    );
  };
  const getPortfolioValue = (period) => {
    postRequest(
      "yes",
      "api/bybit/getDailyBalance",
      "",
      (response) => {
        if (response?.data?.balance) {
          if (Array.isArray(response.data.balance)) {
            const balanceData = response?.data?.balance?.map(
              (item) => item.amount
            );
            const createddate = response?.data?.balance?.map(
              (item) => item.createdAt
            );
            const lastDaysBalances = {
              "1W": balanceData.slice(-7),
              "1M": balanceData.slice(-30),
              "3M": balanceData.slice(-90),
              "6M": balanceData.slice(-180),
              "1Y": balanceData.slice(-365),
              MAX: balanceData,
            };
            setWalletBalances(lastDaysBalances[period]);
            setCreateddate(createddate);
            if (balanceData && balanceData.length > 1) {
              const firstValue = balanceData[0];
              setMonthlyvalue(parseFloat(balanceData[29]?.amount));
              const secondLastValue = balanceData[balanceData.length - 2];
              setTotalGain(firstValue);
              setDailybalance(secondLastValue);
            } else {
              console.error("Insufficient data or error fetching balances");
              setDailybalance(null);
            }
          }
        }
        setIsLoader(false);
      },
      (error) => {
        setIsLoader(false);
        console.error("An error occurred:", error.message);
      }
    );
  };
  // const getPortfolioValue = () => {
  //   postRequest(
  //     "yes",
  //     "api/bybit/getDailyBalance",
  //     "",
  //     (response) => {
  //       if (response?.data?.balance) {
  //         if (Array.isArray(response.data.balance)) {
  //           const balanceData = response.data.balance.map(
  //             (item) => item.amount
  //           );
  //           const lastDaysBalancesweek = balanceData.slice(-7);
  //           const lastDaysBalancesmonth = balanceData.slice(-30);
  //           const lastDaysBalancesthreemonth = balanceData.slice(-90);
  //           const lastDaysBalancessixmonth = balanceData.slice(-180);
  //           const lastDaysBalancessixyear = balanceData.slice(-365);
  //           const lastDaysBalancessixmax = balanceData;
  // const createddate = response.data.balance.map(
  //   (item) => item.createdAt
  // );
  //           if (selected === "1W") {
  //             setWalletBalances(lastDaysBalancesweek);
  //           } else if (selected === "1M") {
  //             setWalletBalances(lastDaysBalancesmonth);
  //           } else if (selected === "3M") {
  //             setWalletBalances(lastDaysBalancesthreemonth);
  //           } else if (selected === "6M") {
  //             setWalletBalances(lastDaysBalancessixmonth);
  //           } else if (selected === "1Y") {
  //             setWalletBalances(lastDaysBalancessixyear);
  //           } else if (selected === "MAX") {
  //             setWalletBalances(lastDaysBalancessixmax);
  //           }

  // setCreateddate(createddate);
  //           setIsLoader(false);
  //         } else {
  //           setIsLoader(false);
  //         }
  //       } else {
  //         setIsLoader(false);
  //         console.error("An error occurred:", error);
  //       }
  //     },
  //     (error) => {
  //       console.error("An error occurred:", error.message);
  //     }
  //   );
  // };

  useEffect(() => {
    // getWalletBalanceDaily();
    getAllCoinsBalance();
    fetchExchangeRate();
    totalinvest();
    getPortfolioValue(selected);
  }, []);
  useEffect(() => {
    if (selectedCurrency) {
      localStorage.setItem("selectedCurrency", selectedCurrency);
    }
    if (dailybalance > 0 && total > 0) {
      const calculateDifference = () => {
        if (dailybalance < total) {
          const percentage = (dailybalance / total - 1) * 100;
          setDifference(+Math.abs(percentage));
        } else if (dailybalance > total) {
          const percentage = (total / dailybalance - 1) * 100;
          setDifference(-Math.abs(percentage));
        }
      };

      calculateDifference();
    }
    if (totalGain > 0 && total > 0) {
      const calculateTotalGain = () => {
        const percentage = (total / totalGain - 1) * 100;
        setTotalGainDiff(percentage);
      };

      calculateTotalGain();
    }
    if (monthlyvalue > 0 && total > 0) {
      const calculateTotalGain = () => {
        const percentage = (total / monthlyvalue - 1) * 100;
        setMonthlyValueDiff(percentage);
      };

      calculateTotalGain();
    }
  }, [dailybalance, total, totalGain, selectedCurrency]);
  const getConvertedValue = () => {
    if (selectedCurrency === "USD") {
      return usdValue?.toFixed(2);
    } else if (selectedCurrency === "EUR" && exchangeRate) {
      return (usdValue / exchangeRate).toFixed(2);
    }
    return usdValue;
  };
  const getTotalInvestConvertedValue = () => {
    if (selectedCurrency === "USD") {
      return totalInvest?.toFixed(2);
    } else if (selectedCurrency === "EUR" && exchangeRate) {
      return (totalInvest / exchangeRate).toFixed(2);
    }
    return usdValue;
  };
  const handleTimeFrameChange = (time) => {
    setSelectedTimeFrame(time);
  };
  const handleAssetChange = (symbol) => {
    if (selectedAssets.includes(symbol)) {
      setSelectedAssets(selectedAssets.filter((asset) => asset !== symbol));
    } else {
      setSelectedAssets([...selectedAssets, symbol]);
    }
  };
  const adjustedBalances =
    selectedCurrency === "EUR" && exchangeRate
      ? walletBalances.map((value) => value / exchangeRate)
      : walletBalances;
  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#ffa500", "#808080"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: formattedDates,
      labels: {
        style: {
          colors: "#ffffff",
        },
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },

    yaxis: {
      labels: {
        style: {
          colors: "#ffffff",
        },
        formatter: (value) => value.toFixed(2),
      },
    },
    grid: {
      borderColor: "#303030",
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.5,
      },
    },
    legend: {
      position: "bottom",
      labels: {
        colors: "#ffffff",
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
      followCursor: true,
      intersect: false,
      x: {
        show: true,
      },
      y: {
        formatter: (val) => `+ ${val.toFixed(2)}%`,
      },
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        const value = series[seriesIndex][dataPointIndex];
        const getConvertedValue = (value) => {
          if (selectedCurrency === "USD") {
            return value?.toFixed(2);
          } else if (selectedCurrency === "EUR" && exchangeRate) {
            return (value / exchangeRate).toFixed(2);
          }
          return value;
        };
        const date = formattedDates[dataPointIndex] || "Date not available";
        return `<div style="padding: 1px 12px; background-color: #0d561b; color: #ffffff; border-radius: 3px;">
                    ${
                      selectedCurrency === "USD" ? "$" : "€"
                    } ${getConvertedValue(value)}<br/>
                    <span style="color: #9B9B9B; font-size: 9px; text-align: center; display: block;">${date}</span>
                </div>`;
      },
    },
  };
  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };
  const series = [
    {
      name: "Portfolio",
      data: adjustedBalances,
    },
  ];

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handledepositModalClick = () => {
    setDepositModal(true);
  };
  const startCopyTrading = async () => {
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }

    setLoading(true);
    postRequest(
      "yes",
      "api/bybit/startcopytrades",
      {},
      (response) => {
        if (response && response.data) {
          Notification.showNotification(
            "success",
            "Success",
            "Copy trading started successfully!"
          );
          setUser((prevUser) => ({
            ...prevUser,
            copyTrading: "CopyTrading",
          }));
        } else {
          Notification.showNotification(
            "error",
            "Error",
            "Unexpected response from the server."
          );
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error making copy trading request:", error.message);
        setLoading(false);
      }
    );
  };

  const stopCopyTrading = async () => {
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }
    setLoading(true);
    postRequest(
      "yes",
      "api/bybit/stopcopytrades",
      {},
      (response) => {
        if (response && response.data) {
          Notification.showNotification(
            "success",
            "Success",
            "Copy trading stop successfully!"
          );
          setUser((prevUser) => ({
            ...prevUser,
            copyTrading: "",
          }));
        } else {
          Notification.showNotification(
            "error",
            "Error",
            "Unexpected response from the server."
          );
        }
        setLoading(false);
      },
      (error) => {
        console.error("Error making copy trading request:", error.message);
        setLoading(false);
      }
    );
  };
  const handleCloseDepositModal = () => {
    setDepositModal(false);
  };
  const Trades = [
    {
      coin: "Bitcoin",
      amount: "0.005 BTC",
      chain: "Bitcoin Network",
      exit: "2024-11-26",
      percentage: "+5%",
      profit: "$50",
    },
    {
      coin: "Ethereum",
      amount: "0.2 ETH",
      chain: "Ethereum Mainnet",
      exit: "2024-11-25",
      percentage: "+8%",
      profit: "$160",
    },
    {
      coin: "BNB",
      amount: "1.5 BNB",
      chain: "BNB Smart Chain",
      exit: "2024-11-24",
      percentage: "-2%",
      profit: "-$10",
    },
    {
      coin: "Solana",
      amount: "10 SOL",
      chain: "Solana Network",
      exit: "2024-11-23",
      percentage: "+12%",
      profit: "$240",
    },
    {
      coin: "Cardano",
      amount: "500 ADA",
      chain: "Cardano Network",
      exit: "2024-11-22",
      percentage: "+6%",
      profit: "$90",
    },
  ];

  const data = [
    {
      label: "Total",
      value: `${selectedCurrency === "USD" ? "$" : "€"} ${getConvertedValue()}`,
      showlabel: true,
      totalGainLabel: "Total Gain",
      totalGainValue: `${
        totalGainDiff > 0
          ? `+ ${totalGainDiff.toFixed(2)}`
          : ` ${totalGainDiff.toFixed(2)}`
      } %`,
      showtotalGainLabel: true,
      capitalGainLabel: "Realized PnL",
      capitalGainValue: `${selectedCurrency === "USD" ? "$" : "€"} 0`,
      showcapitalGainLabel: true,
      unrealizedPnLLabel: "Unrealized PnL",
      unrealizedPnLValue: `${selectedCurrency === "USD" ? "$" : "€"} 0`,
      showunrealizedPnLLabel: false,
      totalGainValueColor:
        totalGainDiff >= 0 ? "text-primary" : "text-[#FF0000]",
      capitalGainValueColor: "text-primary",
      unrealizedPnLValueColor: "text-primary",
    },
    {
      label: "Initial Invest",
      value: `${
        selectedCurrency === "USD" ? "$" : "€"
      } ${getTotalInvestConvertedValue()}`,
      showlabel: true,

      totalGainLabel: "Monthly Gain",
      totalGainValue: `${
        monthlyValueDiff > 0
          ? `+ ${monthlyValueDiff.toFixed(2)}`
          : ` ${monthlyValueDiff.toFixed(2)}`
      } %`,
      showtotalGainLabel: false,

      capitalGainValue: `${selectedCurrency === "USD" ? "$" : "€"}0`,
      showcapitalGainLabel: false,

      unrealizedPnLLabel: "Unrealized PnL",
      unrealizedPnLValue: `${selectedCurrency === "USD" ? "$" : "€"} 0`,
      showunrealizedPnLLabel: false,

      totalGainValueColor:
        monthlyValueDiff > 0 ? "text-primary" : "text-[#FF0000]",
      capitalGainValueColor: "text-primary",
      unrealizedPnLValueColor: "text-primary",
    },
    {
      label: "KPI-Name 3",
      value: `${selectedCurrency === "USD" ? "$" : "€"} 0`,
      showlabel: false,

      totalGainLabel: "KPI-Name 5",
      totalGainValue: "0 %",
      showtotalGainLabel: false,

      capitalGainLabel: "KPI-Name 1",
      capitalGainValue: `${selectedCurrency === "USD" ? "$" : "€"} 0`,
      showcapitalGainLabel: false,

      unrealizedPnLLabel: "Invest",
      unrealizedPnLValue: `${selectedCurrency === "USD" ? "$" : "€"} 0`,
      showunrealizedPnLLabel: false,

      valueColor: "text-[value]",
      totalGainValueColor: "text-[#9B9B9B]",
      capitalGainValueColor: "text-primary",
      unrealizedPnLValueColor: "text-primary",
    },
  ];

  // const [index, setIndex] = useState(0);
  // const [animating, setAnimating] = useState(false);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setAnimating(true);
  //     setTimeout(() => {
  //       setIndex((prev) => (prev + 1) % data.length);
  //       setAnimating(false);
  //     }, 800);
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, []);
  const subMemberId = user?.bybituid;

  const slides = ["Slide 1", "Slide 2", "Slide 3"];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 300000000);
    return () => clearInterval(interval);
  }, [slides.length]);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };
  const cityData = {
    USD: ["USD", "EUR"],
  };
  const provinceData = ["USD"];
  const [cities, setCities] = useState(cityData[provinceData[0]]);
  const [secondCity, setSecondCity] = useState(cityData[provinceData[0]][0]);
  const handleProvinceChange = (value) => {
    setCities(cityData[value]);
    setSecondCity(cityData[value][0]);
  };
  const onSecondCityChange = (value) => {
    setSecondCity(value);
  };
  const handleVerify = async () => {
    setLoadingkyc(true);
    const headers = {};
    postRequest(
      "yes",
      "auth/userAccessCode",
      {},
      (data) => {
        if (data?.data?.success) {
          window.location.href = `https://verify.authenticating.com/?token=${data?.data?.kyctoken}`;
        } else {
          console.error("Failed to store data in backend:", data);
          Notification.showNotification(
            "error",
            "Verification Error",
            data.message || "Could not verify user."
          );
        }
        setLoadingkyc(false);
      },
      (error) => {
        console.error("An error occurred during verification:", error);
        Notification.showNotification(
          "error",
          "Verification Error",
          "Could not verify user."
        );
        setLoadingkyc(false);
      },
      headers
    );
  };
  const expirecheck = async () => {
    setExpireLoadingkyc(true);
    try {
      const currentTime = new Date();
      const expirationTime = new Date(user?.expiresOn);
      if (currentTime < expirationTime) {
        window.location.href = `https://verify.authenticating.com/?token=${user.kyctoken}`;
      } else {
        postRequest(
          "yes",
          "auth/recreatetoken",
          {},
          (data) => {
            window.location.href = `https://verify.authenticating.com/?token=${data?.data?.kyctoken}`;
          },
          (error) => {
            console.error("Failed to store data in backend:", error);
            Notification.showNotification(
              "error",
              "Verification Error",
              error.message || "Could not verify user."
            );
          },
          { "Content-Type": "application/json" }
        );
      }
    } catch (error) {
      console.error("An error occurred during token expiration check:", error);
    } finally {
      setExpireLoadingkyc(false);
    }
  };
  const items = [
    {
      key: "1",
      label: (
        <Link to="/usersetting" className="text-accent hover:text-primary">
          Account settings
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link className="text-accent hover:text-primary" onClick={logout}>
          Logout
        </Link>
      ),
    },
  ];
  return (
    <div className="body">
      <div className="navbar-bg-color-dashboard px-2 lg:px-4">
        {/* <Navbar /> */}
        {isLoader ? (
          <div
            className="flex justify-center h-full items-center"
            style={{ height: "50vh" }}
          >
            <BigLoader />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-5 items-center mx-[3%]">
              {/* <img
            src="/icon/logo.svg"
            className="ml-1 mt-2 hidden sm:block col-span-1 w-40 sm:w-72 md:w-72 cursor-pointer"
            alt="Logo"
          /> */}
              <div className=" hidden sm:block w-40 sm:w-72 md:w-72"></div>

              <div className="flex justify-between mt-6 col-span-4 items-center ml-4 w-full">
                <div>
                  <div className="flex flex-col sm:flex-row gap-9 ">
                    <p className="font-sora my-auto  text-md lg:text-[26px] font-extrabold leading-[62.72px] tracking-[-1.68px] text-left text-accent">
                      Welcome {user?.name}
                      <span className="font-sora text-[14px] font-bold leading-[23.94px] ml-2 text-center text-primary px-2 py-1 rounded-2xl bg-[#FFBF0026]">
                        Beta
                      </span>
                    </p>
                    {user?.kycStatus === "success" ? (
                      ""
                    ) : (
                      <div
                        className="my-auto flex gap-2 border cursor-pointer border-primary pt-1 h-9 px-4 rounded"
                        onClick={() => {
                          if (!user?.kyctoken) {
                            handleVerify();
                          } else if (
                            user?.kyctoken &&
                            user?.kycStatus !== "success"
                          ) {
                            expirecheck();
                          } else {
                            return;
                          }
                        }}
                      >
                        {!loadingkyc ? (
                          <>
                            <p className="font-sora mt-0.5 text-[12px] font-bold leading-[23.94px] text-primary text-center">
                              {user?.kycStatus === "success" && "Verified"}
                              {!user?.kyctoken && "Verrify Account"}
                              {user?.kyctoken && (
                                <p className="font-sora text-xs font-bold leading-6 text-center text-primary">
                                  {expireloadingkyc ? (
                                    <img
                                      src="/icon/loaderwhite.svg"
                                      alt="Loading..."
                                      className="w-7 ml-[10%] h-7 animate-spin"
                                    />
                                  ) : (
                                    `${
                                      user?.kycStatus === "success"
                                        ? ""
                                        : "Verrify Account"
                                    }`
                                  )}
                                </p>
                              )}
                            </p>
                            <img
                              src="/icon/vaarrow.png"
                              className="size-4 mt-1.5"
                            />{" "}
                          </>
                        ) : (
                          <img
                            src="/icon/loaderwhite.svg"
                            alt="Loading..."
                            className="w-7 ml-[5%] h-7 animate-spin"
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {user?.kycStatus === "success" ? (
                    <div className="flex gap-2 mb-4 mt-[-5%]">
                      <img
                        src="./icon/vtick.svg"
                        className="w-6 h-3 my-auto "
                      />
                      <p className="font-sora text-xs font-bold leading-[23.94px] text-primary text-center text- my-auto">
                        verified
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div
                className="flex gap-3 bg-black w-[64%] px-4 py-1 rounded cursor-pointer mt-[-12%] mb-6"
                onClick={openModalpro}
              >
                <p className="font-sora text-[12px] font-bold leading-[23.94px] text-center text-primary">
                  Upgrade to Pro
                </p>
                <img src="/icon/upgradepro.svg" className=" h-4 w-3 my-auto" />
              </div> */}
                </div>
                <Space direction="vertical">
                  <Space wrap>
                    <Dropdown
                      overlayClassName="custom-dropdown-menu"
                      menu={{
                        items,
                      }}
                      placement="bottomLeft"
                      trigger={["click"]}
                    >
                      <div className="flex gap-5 items-center bg-[#191919] px-4 py-2 rounded-3xl">
                        <img
                          src="/icon/userprofile.png"
                          className="cursor-pointer h-6 w-6"
                          alt="User Profile"
                        />
                        <img
                          src="/icon/userprofiledownarrow.png"
                          className="cursor-pointer h-2 w-3"
                          alt="Dropdown Arrow"
                        />
                      </div>
                    </Dropdown>
                  </Space>
                </Space>
              </div>
            </div>
            <div className="grid sm:grid-cols-5 ">
              <div className="sm:col-span-1 hidden sm:block border-r border-[#404040]">
                {/* Dashboard */}
                <div
                  onClick={() => navigate("/dashboard")}
                  className={`flex gap-2 px-3 mt-4 cursor-pointer py-2 mx-2 lg:mx-8 rounded-xl ${
                    location.pathname === "/dashboard"
                      ? "border-white border text-white"
                      : "border-[#404040] text-[#9B9B9B]"
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/dashboard"
                        ? "/icon/dashboard2.svg"
                        : "/icon/dashboard.svg"
                    }
                    className="size-4 my-auto lg:size-6 cursor-pointer"
                    alt="dashboardicon"
                  />
                  <p className="font-helvetica text-[12px] lg:text-[16px] font-normal leading-[24px] text-left mt-0.5">
                    Dashboard
                  </p>
                </div>
                <div
                  onClick={() => navigate("/user-transaction")}
                  className={`flex gap-2 px-3 mt-4 cursor-pointer py-2 mx-2 lg:mx-8 rounded-xl ${
                    location.pathname === "/user-transaction"
                      ? "border-white text-white"
                      : "border-[#404040] text-[#9B9B9B]"
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/user-transaction"
                        ? "/icon/treading2.svg"
                        : "/icon/Transaction.svg"
                    }
                    className="size-4 my-auto lg:size-6 cursor-pointer"
                    alt="transactionicon"
                  />
                  <p className="font-helvetica text-[12px] lg:text-[16px] font-normal leading-[24px] text-left mt-0.5">
                    Transaction History
                  </p>
                </div>

                <hr className="mx-2 lg:mx-8 my-4 border-t border-[#404040]" />

                {/* Contact Us */}
                <div
                  // onClick={() => navigate("/contact")}
                  className={`flex gap-2 px-3 mt-4 cursor-pointer py-2 mx-2 lg:mx-8 rounded-xl ${
                    location.pathname === "/contact"
                      ? "border-white text-white"
                      : "border-[#404040] text-[#9B9B9B]"
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/contact"
                        ? "/icon/treading2.svg"
                        : "/icon/contact.png"
                    }
                    className="size-4 my-auto lg:size-6 cursor-pointer"
                    alt="contacticon"
                  />
                  <p className="font-helvetica text-[12px] lg:text-[16px] font-normal leading-[24px] text-left mt-0.5">
                    Contact Us
                  </p>
                </div>
              </div>
              <div className="col-span-4 my-4 grid grid-cols-1 lg:grid-cols-4 px-[1.5%]">
                <div className="col-span-1 lg:col-span-1 lg:w-[140%] 2xl:w-auto border bg-[#40404040] border-gray py-4 px-[4%] pt-6 rounded-3xl">
                  <div className="flex justify-between">
                    <p className="font-sora text-[16px] font-bold leading-[20.16px] tracking-[-1.68px] text-left text-accent">
                      Portfolio Value
                    </p>
                    <div className="relative">
                      <Space wrap>
                        <Select
                          style={{
                            width: "100%",
                            fontSize: 12,
                            fontWeight: "bolder",
                            color: "white",
                            backgroundColor: "transparent",
                          }}
                          dropdownStyle={{
                            backgroundColor: "black",
                          }}
                          value={selectedCurrency}
                          onChange={(value) => handleSelectCurrency(value)}
                          options={[
                            {
                              label: "USD",
                              value: "USD",
                              style: {
                                backgroundColor: "black",
                                color: "white",
                                cursor: "pointer",
                              },
                            },
                            {
                              label: "EUR",
                              value: "EUR",
                              style: {
                                backgroundColor: "black",
                                color: "white",
                                cursor: "pointer",
                              },
                            },
                          ]}
                          className="custom-selectmodal"
                        />
                      </Space>
                    </div>
                  </div>
                  {/* <p className="font-helvetica text-[12px] mt-1 font-normal leading-[24px] text-left text-[#9B9B9B]">
                Acc Nr.: 4568 0934 4949 4949
                Subuid : {user?.bybituid}
              </p> */}
                  <div className="flex mt-6 gap-[6%] ">
                    <p className="font-sora text-[24px] lg:text-[17px] xl:text-[24px] font-extrabold leading-[30.24px] text-center">
                      {selectedCurrency === "USD" ? "$" : "€"}
                      {/* {total?.toFixed(2)} */}
                      {getConvertedValue() ? getConvertedValue() : 0}
                    </p>
                    <div
                      className={`  gap-2 my-auto px-1 h-6 rounded-2xl flex ${
                        difference >= 0 ? "bg-[#103E00]" : "bg-[#441414]"
                      } `}
                    >
                      {/* <img src="/icon/greenplus.png" className="h-3 my-auto" /> */}
                      <p
                        className={`font-sora text-[12px] lg:text-[9px] xl:text-[11px] font-bold leading-[23.94px] my-auto text-center ${
                          difference >= 0 ? "text-[#3BD306]" : "text-[#FF0000]" // Green for +, Red for -
                        }`}
                      >
                        {difference > 0
                          ? `+ ${difference.toFixed(2)}`
                          : `${difference.toFixed(2)}`}{" "}
                        %
                      </p>
                    </div>
                  </div>
                  <hr className="my-4 border-t border-gray" />
                  <div className="gap-4 pt-2 mb-6 flex ">
                    <div
                      className="flex gap-2 rounded w-full bg-primary cursor-pointer"
                      onClick={handledepositModalClick}
                    >
                      <div className="mx-auto flex gap-2">
                        <img src="/icon/d2.svg" className="h-5 w-4 my-auto" />
                        <p className="font-sora text-[12px] my-auto font-bold text-black leading-[23.94px] text-center">
                          Deposit
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex gap-2 w-full rounded border border-primary cursor-pointer"
                      onClick={handleWithdrawModalClick}
                    >
                      <div className="mx-auto flex gap-2">
                        <img src="/icon/w2.svg" className="h-4 w-3 my-auto" />
                        <p className="font-sora text-[12px] font-bold my-auto text-primary leading-[23.94px] text-center">
                          Withdraw
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr className="my-4 border-t border-gray" />
                  <div className="relative w-full h-40 overflow-hidden bg-[#00000026] pt-4  rounded-2xl">
                    <div className="absolute z-30 flex space-x-3 bottom-4 left-1/2 transform -translate-x-1/2">
                      {data.map((_, index) => (
                        <button
                          key={index}
                          type="button"
                          className={`w-3 h-3 rounded-full ${
                            index === currentSlide
                              ? "bg-white"
                              : "bg-gray-400 opacity-50"
                          }`}
                          aria-label={`Slide ${index + 1}`}
                          onClick={() => handleSlideChange(index)}
                        />
                      ))}
                    </div>
                    <div
                      className="w-full h-full flex transition-transform  duration-500 ease-in-out"
                      style={{
                        transform: `translateX(-${currentSlide * 100}%)`,
                      }}
                    >
                      {data.map((item, index) => (
                        <div key={index} className="w-full flex-shrink-0">
                          <div className="flex justify-between px-2 gap-10">
                            {/* Label Section */}
                            <div
                              className={`flex flex-col items-center min-w-24 ${
                                item.showlabel ? "" : "filter blur-sm"
                              }`}
                            >
                              <div className="flex gap-1">
                                <p className="font-helvetica text-[#9B9B9B] text-[9px] sm:text-[12px] font-normal">
                                  {item.label}
                                </p>
                                <img
                                  src="/icon/i.png"
                                  className="size-3 my-auto"
                                />
                              </div>
                              <p className="text-[#9B9B9B] font-sora text-[12px] sm:text-[14px] lg:text-[16px] font-bold">
                                {item.value}
                              </p>
                            </div>

                            {/* Total Gain Section */}
                            <div
                              className={`flex flex-col items-center min-w-24 ${
                                item.showtotalGainLabel ? "" : "filter blur-sm"
                              }`}
                            >
                              <div className="flex gap-1">
                                <p className="font-helvetica text-[#9B9B9B] text-[9px] sm:text-[12px] font-normal">
                                  {item.totalGainLabel}
                                </p>
                                <img
                                  src="/icon/i.png"
                                  className="size-3 my-auto"
                                />
                              </div>
                              <p
                                className={`${item.totalGainValueColor} font-sora text-[12px] sm:text-[14px] lg:text-[16px] font-bold`}
                              >
                                {item.totalGainValue}
                              </p>
                            </div>
                          </div>

                          <div className="flex justify-between px-2 gap-10 mt-4">
                            {/* Capital Gain Section */}
                            <div
                              className={`flex flex-col items-center min-w-24 ${
                                item.showcapitalGainLabel
                                  ? ""
                                  : "filter blur-sm"
                              }`}
                            >
                              <div className="flex gap-1">
                                <p className="font-helvetica text-[#9B9B9B] text-[9px] sm:text-[12px] font-normal">
                                  {item.capitalGainLabel}
                                </p>
                                <img
                                  src="/icon/i.png"
                                  className="size-3 my-auto"
                                />
                              </div>
                              <p
                                className={`${item.capitalGainValueColor} font-sora text-[12px] sm:text-[14px] lg:text-[16px] font-bold`}
                              >
                                {item.capitalGainValue}
                              </p>
                            </div>

                            {/* Unrealized PnL Section */}
                            <div
                              className={`flex flex-col items-center min-w-24 ${
                                item.showunrealizedPnLLabel
                                  ? ""
                                  : "filter blur-sm"
                              }`}
                            >
                              <div className="flex gap-1">
                                <p className="font-helvetica text-[#9B9B9B] text-[9px] sm:text-[12px] font-normal">
                                  {item.unrealizedPnLLabel}
                                </p>
                                <img
                                  src="/icon/i.png"
                                  className="size-3 my-auto"
                                />
                              </div>
                              <p
                                className={`${item.unrealizedPnLValueColor} font-sora text-[12px] sm:text-[14px] lg:text-[16px] font-bold`}
                              >
                                {item.unrealizedPnLValue}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="my-4 border-t border-gray" />
                  <div
                    className={`mx-auto flex gap-2 ${
                      user?.copyTrading === "CopyTrading"
                        ? "bg-[#d31706]"
                        : "bg-primary"
                    } rounded-lg cursor-pointer`}
                    onClick={
                      user?.copyTrading === "CopyTrading"
                        ? stopCopyTrading
                        : startCopyTrading
                    }
                  >
                    <p className="font-sora text-[14px] my-auto py-2 font-bold text-black leading-[23.94px] mx-auto">
                      {loading ? (
                        <img
                          src="/icon/loader.svg"
                          alt="Loading..."
                          className="w-7 mx-auto h-7 animate-spin"
                        />
                      ) : user?.copyTrading === "CopyTrading" ? (
                        <>
                          <BlockIcon /> Stop Copy Trades
                        </>
                      ) : (
                        <>
                          <PlayCircleOutlineIcon /> Start Copy Trades
                        </>
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-span-1 lg:col-span-3 border bg-[#40404040] w-full mt-10 sm:mt-4 lg:mt-0 lg:w-[85%] 2xl:w-[96%] ml-auto border-gray rounded-3xl">
                  <div className="chart-container">
                    <div className="flex flex-col sm:flex-row gap-2 justify-between">
                      <div className="flex gap-2">
                        <h3 className="text-white font-sora text-[16px] font-bold leading-[20.16px] text-left">
                          Performance
                        </h3>
                        <div className="bg-[#FFFFFF0D] size-5 rounded-full flex justify-center items-center">
                          <img
                            src="/icon/dPerformance.svg"
                            className="w-2 h-3"
                          />
                        </div>
                      </div>
                      <div className="flex gap-4 rounded-2xl bg-[#FFFFFF0D] px-4 py-1">
                        {timePeriods.map((period) => (
                          <p
                            key={period}
                            className={`font-sora text-[12px] font-bold cursor-pointer text-[#FFFFFF80] focus:text-white text-center ${
                              selected === period ? "text-white" : ""
                            }`}
                            onClick={() => {
                              setSelected(period);
                              getPortfolioValue(period);
                            }}
                          >
                            {period}
                          </p>
                        ))}
                      </div>
                    </div>
                    {/* <div className="flex gap-4 mt-5">
                  <p className="font-sans text-xs font-normal leading-6 text-left text-[#9B9B9B]">
                    Compare to
                  </p>
                  <div className="dropdown-container">
                    <select className="dropdown">
                      <option>MSCI World</option>
                      <option>MSCI Emerging Markets</option>
                    </select>
                  </div>
                </div> */}
                    <div className=" flex mt-8 mb-2 cursor-pointer">
                      <div className="font-sora text-[8px] sm:text-[12px] font-normal h-6 px-2 rounded-2xl leading-[23.94px] text-center text-primary bg-[#FFBF0040]">
                        Portfolio
                      </div>
                      {/* <div className=" mx-2 h-6 px-2 rounded-2xl text-center flex gap-2 bg-[#91919140]">
                        <p className="font-sora font-normal  leading-[23.94px] text-[#919191] text-[8px] sm:text-[12px]">
                          MSCI World
                        </p>
                        <img
                          src="/icon/chartcross.svg"
                          className="size-2 sm:size-2.5 my-auto"
                        />
                      </div>
                      <div className=" mx-2 h-6 px-2 rounded-2xl text-center flex gap-2 bg-[#455C9040]">
                        <p className="font-sora font-normal  leading-[23.94px] text-[#455C90] text-[8px] sm:text-[12px]">
                          Gold
                        </p>
                        <img
                          src="/icon/chartcross2.svg"
                          className="size-2 sm:size-2.5 my-auto"
                        />
                      </div> */}

                      {/* <Popover
                    placement="bottomRight"
                    content={content}
                    arrow={true}
                    overlayClassName="custom-popover"
                    trigger="click"
                    visible={visible}
                    onClick={handleClick}
                  >
                    <img
                      src="/icon/graphsetting.svg"
                      alt="Settings"
                      style={{ cursor: "pointer" }}
                      onClick={handleClick}
                    />
                  </Popover> */}
                    </div>
                    {/* <div
                  id="tradingview-chart"
                  ref={widgetRef}
                  style={{ height: "300px", width: "100%" }}
                ></div> */}

                    {/* {["1M", "3M", "6M", "1Y", "MAX"].map((time, index) => ( */}
                    <div className="timeframe-buttons bg-gray mr-2 mt-4 rounded-2xl">
                      {[].map((time, index) => (
                        <button
                          key={index}
                          className={`time-button ${
                            selectedTimeFrame === time ? "active" : ""
                          }`}
                          onClick={() => handleTimeFrameChange(time)}
                        >
                          {time}
                        </button>
                      ))}
                    </div>
                    <div id="chart">
                      <Chart
                        options={options}
                        series={series}
                        type="line"
                        height={350}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid sm:grid-cols-5 pb-14">
              <div className=" sm:col-span-1 hidden sm:block  border-r border-gray"></div>
              <div className="mt-8 px-2 sm:px-4 col-span-4">
                <div className="p-2 sm:p-8 border bg-[#40404040] border-gray rounded-3xl w-full">
                  <div className="relative">
                    <div className="blur-md relative">
                      <p className="font-sora text-[16px] font-bold leading-[20.16px] tracking-tight text-left text-[#FFFFFFBF]">
                        Recent Trades
                      </p>
                      <table className="w-full border-collapse mt-4">
                        <thead>
                          <tr className="border-b border-gray">
                            <th className=" px-0.5 sm:px-3 py-2 font-sans text-[10px] sm:text-[10px] md:text-[14px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                              Coin
                            </th>
                            <th className=" px-0.5 sm:px-3 py-2 font-sans text-[10px] sm:text-[10px] md:text-[14px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                              Amount
                            </th>
                            <th className=" px-0.5 sm:px-3 py-2 font-sans text-[10px] sm:text-[10px] md:text-[14px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                              Chain
                            </th>
                            <th className=" px-0.5 sm:px-3 py-2 font-sans text-[10px] sm:text-[10px] md:text-[14px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                              Exit
                            </th>
                            <th className=" px-0.5 sm:px-3 py-2 font-sans text-[10px] sm:text-[10px] md:text-[14px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                              Percentage
                            </th>
                            <th className=" px-0.5 sm:px-3 py-2 font-sans text-[10px] sm:text-[10px] md:text-[14px] font-normal leading-[24px] text-center text-[#9B9B9B]">
                              Profit
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Trades.map((trade, index) => (
                            <tr key={index}>
                              <td className=" lg:px-3 py-3 ">
                                <div className="flex gap-2">
                                  <img
                                    src="/icon/dashboardgallery.png"
                                    className="w-3 my-auto h-2 sm:w-6 sm:h-4"
                                  />
                                  <p className="text-[6px] sm:text-[8px] md:text-[10px] lg:text-[16px] font-normal leading-[24px] text-left font-helvetica text-accent">
                                    {trade.coin}
                                  </p>
                                </div>
                              </td>
                              <td className="lg:px-3 py-3 text-[7px] sm:text-[8px] md:text-[10px] lg:text-[16px] font-normal leading-[24px] text-left font-helvetica text-accent">
                                {trade.amount}
                              </td>
                              <td className="lg:px-3 py-3 text-[7px] sm:text-[8px] md:text-[10px] lg:text-[16px] font-normal leading-[24px] text-left font-helvetica text-accent">
                                {trade.chain}
                              </td>
                              <td className="lg:px-3 py-3 text-[7px] sm:text-[8px] md:text-[10px] lg:text-[16px] font-normal leading-[24px] text-left font-helvetica text-accent">
                                {trade.exit}
                              </td>
                              <td className="lg:px-3 py-3 text-[7px] sm:text-[8px] md:text-[10px] lg:text-[16px] font-normal leading-[24px] text-left font-helvetica text-accent">
                                {trade.percentage}
                              </td>
                              <td className="lg:px-3 py-1 sm:py-3 ">
                                <div className="bg-[#103E00] rounded-2xl w-[100%] xl:w-[70%]">
                                  <p className="px-1 font-sora text-[7px] sm:text-[6px] md:text-[9px] lg:text-[10px] font-bold leading-[23.94px] text-center text-[#3BD306]">
                                    {trade.profit}
                                  </p>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Link to="/activity">
                        <div className="flex gap-3 mx-auto border border-[#FFFFFF80] opacity-80 px-3 py-1 mt-8 cursor-pointer rounded w-fit">
                          <p className="font-sora font-bold text-[12px] leading-[23.94px] text-left">
                            View all
                          </p>
                          <img
                            src="/icon/viewall.png"
                            className="h-2 w-4 my-auto"
                          />
                        </div>
                      </Link>
                    </div>
                    {/* Coming Soon Overlay */}
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                      <p className="font-sora text-[20px] sm:text-[24px] font-bold text-center text-white">
                        Coming Soon
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {isModalOpen && <DepositModal func={toggleModal} />}
      <DepositModal
        show={depositModal}
        onClosePrevious={handleCloseDepositModal}
      />
      {isWithdrawModalOpen && <WithdrawModal func={togglewithdrawModal} />}
      <WithdrawModal
        total={total}
        show={withdrawModal}
        onClose={handleCloseWithdrawModal}
        transferBalance={transferBalance}
        setTransferBalance={setTransferBalance}
      />
      <Modal isOpenpro={isModalOpenPro} onClosepro={closeModalpro} />
    </div>
  );
}
const Modal = ({ isOpenpro, onClosepro }) => {
  if (!isOpenpro) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-[#1F1C30] opacity-90 rounded-lg p-6 shadow-lg">
        <div
          className="font-semibold text-xl text-end cursor-pointer"
          onClick={onClosepro}
        >
          X
        </div>
        <h2 className="text-3xl px-32 py-20 font-semibold">Comming Soon</h2>
      </div>
    </div>
  );
};
export default Dashboard;
