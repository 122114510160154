import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Notification from "./Notification";
import ErrorIcon from "@mui/icons-material/Error"; // Import Material-UI error icon
import { URL } from "../constants/constant";
import Loader from "./Loader";
import axios from "axios";
import { postRequest } from "../backendServices/ApiCalls";

function ResetPasswordCard() {
  const { resetCode } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [success, setSuccess] = useState("");
  const [loadingCode, setLoadingCode] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  // const verifyResetCode = async () => {
  //   try {
  //     setLoadingCode(true);
  //     const response = await axios.post(
  //       `${URL}/auth/verify-reset-code`,
  //       {
  //         resetCode,
  //       }
  //     );
  //     if (response.data.valid) {
  //       setMessage(response.data.message);
  //     }
  //   } catch (err) {
  //     if (err.response) {
  //       setError(err.response.data.message);
  //     } else {
  //       setError(
  //         "You are using an invalid reset password link. Please request a new one"
  //       );
  //     }
  //   } finally {
  //     setLoadingCode(false);
  //   }
  // };
  const verifyResetCode = async () => {
    setLoadingCode(true); // Start loading state
  
    // Prepare parameters for the postRequest
    const params = {
      resetCode,
    };
  
    // Use postRequest utility function
    postRequest("no",
      "auth/verify-reset-code", // API endpoint
      params, // Payload
      (response) => {
        // Handle success response
        if (response.data.valid) {
          setMessage(response.data.message);
        }
      },
      (error) => {
        // Handle error from the postRequest call
        if (error.response) {
          setError(error.message);
        } else {
          setError(
            "You are using an invalid reset password link. Please request a new one."
          );
        }
      }
    );
  
    // Stop loading state in the finally block of postRequest
    setLoadingCode(false);
  };
  
  useEffect(() => {
    verifyResetCode();
    
  }, [resetCode]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError("");
  };

  // const handleSubmit = async () => {
  //   if (loading) return;
  //   setLoading(true);
  //   setError("");
  //   setSuccess("");
  //   setPasswordError("");
  //   setConfirmPasswordError("");
  //   const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

  //   if (password !== confirmPassword) {
  //     setConfirmPasswordError("Passwords do not match");
  //     setLoading(false);
  //     return;
  //   }
  //   if (password.length < 8) {
  //     setPasswordError("Password must be at least 8 characters long");
  //     setLoading(false);
  //     return;
  //   }
  //   if (specialCharRegex.test(password)) {
  //     setPasswordError("Password must not contain a special character");
  //     setLoading(false);
  //     return;
  //   }

  //   try {
  //     const response = await fetch(`${URL}/auth/reset-password`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ resetCode, password, confirmPassword }),
  //     });

  //     if (response.status !== 200) {
  //       const errorData = await response.json();
  //       throw new Error(errorData.message || "Failed to reset password");
  //     }

  //     Notification.showNotification(
  //       "success",
  //       "Success",
  //       "Password reset successfully",
  //       "Your password has been reset."
  //     );
  //     setSuccess("Password reset successfully!");
  //     navigate("/login");
  //     // setTimeout(() => {
  //     // }, 2000);
  //   } catch (error) {
  //     setError(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    setError("");
    setSuccess("");
    setPasswordError("");
    setConfirmPasswordError("");
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
  
    // Validate passwords
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      setLoading(false);
      return;
    }
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      setLoading(false);
      return;
    }
    if (specialCharRegex.test(password)) {
      setPasswordError("Password must not contain a special character");
      setLoading(false);
      return;
    }
  
    // Prepare parameters for the postRequest
    const params = {
      resetCode,
      password,
      confirmPassword,
    };
  
    // Use postRequest utility function
    postRequest("no",
      "auth/reset-password", // API endpoint
      params, // Payload
      (response) => {
        // Handle success response
        Notification.showNotification(
          "success",
          "Success",
          "Password reset successfully",
          "Your password has been reset."
        );
        setSuccess("Password reset successfully!");
        navigate("/login");
        setLoading(false);
      },
      (error) => {
        // Handle error from the postRequest call
        setError(error.message);
        setLoading(false);
      }
    );
  
    // Stop loading state will be handled in the finally block of postRequest
  };
  

  if (loadingCode) {
    return (
      <div className="flex flex-col justify-center items-center mt-20">
        <div className="mx-auto">
          <Loader />
        </div>
        <p className="text-center text-lg mt-12 text-gray-500">
          Verifying reset password link...
        </p>
      </div>
    );
  }
  return (
    <div>
      {error && (
        <div className="flex items-center bg-red-200 border border-red-500 w-[50%] mx-auto rounded-lg p-4 mt-16 shadow-md">
          <div className="flex mx-auto">
            <ErrorIcon className="text-red-500 mr-2" /> {/* Material-UI icon */}
            <p className="text-red-600">{error}</p>
          </div>
        </div>
      )}

      {message && (
        <div className="mx-6">
          <h1 className="text-accent tracking-tighter mt-16 mb-0 text-center text-6xl font-bold leading-normal">
            Change Password
          </h1>
          <div className="max-w-[488px] mt-6 pt-6 h-auto pb-6 bg-[#222222] mx-auto rounded-3xl">
            <div className="w-16 h-16 rounded-2xl items-center mx-auto justify-center flex bg-primary bg-opacity-20">
              <img
                src="/icon/yellowProfile.png"
                className="w-6 h-6"
                alt="Profile"
              />
            </div>
            {success && <Notification message={success} type="success" />}
            <div className="relative mx-4 md:mx-8 mt-4">
              <p className="text-accent tracking-tighter mb-0 text-lg font-semibold leading-relaxed block">
                New password
              </p>
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handleChangePassword}
                onKeyPress={handleKeyPress}
                className="border mt-1 px-2 h-12 rounded-xl border-[#383838] w-full bg-transparent text-accent"
              />
              <span
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-accent"
              >
                {showPassword ? (
                  <img src="/icon/openeye.svg" className="size-5 mt-7" />
                ) : (
                  <img src="/icon/closeeye.svg" className="size-5 mt-7" />
                )}
              </span>
              {passwordError && (
                <p className="text-red-500 mt-1 text-sm">{passwordError}</p> // Red text for password error
              )}
            </div>
            <div className="relative mx-4 md:mx-8 mt-4">
              <p className="text-accent tracking-tighter mb-0 text-lg font-semibold leading-relaxed block">
                Confirm password
              </p>
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleChangeConfirmPassword}
                onKeyPress={handleKeyPress}
                className="border mt-1 px-2 h-12 rounded-xl border-[#383838] w-full bg-transparent text-accent"
              />
              <span
                onClick={toggleConfirmPasswordVisibility}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-accent"
              >
                {showConfirmPassword ? (
                  <img src="/icon/openeye.svg" className="size-5 mt-7" />
                ) : (
                  <img src="/icon/closeeye.svg" className="size-5 mt-7" />
                )}
              </span>
              {confirmPasswordError && (
                <p className="text-red-500 mt-1 text-sm">
                  {confirmPasswordError}
                </p> // Red text for confirm password error
              )}
            </div>
            <button
              onClick={handleSubmit} // Handle submit on button click
              disabled={loading}
              className={`bg-primary w-[40%] sm:w-[87%] mt-10 text-black font-medium rounded-xl text-md py-2 mx-auto sm:py-4 sm:mx-8 flex items-center justify-center ${
                loading ? "cursor-not-allowed opacity-50" : ""
              }`}
            >
              {loading ? (
                <img
                  src="/icon/loader.svg"
                  alt="Loading..."
                  className="w-7 mx-auto h-7 animate-spin"
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResetPasswordCard;
