import React from "react";
import Navbar from "../../components/Navbar";
import Faqs from "../../components/Faqs";
import Footer from "../../components/Footer";
import NotificationSidebar from "../../components/NotificationSidebar";

function Notification() {
  return (
    <div className="body ">
      <div className=" navbar-bg-color px-4">
        <Navbar />
        <div className="grid grid-cols-1 sm:grid-cols-5">
          <div className="col-span-1 hidden sm:block ">
            <NotificationSidebar />
          </div>
          <div className="col-span-4 mx-6 md:mx-16 2xl:mx-20 mt-20">
            <div className="md:px-4 lg:px-6 xl:px-12 bg-[#222222] rounded-3xl mb-5 px-2 2xl:px-20 py-8 ">
              <div className="flex items-center gap-4  justify-between">
                <div>
                  <div className="flex gap-2">
                    <img src="/icon/message.svg" alt="Message Icon" />
                    <p className="font-inter min-w-36 my-auto text-md font-medium leading-[22px] mb-1">
                      Login Attempt From New ID
                    </p>
                  </div>
                  <p className="font-inter mt-4 text-xs font-medium leading-[22px]">
                    Dear Trader,
                  </p>
                  <p className="font-inter mt-2 text-xs font-medium leading-[22px]">
                    The system has detected that your account is logged in from
                    an unfamiliar IP address:
                  </p>
                  <p className="font-inter text-xs font-medium leading-[22px]">
                    .....
                  </p>
                  <div className="flex gap-2 ">
                    <p className="font-inter text-xs font-medium leading-[22px]">
                      07-31
                    </p>
                    <p className="font-inter text-xs font-medium leading-[22px]">
                      13:39
                    </p>
                  </div>
                </div>
                <button className="bg-primary text-accent min-w-28 2xl:min-w-32 py-1 px-3 rounded">
                  <p className="font-sora text-black text-[12px] 2xl:text-[15px] font-normal leading-[23.94px] text-center">
                    View More
                  </p>
                </button>
              </div>
            </div>
            <div className="md:px-4 lg:px-6 xl:px-12 mb-5 bg-[#222222] rounded-3xl px-2 2xl:px-20 py-8 ">
              <div className="flex items-center gap-4  justify-between">
                <div>
                  <div className="flex gap-2">
                    <img src="/icon/message.svg" alt="Message Icon" />
                    <p className="font-inter min-w-36 my-auto text-md font-medium leading-[22px] mb-1">
                      Login Attempt From New ID
                    </p>
                  </div>
                  <p className="font-inter mt-4 text-xs font-medium leading-[22px]">
                    Dear Trader,
                  </p>
                  <p className="font-inter mt-2 text-xs font-medium leading-[22px]">
                    The system has detected that your account is logged in from
                    an unfamiliar IP address:
                  </p>
                  <p className="font-inter text-xs font-medium leading-[22px]">
                    .....
                  </p>
                  <div className="flex gap-2 ">
                    <p className="font-inter text-xs font-medium leading-[22px]">
                      07-31
                    </p>
                    <p className="font-inter text-xs font-medium leading-[22px]">
                      13:39
                    </p>
                  </div>
                </div>
                <button className="bg-primary text-accent min-w-28 py-1 2xl:min-w-32 px-3 rounded">
                  <p className="font-sora text-black text-[12px] font-normal 2xl:text-[15px] leading-[23.94px] text-center">
                    View More
                  </p>
                </button>
              </div>
            </div>
            <div className="md:px-4 lg:px-6 xl:px-12 bg-[#222222] rounded-3xl px-2 2xl:px-20 py-8 ">
              <div className="flex items-center gap-4  justify-between">
                <div>
                  <div className="flex gap-2">
                    <img src="/icon/message.svg" alt="Message Icon" />
                    <p className="font-inter min-w-36 my-auto text-md font-medium leading-[22px] mb-1">
                      Login Attempt From New ID
                    </p>
                  </div>
                  <p className="font-inter mt-4 text-xs font-medium leading-[22px]">
                    Dear Trader,
                  </p>
                  <p className="font-inter mt-2 text-xs font-medium leading-[22px]">
                    The system has detected that your account is logged in from
                    an unfamiliar IP address:
                  </p>
                  <p className="font-inter text-xs font-medium leading-[22px]">
                    .....
                  </p>
                  <div className="flex gap-2 ">
                    <p className="font-inter text-xs font-medium leading-[22px]">
                      07-31
                    </p>
                    <p className="font-inter text-xs font-medium leading-[22px]">
                      13:39
                    </p>
                  </div>
                </div>
                <button className="bg-primary text-accent min-w-28 2xl:min-w-32 py-1 px-3 rounded">
                  <p className="font-sora text-black text-[12px] font-normal 2xl:text-[15px] leading-[23.94px] text-center">
                    View More
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <p className="font-sora text-[34px] sm:text-[44px] mt-3 font-semibold leading-[49.28px] tracking-[-0.88px] text-center">
          Frequently Asked <br />
          Question
        </p>
        <div className="mt-4 flex flex-col gap-6">
          <Faqs question="How do I get started?" />
          <Faqs question="How much money do I need to start?" />
          <Faqs question="Is my money safe using Algo X Capital?" />
          <Faqs question="I have a problem, how can I contact the Team?" />
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default Notification;
