import React from "react";
import Getstartedcard from "./Getstartedcard";

function Getstarted() {
  return (
    <div>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="font-sora text-[16px] text-primary mt-44 font-normal leading-[22.4px] tracking-[4.8px] text-center"
      >
        HOW IT WORKS
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="font-sora  mt-3 px-3 font-semibold leading-[49.28px] tracking-[-0.88px] text-center text-[34px] sm:text-[50px]"
      >
        3 EASY STEPS TO GET YOU STARTED
      </p>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className=" flex lg:flex-row flex-col mt-12  justify-center gap-8 items-center  "
      >
        <Getstartedcard
          heading="1. Create An Account"
          paragraph="Sign up and start your crypto journey
instantly with our FREE PLAN or upgrade
to a paid monthly subscription for
additional features and lower
commissions. Select the plan that aligns
with your goals and assets"
        />
        <Getstartedcard
          heading="2. Fund Your Account"
          paragraph="Start investing with as little as $1, or
jumpstart your earnings with our
recommended deposit of $200.
Remember, the more you invest, the
greater your potential returns."
        />
        <Getstartedcard
          heading="3. Lean Back & Profit"
          paragraph="Now relax! Our advanced trading
algorithms go to start working immediately,
monitoring markets 24/7. Just sit back and
watch your passive income stream grow!"
        />
      </div>
    </div>
  );
}

export default Getstarted;
