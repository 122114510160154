import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import NotificationSidebar from "../../components/NotificationSidebar";
import Footer from "../../components/Footer";
import AccountFaqs from "../../components/AccountFaqs";

function SecurityVerification() {
  const [code, setCode] = useState(new Array(6).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    setCode([...code.map((d, idx) => (idx === index ? element.value : d))]);

    // Move to next input field
    if (element.value !== "" && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (code[index] === "" && e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  return (
    <div className="body">
      <div className="navbar-bg-color px-4">
      <Navbar />
      <div className="grid grid-cols-1 sm:grid-cols-5">
        <div className="hidden sm:block mt-[8%] mb-[-60%] col-span-1 ml-auto">
          <NotificationSidebar />
        </div>
        <div className=" w-[98%] sm:w-[85%] 2xl:w-[77%] col-span-1 sm:col-span-4 lg:col-span-3 mt-28 mx-auto rounded-xl bg-[#222222]">
          <div className="w-[80%] xl:w-[70%] mx-auto">
            <p className="font-inter text-2xl sm:text-4xl pt-9 mb-14 font-bold leading-[43.57px] text-center">
              Email Verification Code
            </p>
            <div className="flex justify-center mb-6 items-center">
              <img src="/icon/email.svg" />
              <p className="font-poppins text-base font-normal ml-2 leading-6 text-left">
                A verification code will be send to off
                <span className="font-poppins text-base font-bold leading-6 text-left">
                  {" "}
                  ****@algox.capital
                </span>
              </p>
            </div>
            <div className="flex space-x-2 sm:space-x-4 md:space-x-7 justify-center">
              {code.map((data, index) => {
                return (
                  <input
                    className="w-10 h-10 sm:w-12 sm:h-12 border-2 border-primary text-primary bg-[#222222] rounded-md text-center text-xl"
                    type="text"
                    name="code"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                  />
                );
              })}
            </div>
            <p className="font-poppins text-sm mt-2 font-normal leading-6 text-end">
              Didn’t able to receive verification code? 53s
            </p>
            <p className="font-poppins text-sm font-semibold mt-11 pb-14 leading-6 text-start text-primary">
              Having Problem With Verification?
            </p>
          </div>
        </div>
        <div className="lg:col-span-1"></div>
      </div>
      {/* <p
      
        className="font-sora text-[34px] sm:text-[44px] mt-3 font-semibold leading-[49.28px] tracking-[-0.88px] text-center"
      >
        Frequently Asked <br />
        Question
      </p>
        <div className="mt-4  2xl:pb-64">
          <AccountFaqs question="How do I get started?" />
          <AccountFaqs question="How much money do I need to start?" />
          <AccountFaqs question="Is my money safe using Algo X Capital?" />
          <AccountFaqs question="I have a problem, how can I contact the Team?" />
        </div> */}
      </div>
      <Footer />
    </div>
  );
}

export default SecurityVerification;
