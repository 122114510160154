import React, { useEffect } from "react";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import ElevateBusiness from "../../components/ElevateBusiness";
import AccountFaqs from "../../components/AccountFaqs";
function PrivacyPolicy() {
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="account">
      <div className="bg-account">
        <Navbar />

        <div className=" mx-4 sm:mx-12 md:mx-20 mt-20 2xl:w-4/5 2xl:mx-auto">
          <h1 className="font-sora text-5xl font-bold leading-[62.72px] tracking-[-0.03em] text-center">
            Privacy Policy
          </h1>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-12">
            Last updated: May 16, 2024
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Cookies set by the website you are visiting are usually referred to
            as first-party cookies. They typically only track your activity on
            that particular site. Cookies set by other sites and companies (i.e.
            third parties) are called third-party cookies They can be used to
            track you on other websites that use the same third-party service.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Interpretation
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Use of Your Personal Data
          </h2>
          <div className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul className="list-disc pl-5">
              <li>
                To provide and maintain our Service, including to monitor the
                usage of our Service.
              </li>
              <li>
                To manage Your Account: to manage Your registration as a user of
                the Service. The Personal Data You provide can give You access
                to different functionalities of the Service that are available
                to You as a registered user.
              </li>
              <li>
                For the performance of a contract: the development, compliance,
                and undertaking of the purchase contract for the products,
                items, or services You have purchased or of any other contract
                with Us through the Service.
              </li>
              <li>
                To contact You: To contact You by email, telephone calls, SMS,
                or other equivalent forms of electronic communication, such as a
                mobile application's push notifications regarding updates or
                informative communications related to the functionalities,
                products, or contracted services, including security updates,
                when necessary or reasonable for their implementation.
              </li>
              <li>
                To provide You with news, special offers, and general
                information about other goods, services, and events which we
                offer that are similar to those that you have already purchased
                or enquired about unless You have opted not to receive such
                information.
              </li>
              <li>
                To manage Your requests: To attend and manage Your requests to
                Us.
              </li>
              <li>
                For business transfers: We may use Your information to evaluate
                or conduct a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of Our
                assets, whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which Personal Data held
                by Us about our Service users is among the assets transferred.
              </li>
              <li>
                For other purposes: We may use Your information for other
                purposes, such as data analysis, identifying usage trends,
                determining the effectiveness of our promotional campaigns, and
                to evaluate and improve our Service, products, services,
                marketing, and your experience.
              </li>
            </ul>
          </div>

          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Disclosure of Your Personal Data
          </h2>
          <div className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            <p>
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </p>
            <ul className="list-disc pl-5">
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of the Company</li>
              <li>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li>
                Protect the personal safety of Users of the Service or the
                public
              </li>
              <li>Protect against legal liability</li>
            </ul>
          </div>

          <h2 className="font-sora text-xl font-semibold leading-[26.88px] tracking-[-0.02em] text-left mt-6">
            Changes to this Privacy Policy
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
            <br />
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
            <br /> You are advised to review this Privacy Policy periodically
            for any changes. Changes to this Privacy Policy are effective when
            they are posted on this page.
          </p>
        </div>
      </div>
      {/* <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[16px] md:hidden text-[#B0A4FF] mt-64 sm:mt-[45%] 2xl:mt-96 font-normal leading-[22.4px] tracking-[4.8px] text-center"
      >
        HAVE QUESTION?
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[44px] mt-3 md:hidden font-semibold leading-[49.28px] tracking-[-0.88px] text-center"
      >
        Frequently Asked <br />
        Question
      </p>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="lg:hidden"
      >
       <AccountFaqs question="What payment methods do you accept?" />
        <AccountFaqs question="Are there any hidden fees?" />
        <AccountFaqs question="Is there a free trial available?" />
        <AccountFaqs question="How do I get started?" />
        <AccountFaqs question="How do I access my account?" />
      </div> */}

      {/* <ElevateBusiness /> */}

      <Footer />
    </div>
  );
}
export default PrivacyPolicy;
