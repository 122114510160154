import React, { useContext, useEffect, useState } from "react";
import { Switch } from "antd";
import CryptoDepositModal from "./CryptoDepositModal";
import BankDepositModal from "./BankDepositModal";
import CreditDepositModal from "./CreditDepositModal";
import { Select, Space } from "antd";
import DepositVerificationModal from "./DepositVerificationModal";
import { UserContext } from "../contexts/UserContext";
import Notification from "./Notification";
import { postRequest } from "../backendServices/ApiCalls";
import QRCode from "react-qr-code";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Steps } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";

const Images = {
  close: "./icon/CDM.svg",
  dolphin: "./icon/dolphin.svg",
};

function DepositModal({ show, onClosePrevious }) {
  const { user } = useContext(UserContext);
  const [modalState, setModalState] = useState({
    cryptoModalOpen: false,
    bankTransferModal: false,
    creditCardModalOpen: false,
    verificationModal: false,
  });
  const [current, setCurrent] = useState(0);
  const [selectedOption, setSelectedOption] = useState("Crypto Payment");
  const [inputValue, setInputValue] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [loadingCoin, setLoadingCoin] = useState(false);
  const [coin, setCoin] = useState("USDT");
  const [network, setNetwork] = useState("");
  const [copied, setCopied] = useState(false);
  const [coinOptions, setCoinOptions] = useState([]);
  const [networkOptions, setNetworkOptions] = useState([]);
  const [showMethods, setShowMethods] = useState(false);
  const [loadingChain, setLoadingChain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressDeposit, setAddressDeposit] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [error, setError] = useState("");

  const handleInputChange = (event) => {
    setError("");
    const value = event.target.value;
    if (value === "" || value > 0) {
      setInputValue(value);
    }
  };
  useEffect(() => {
    const handleCoin = async () => {
      setLoadingCoin(true);

      if (!navigator.onLine) {
        Notification.showNotification(
          "error",
          "Network Error",
          "Please check your internet connection and try again."
        );
        setLoadingCoin(false);
        return;
      }

      postRequest(
        "yes",
        "api/bybit/getAllowedDepositCoinInfo",
        {},
        (response) => {
          const allowedCoins = ["USDT"];
          const filteredCoins = response?.data?.result?.configList?.filter(
            (coin, index, self) =>
              allowedCoins.includes(coin.coin) &&
              index === self.findIndex((c) => c.coin === coin.coin)
          );
          setCoinOptions(filteredCoins || []);
          setLoadingCoin(false);
        },
        (error) => {
          Notification.showNotification("error", "Error", "Error");
          console.error("Error making deposit request:", error);
          setLoadingCoin(false);
        }
      );
    };

    handleCoin();
  }, []);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };
  const chain = async (selectedCoin) => {
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }

    setLoadingChain(true);

    postRequest(
      "yes",
      "api/bybit/getCoinInfo",
      { coin: selectedCoin },
      (response) => {
        const allowedChains = ["ETH", "BSC", "ARBI", "MATIC", "OP"];
        const filteredChains = response?.data[0]?.chains?.filter((chain) =>
          allowedChains.includes(chain.chain)
        );
        setNetworkOptions(filteredChains || []);
        setLoadingChain(false);
      },
      (error) => {
        Notification.showNotification("error", "Error", "Error");
        console.error("Error fetching coin info:", error);
        setLoadingChain(false);
      }
    );
  };
  const toggleMethods = () => {
    setShowMethods((prev) => !prev);
  };
  const handleNextClick = () => {
    if (inputValue.trim() !== "") {
    } else {
      setError("Please enter a value before proceeding.");
      return;
    }
    if (!selectedOption) {
      alert("Please select a payment method.");
      return;
    }
    if (user.kycStatus === "success") {
      if (selectedOption === "Bank Transfer") {
        setModalState({ ...modalState, bankTransferModal: true });
      } else if (selectedOption === "Debit / Credit Card") {
        setModalState({ ...modalState, creditCardModalOpen: true });
      } else if (selectedOption === "Crypto Payment") {
        setModalState({ ...modalState, cryptoModalOpen: true });
      }
    } else {
      // Open another modal if the user is not fully verified
      setModalState({ ...modalState, verificationModal: true });
    }
  };
  const deposit = async () => {
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }
    setLoading(true);
    postRequest(
      "yes",
      "api/bybit/getSubDepositAddress",
      { coin, network },
      (response) => {
        // console.log(response?.data?.address);
        setAddressDeposit(response?.data?.address);
        Notification.showNotification(
          "success",
          "Success",
          "Deposit Address is now available"
        );
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          error?.data?.error || "An unexpected error occurred.";
        Notification.showNotification("error", "Error", errorMessage);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (coin) {
      chain(coin);
    }
  }, [coin]);
  const handleverifymodel = () => {
    if (user.kycStatus === "success") {
      if (coin && network) {
        deposit();
        setError("");
      } else {
        setError("Please enter a value before proceeding.");
      }
    } else {
      setModalState({ ...modalState, verificationModal: true });
    }
  };
  const handleCloseModal = (modalType) => {
    setModalState({ ...modalState, [modalType]: false });
  };

  if (!show) {
    return null;
  }
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(addressDeposit);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } catch (err) {
      console.error("Clipboard copy failed:", err);
    }
  };
  const handleChange = (value) => {
    setSelectedCurrency(value);
  };
  const isAnyOtherModalOpen =
    modalState.bankTransferModal ||
    modalState.cryptoModalOpen ||
    modalState.creditCardModalOpen ||
    modalState.verificationModal;
  const onChange = (value) => {
    setCurrent(value);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleverifymodel();
    }
  };
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };
  const handleSelect = (value) => {
    setNetwork(value);
    setError("");
    setIsOpen(false); // Close the dropdown after selection
  };

  return (
    <div className="fixed z-50 inset-0 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
      <div
        className={`bg-Accent p-4 rounded-lg w-[400px] sm:w-[738px] animate-fadeIn ${
          isAnyOtherModalOpen ? "hidden" : ""
        }`}
      >
        {addressDeposit ? (
          <div className="bg-[#211E34E5] pt-6 border rounded-3xl border-[#FFFFFF26]">
            <div className="relative flex justify-between mx-[4%]">
              <p className="font-sans text-[16px] font-bold leading-[1.26] text-left">
                Deposit Confirmation
              </p>
              <img
                src={Images.close}
                onClick={() => {
                  onClosePrevious();
                  setInputValue("");
                }}
                className=" right-6 size-4 cursor-pointer"
                alt="close-icon"
              />
            </div>
            <div className="mx-[4%] mt-6 gap-4 flex justify-between">
              <div className="w-full">
                <p className="font-helvetica text-[12px] text-[#9B9B9B] font-normal leading-6 text-left">
                  Selected Coin
                </p>
                <div className="border mt-1 border-[#FFFFFF80] flex gap-2 px-2 h-12 rounded">
                  <img src="/icon/usdtnew.png" className="w-20 h-8 my-auto" />
                  <p className="font-sora text-[14px] font-bold leading-[17.64px] my-auto text-center text-[#FFFFFFBF]">
                    USDT
                  </p>
                </div>
              </div>
              <div className="w-full">
                <p className="font-helvetica text-[12px] text-[#9B9B9B] font-normal leading-6 text-left">
                  Selected Chain
                </p>
                <div className="border mt-1 border-[#FFFFFF80]  px-2 h-12 rounded">
                  <p className="font-sora text-[14px] font-bold leading-[17.64px] mt-4 text-left text-[#FFFFFFBF]">
                    {network}
                  </p>
                </div>
              </div>
            </div>
            <hr className="my-6 mx-[4%] border-t border-[#FFFFFF26]" />
            <div className="flex mx-[4%] bg-[#3F3C4F] gap-4 px-4 py-7 rounded-lg">
              <div className="p-2 bg-white rounded-lg">
                <QRCode value={addressDeposit} className="size-16 " />
              </div>
              <div className="w-full border relative border-[#FFFFFF80] rounded flex h-10 my-auto">
                {copied && (
                  <div className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 bg-black text-accent text-sm rounded-md px-2 py-1">
                    Copied!
                  </div>
                )}
                <input
                  type="text"
                  value={addressDeposit}
                  readOnly
                  className="w-full cursor-pointer text-left bg-[#3F3C4F] border-none text-[#FFFFFFBF] rounded-md p-2"
                  onClick={(e) => e.target.select()}
                />
                <button
                  className="flex items-center justify-center bg-[#00000026] m-1 p-2 rounded"
                  aria-label="Copy secret key flex"
                  onClick={handleCopy}
                >
                  {copied ? (
                    <CheckCircleIcon className="cursor-pointer text-green-500" />
                  ) : (
                    <ContentCopyIcon className="cursor-pointer" />
                  )}
                  <p className="font-sora text-[10px] font-normal w-20 leading-[12.6px] text-center">
                    Copy Address
                  </p>
                </button>
              </div>
            </div>
            <div className="mt-6 bg-[#3F3C4F] rounded-lg px-[4%] mx-[4%] ">
              <div
                onClick={toggleMethods}
                className="flex gap-2 py-4 justify-center items-center"
              >
                <p className="font-helvetica text-[14px] font-bold leading-6 text-left">
                  Instructions
                </p>
                <img
                  src={showMethods ? "/icon/upword.svg" : "/icon/dad.svg"}
                  className="cursor-pointer w-4 my-auto h-4"
                  alt="Toggle Payment Methods"
                />
              </div>
              <div
                className={`transition-all duration-1000 ease-in-out ${
                  showMethods ? "opacity-100 max-h-screen" : "opacity-0 max-h-0"
                } overflow-hidden`}
              >
                {showMethods && (
                  <>
                    <div className="custom-steps flex justify-between rounded">
                      <div className="flex-1">
                        <Steps
                          direction="vertical"
                          size="small"
                          current={2}
                          items={[
                            {
                              title: (
                                <span className="text-[#9B9B9B] text-[11px]">
                                  Copy the deposit address
                                </span>
                              ),
                              icon: (
                                <span className="text-[11px] py-0.5 rounded-full px-1.5 text-white bg-[#534f69] ">
                                  1
                                </span>
                              ),
                            },
                            {
                              title: (
                                <span className="text-[#9B9B9B] text-[11px]">
                                  Open your crypto wallet
                                </span>
                              ),
                              icon: (
                                <span className="text-[11px] py-0.5 rounded-full px-1.5 text-white bg-[#534f69] ">
                                  2
                                </span>
                              ),
                            },
                            {
                              title: (
                                <span className="text-[#9B9B9B] text-[11px]">
                                  Verify you're on the correct [network]
                                </span>
                              ),
                              icon: (
                                <span className="text-[11px] py-0.5 rounded-full px-1.5 text-white bg-[#534f69] ">
                                  3
                                </span>
                              ),
                            },
                          ]}
                        />
                      </div>
                      <div className="flex-1">
                        <Steps
                          direction="vertical"
                          size="small"
                          current={2}
                          items={[
                            {
                              title: (
                                <span className="text-[#9B9B9B] text-[11px]">
                                  Send USDT{" "}
                                </span>
                              ),
                              icon: (
                                <span className="text-[11px] py-0.5 rounded-full px-1.5 text-white bg-[#534f69] ">
                                  4
                                </span>
                              ),
                            },
                            {
                              title: (
                                <span className="text-[#9B9B9B] text-[11px]">
                                  Review and send the transaction
                                </span>
                              ),
                              icon: (
                                <span className="text-[11px] py-0.5 rounded-full px-1.5 text-white bg-[#534f69] ">
                                  5
                                </span>
                              ),
                            },
                            {
                              title: (
                                <span className="text-[#9B9B9B] text-[11px]">
                                  wait for confirmation (up to 30 minutes)
                                </span>
                              ),
                              icon: (
                                <span className="text-[11px] py-0.5 rounded-full px-1.5 text-white bg-[#534f69] ">
                                  6
                                </span>
                              ),
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex gap-2 justify-center items-center mt-6">
              <div className={`w-1 h-1 rounded-full bg-[#6d6d6d]`}></div>
              <div className={`w-1 h-1 rounded-full bg-[#fff1f1b8]`}></div>
            </div>
            <div className="mx-[4%] justify-between flex gap-6 my-6">
              <button
                className="w-[30%] border  border-[#FFFFFF80] py-2 rounded"
                onClick={() => {
                  setAddressDeposit("");
                }}
              >
                <span className="font-sora text-[12px] font-bold  text-[#FFFFFF80]">
                  Back
                </span>
              </button>
              <button
                className="w-[30%] border border-primary bg-primary py-2 rounded"
                onClick={() => {
                  onClosePrevious();
                  setInputValue("");
                }}
              >
                <span className=" font-sora text-[12px] font-bold text-black">
                  Close Window
                </span>
              </button>
            </div>
          </div>
        ) : (
          <div className="bg-[#211E34E5] pt-6 border rounded-3xl border-[#FFFFFF26]">
            <div className="relative flex justify-between mx-[4%]">
              <p className="font-sans text-[16px] font-bold leading-[1.26] text-left">
                Deposit Details
              </p>
              <img
                src={Images.close}
                onClick={() => {
                  onClosePrevious();
                  setInputValue("");
                }}
                className=" right-6 size-4 cursor-pointer"
                alt="close-icon"
              />
            </div>
            <p className="font-sans text-[#9B9B9B] text-[12px] font-normal leading-6 text-left ml-[4%]">
              Any questions? Find more information in our{" "}
              <Link to="/faqs">
                <span className="text-primary">FAQ</span>
              </Link>
            </p>
            <div className="flex gap-4 ml-[4%] mt-6">
              <p className="font-helvetica text-[#9B9B9B] text-[12px] font-normal leading-[24px] text-left">
                Choose coin to deposit
              </p>
              <img src="/icon/i.png" className="size-4 my-auto" />
            </div>
            <div
              className="flex p-2 border mt-2 border-[#FFFFFF80] mx-[4%] rounded gap-3 cursor-pointer"
              onClick={() => handleOptionClick("Crypto Payment")}
            >
              <img
                src="/icon/usdtnew.png"
                className="w-20 h-8 rounded-lg"
                alt="usdtnew Icon"
              />
              <div className="flex justify-between items-center flex-grow">
                <div>
                  <p className="font-sora text-[14px] text-[#FFFFFFBF] font-bold leading-[15.12px] text-left">
                    {coin}
                  </p>
                </div>
                {selectedOption === "Crypto Payment" && (
                  <img
                    src="/icon/dad.svg"
                    className="w-5 h-5 rounded-lg"
                    alt="Selected Icon"
                  />
                )}
              </div>
            </div>
            <hr className="my-6 mx-[4%] border-t border-[#FFFFFF26]" />
            <div className="flex gap-4 ml-[4%] mt-6">
              <p className="font-helvetica text-[12px] text-[#9B9B9B] font-normal leading-[24px] text-left">
                Choose Chain
              </p>
              <img src="/icon/i.png" className="size-4 my-auto" />
            </div>
            <div className="flex justify-center mt-1">
              {loadingChain ? (
                <img
                  src="/icon/yellowloader.svg"
                  alt="Loading..."
                  className="w-7 mx-auto h-7 animate-spin"
                />
              ) : (
                <div className="w-[92%] ">
                  <div className="relative">
                    <div
                      className="appearance-none bg-[#211E34E5] border border-[#FFFFFF80] text-[#FFFFFFBF] font-semibold w-full py-2.5 px-4 rounded cursor-pointer"
                      onClick={toggleDropdown}
                    >
                      {network ? (
                        <>
                          {
                            networkOptions?.find(
                              (option) => option.chain === network
                            )?.chain
                          }
                          <span className="text-[10px] font-normal ml-4">
                            0% fee
                          </span>
                        </>
                      ) : (
                        <span className="text-[#FFFFFF80]">Select Network</span>
                      )}
                    </div>
                    {isOpen && (
                      <ul
                        className="absolute left-0 right-0 bg-[#211E34E5] border border-[#FFFFFF80] text-[#FFFFFFBF] font-semibold rounded"
                        onKeyPress={handleKeyPress}
                      >
                        {networkOptions?.map((networkOption, index) => {
                          let displayValue = networkOption?.chain;
                          const networkInfo = {
                            ETH: "ETH(ERC20)",
                            BSC: "BSC (BEP20)",
                            ARBI: "ARBITRUM ONE",
                            MATIC: "POLYGON PoS",
                            OP: "OP MAINNET",
                          };
                          if (networkInfo[networkOption?.chain]) {
                            displayValue = (
                              <div className="text-[12px] font-semibold">
                                {networkInfo[networkOption?.chain]}{" "}
                                <span className="text-[10px] font-normal">
                                  0% fee
                                </span>
                              </div>
                            );
                          }
                          return (
                            <li
                              key={index}
                              className="px-4 py-2 cursor-pointer hover:bg-[#3a365f] rounded"
                              onClick={() => handleSelect(networkOption?.chain)}
                            >
                              {displayValue}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                    <div className="absolute right-3 bg-[#211E34E5] top-1/2 transform cursor-pointer -translate-y-1/2">
                      <img
                        src="/icon/dad.svg"
                        className="w-5 h-5 rounded-lg"
                        alt="Selected Icon"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <p className="font-helvetica text-[12px] text-red-600 mx-[4%] font-normal leading-[24px] text-left">
              {error}
            </p>
            <div className="flex gap-2 justify-center items-center mt-6">
              <div className={`w-1 h-1 rounded-full bg-[#fff1f1b8]`}></div>
              <div className={`w-1 h-1 rounded-full bg-[#6d6d6d]`}></div>
            </div>
            <div className="mx-[4%] justify-between flex gap-6 my-6">
              <button
                className="w-[30%] border  border-[#FFFFFF80] py-2 rounded"
                onClick={() => {
                  onClosePrevious();
                  setInputValue("");
                  setError("");
                }}
              >
                <span className="font-sora text-[12px] font-bold  text-[#FFFFFF80]">
                  Cancel
                </span>
              </button>
              <button
                className="w-[30%] border border-primary bg-primary py-2 rounded"
                onClick={handleverifymodel}
              >
                <span className=" font-sora text-[12px] font-bold text-black">
                  {loading ? (
                    <img
                      src="/icon/loader.svg"
                      alt="Loading..."
                      className="w-7 mx-auto h-7 animate-spin"
                    />
                  ) : (
                    "Next Step"
                  )}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>

      {modalState.bankTransferModal && (
        <BankDepositModal
          show={modalState.bankTransferModal}
          onClose={() => handleCloseModal("bankTransferModal")}
        />
      )}
      {modalState.cryptoModalOpen && (
        <CryptoDepositModal
          show={modalState.cryptoModalOpen}
          onClose={() => handleCloseModal("cryptoModalOpen")}
        />
      )}
      {modalState.creditCardModalOpen && (
        <CreditDepositModal
          show={modalState.creditCardModalOpen}
          onClose={() => handleCloseModal("creditCardModalOpen")}
        />
      )}
      {modalState.verificationModal && (
        <DepositVerificationModal
          show={modalState.verificationModal}
          onClose={() => handleCloseModal("verificationModal")}
          type="deposit"
        />
      )}
    </div>
  );
}

export default DepositModal;
