import React from "react";
import { Link } from "react-router-dom";

function Ourvision() {
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="400"
      className=" w-[95%] 2xl:w-[80%] mt-52 mx-auto"
    >
      <div className="grid grid-cols-1  mx-2 md:grid-cols-2">
        <div className="bg-[#222222] w-[97%] h-[80%] rounded-3xl ">
          <img
            src="/icon/vision.png "
            className="w-[90%] h-[110%] mx-auto mt-[8%]"
            alt="Our Vision"
          />
        </div>
        <div>
            
          <div className="ml-10">
          <p className="w-[77.73px] mx-auto font-sora  text-primary h-[22.4px] ">VALUE</p>
          </div>
          <p className="font-sora text-[44px] ml-2 mt-2 font-semibold leading-[49.28px]  text-center">
            Our VISION
          </p>
          <p className="font-helvetica text-[16px] mt-4 font-medium leading-[24px] text-center ">
            AlgoX Capital is your gateway to effortless crypto investing. Our
            state-of- the-art algorithmic trading services leverages the power
            of AI to generate passive income for our users.
          </p>
          <p className="font-helvetica text-[16px] font-normal mt-4 leading-[24px] text-center ">
            With AlgoX Capital, you don't need to be a trading expert to profit
            from the crypto market. Simply set your investment amount and let
            our sophisticated algorithms work for you 24/7. Just optimizing your
            returns while minimizing risk.
          </p>
          <div className="flex mt-8 gap-4 justify-center items-center sm:gap-10 ">
            <div>
              <p className="font-sora text-[24px] sm:text-[32px] font-semibold leading-[35.84px] tracking-[-0.64px] text-left">
                + 1,016%
              </p>
              <p className="font-sora mb-4 text-[11px] sm:text-[14px] text-primary font-normal leading-[19.6px] text-center">
                Since 2018
              </p>
            </div>
            <div>
              <p className="font-sora text-[24px] sm:text-[32px] ml-2 font-semibold leading-[35.84px] tracking-[-0.64px] text-left">
                15x
              </p>
              <p className="font-sora  text-[11px] sm:text-[14px] text-primary font-normal leading-[19.6px] text-left">
                Better than <span className="block"></span> MSCI World
              </p>
            </div>
            <div>
              <p className="font-sora text-[24px] sm:text-[32px] font-semibold leading-[35.84px] tracking-[-0.64px] text-left">
                +100k $
              </p>
              <p className="font-sora text-[11px] sm:text-[14px] text-primary font-normal leading-[19.6px] text-left">
                {" "}
                Generated Profit <span className="block"></span> for our
                costumers
              </p>
            </div>
          </div>
          <Link to="/dashboard">
            <button className="bg-primary mt-6 ml-[35%] sm:ml-[40%] my-auto px-8 h-14 rounded-md hover:bg-[#f1ae1c] hover:text-accent">
              <p className="font-sora text-[16px] text-black font-medium leading-[23.94px] text-left hover:text-accent">
                Try For Free
              </p>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Ourvision;
