import React, { useContext } from "react";
import Navbar from "../../components/Navbar";
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";

function Activity() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const trades = [
    {
      name: "Solana / USDT",
      type: "Long",
      entry: "145,98 USDT",
      exit: "198,07 USDT",
      percentage: "23,40%",
      profit: "43,07 USDT",
    },
    {
      name: "Bitcoin / USDT",
      type: "Long",
      entry: "145,98 USDT",
      exit: "198,07 USDT",
      percentage: "23,40%",
      profit: "43,07 USDT",
    },
    {
      name: "FXS / USDT",
      type: "Long",
      entry: "145,98 USDT",
      exit: "198,07 USDT",
      percentage: "23,40%",
      profit: "43,07 USDT",
    },
    {
      name: "Ethereum / USDT",
      type: "Long",
      entry: "145,98 USDT",
      exit: "198,07 USDT",
      percentage: "23,40%",
      profit: "43,07 USDT",
    },
    {
      name: "Ethereum / USDT",
      type: "Long",
      entry: "145,98 USDT",
      exit: "198,07 USDT",
      percentage: "23,40%",
      profit: "43,07 USDT",
    },
    {
      name: "Ethereum / USDT",
      type: "Long",
      entry: "145,98 USDT",
      exit: "198,07 USDT",
      percentage: "23,40%",
      profit: "43,07 USDT",
    },
    {
      name: "Ethereum / USDT",
      type: "Long",
      entry: "145,98 USDT",
      exit: "198,07 USDT",
      percentage: "23,40%",
      profit: "43,07 USDT",
    },
    {
      name: "Ethereum / USDT",
      type: "Long",
      entry: "145,98 USDT",
      exit: "198,07 USDT",
      percentage: "23,40%",
      profit: "43,07 USDT",
    },
    {
      name: "Ethereum / USDT",
      type: "Long",
      entry: "145,98 USDT",
      exit: "198,07 USDT",
      percentage: "23,40%",
      profit: "43,07 USDT",
    },
    {
      name: "Ethereum / USDT",
      type: "Long",
      entry: "145,98 USDT",
      exit: "198,07 USDT",
      percentage: "23,40%",
      profit: "43,07 USDT",
    },
  ];
  return (
    <div className="body">
      <div className="navbar-bg-color px-2 lg:px-4">
        <Navbar />
        <div className="grid grid-cols-5 items-center mx-[3%]">
          {/* <img
            src="/icon/logo.svg"
            className="ml-1 mt-2 hidden sm:block col-span-1 w-40 sm:w-72 md:w-72 cursor-pointer"
            alt="Logo"
          /> */}
          <p className="font-sora my-8 text-xl lg:text-[26px] font-extrabold leading-[62.72px] tracking-[-1.68px] text-left text-accent">
            Welcome {user?.name}
          </p>
          {/* <div className="flex justify-between col-span-4 items-center ml-4 w-full">
            <div></div>
            <div className="flex gap-5 items-center bg-[#191919] px-4 py-2 rounded-3xl">
              <img
                src="/icon/userprofile.png"
                className="cursor-pointer h-6 w-6"
                alt="User Profile"
              />
              <img
                src="/icon/userprofiledownarrow.png"
                className="cursor-pointer h-2 w-3"
                alt="Dropdown Arrow"
              />
            </div>
          </div> */}
        </div>

        <div className="grid sm:grid-cols-5 ">
          <div className="sm:col-span-1 hidden sm:block border-r border-gray">
            {/* Side Menu Items */}
            <div
              onClick={() => navigate("/dashboard")}
              className="flex gap-2 px-3  cursor-pointer py-2 mx-2 lg:mx-8 rounded-xl"
            >
              <img
                src="/icon/dashboardicon.png"
                className="size-4 my-auto lg:size-6 cursor-pointer"
                alt="dashboardicon"
              />
              <p className="font-sans text-[12px] lg:text-[16px] font-normal text-[#9B9B9B] leading-[24px] text-left mt-0.5">
                Dashboard
              </p>
            </div>
            <div className="flex gap-2 px-3 mt-4 cursor-pointer border py-2 border-accent  mx-2 lg:mx-8 rounded-xl">
              <img
                src="/icon/activity.png"
                className="size-4 my-auto lg:size-6 cursor-pointer"
                alt="dashboardicon"
              />
              <p className="font-sans text-[12px] lg:text-[16px] text-accent font-normal leading-[24px] text-left mt-0.5">
                Activity
              </p>
            </div>
            <div className="flex gap-2 px-3 mt-4 cursor-pointer borde py-2 border-whit  mx-2 lg:mx-8 rounded-xl">
              <img
                src="/icon/activity.png"
                className="size-4 my-auto lg:size-6 cursor-pointer"
                alt="dashboardicon"
              />
              <p
                onClick={() => navigate("/user-transaction")}
                className="font-sans text-[12px] lg:text-[16px] text-[#9B9B9B] font-normal leading-[24px] text-left mt-0.5"
              >
                Transaction History
              </p>
            </div>
            <hr className=" mx-2 lg:mx-8 my-4 border-t border-[#404040]" />
            <div className="flex gap-2 px-3 borde cursor-pointer py-2 border-whit  mx-2 lg:mx-8 rounded-xl">
              <img
                src="/icon/contact.png"
                className="size-4 my-auto lg:size-6 cursor-pointer"
                alt="dashboardicon"
              />
              <p className="font-sans text-[#9B9B9B] text-[12px] lg:text-[16px] font-normal leading-[24px] text-left mt-0.5">
                Contact Us
              </p>
            </div>
          </div>

          <div className="col-span-4">
            <div className="mt-8 px-2 sm:px-4 col-span-4">
              <div className="p-2 sm:p-8 border border-gray rounded-3xl w-full">
                <p className="font-sora text-[16px] font-bold leading-[20.16px] tracking-tight text-left text-[#FFFFFFBF]">
                  Recent Trades
                </p>
                <table className="w-full border-collapse mt-4">
                  <thead>
                    <tr className="border-b  border-gray">
                      <th className=" px-0.5 sm:px-3 py-2 font-sans text-[10px] sm:text-[10px] md:text-[14px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                        Name
                      </th>
                      <th className=" px-0.5 sm:px-3 py-2 font-sans text-[10px] sm:text-[10px] md:text-[14px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                        Type
                      </th>
                      <th className=" px-0.5 sm:px-3 py-2 font-sans text-[10px] sm:text-[10px] md:text-[14px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                        Entry
                      </th>
                      <th className=" px-0.5 sm:px-3 py-2 font-sans text-[10px] sm:text-[10px] md:text-[14px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                        Exit
                      </th>
                      <th className=" px-0.5 sm:px-3 py-2 font-sans text-[10px] sm:text-[10px] md:text-[14px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                        Percentage
                      </th>
                      <th className=" px-0.5 sm:px-3 py-2 font-sans text-[10px] sm:text-[10px] md:text-[14px] font-normal leading-[24px] text-center text-[#9B9B9B]">
                        Profit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {trades.map((trade, index) => (
                      <tr key={index} className="">
                        <td className="  lg:px-3 py-3 ">
                          <div className="flex gap-2">
                            <img
                              src="/icon/dashboardgallery.png"
                              className="w-3 my-auto h-2 sm:w-6 sm:h-4"
                            />
                            <p className="text-[6px] sm:text-[8px] md:text-[10px] lg:text-[16px] font-normal leading-[24px] text-left font-helvetica text-accent">
                              {trade.name}
                            </p>
                          </div>
                        </td>
                        <td className="lg:px-3 py-3 text-[7px] sm:text-[8px] md:text-[10px] lg:text-[16px] font-normal leading-[24px] text-left font-helvetica text-accent">
                          {trade.type}
                        </td>
                        <td className="lg:px-3 py-3 text-[7px] sm:text-[8px] md:text-[10px] lg:text-[16px] font-normal leading-[24px] text-left font-helvetica text-accent">
                          {trade.entry}
                        </td>
                        <td className="lg:px-3 py-3 text-[7px] sm:text-[8px] md:text-[10px] lg:text-[16px] font-normal leading-[24px] text-left font-helvetica text-accent">
                          {trade.exit}
                        </td>
                        <td className="lg:px-3 py-3 text-[7px] sm:text-[8px] md:text-[10px] lg:text-[16px] font-normal leading-[24px] text-left font-helvetica text-accent">
                          {trade.percentage}
                        </td>
                        <td className="lg:px-3 py-1 sm:py-3 ">
                          <div className="bg-[#103E00] rounded-2xl w-[100%] xl:w-[70%]">
                            <p className=" px-1 font-sora text-[7px] sm:text-[6px] md:text-[9px] lg:text-[10px] font-bold leading-[23.94px] text-center text-[#3BD306]">
                              {trade.profit}
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Activity;
