import React, { useState } from "react";
import PurchaseModal from "../components/PurchaseModal";
import WithdrawModal from "./WithdrawModal";

const Images = {
  close: "./icon/close.svg",
  dolphin: "./icon/dolphin.svg",
};

export default function PurchaseCard() {
  const [hovered, setHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const togglewithdrawModal = () => {
    setIsWithdrawModalOpen(!isWithdrawModalOpen);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handlePurchaseModalClick = () => {
    setPurchaseModal(true);
  };

  const handleClosePurchaseModal = () => {
    setPurchaseModal(false);
  };
  const handleWithdrawModalClick = () => {
    setWithdrawModal(true);
  };

  const handleCloseWithdrawModal = () => {
    setWithdrawModal(false);
  };


  return (
    <>
      <div className="flex  flex-col lg:flex-row  md:mb-0">
        {/* Card */}
        <div className="relative w-[95%] sm:w-[80%]  lg:w-[46%] xl:w-[44%] lg:max-w-[60%] h-[236px]  md:mx-[10%] mb-[25px]">
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="bg-black relative group grid grid-cols-3 md:grid-cols-3 h-52 gap-x-2 md:ml-[-80px] ml-4 sm:gap-4 border-Accent2 border-2 rounded-2xl"
          >
            <div className="mt-8 sm:mt-2 grid-cols-1 md:grid-cols-3 w-32 sm:w-36 md:w-56 xl:w-60">
              <img
                src={Images.dolphin}
                width={260}
                height={216}
                alt="card-img"
              />
            </div>
            <div className="md:ml-6 ml-4">
              <h1 className="text-yellow-500 mt-10 font-poppins text-xs md:text-2xl font-semibold leading-normal capitalize">
                Starter
              </h1>
              <p className="text-Accent text-accent font-poppins text-xs md:text-base font-semibold leading-normal">
                100 – 500 USD
              </p>
              <div onClick={handlePurchaseModalClick}>
                <button className="transition duration-500 bg-yellow-500 ease-in-out hover:bg-black hover:text-Accent active:bg-Accent2 active:text-Accent bg-Accent2 border-Accent2 w-[80px] md:w-[144px] h-[45px] mt-[20px] py-3 text-center rounded-md">
                  <h2 className="w-[80px] text-accent md:w-[143px] h-[28px] font-medium">
                    Purchase
                  </h2>
                </button>
              </div>
            </div>
            <div>
              <h1 className="text-Accent  mt-10 text-accent sm:mt-11 font-poppins text-xs md:text-lg font-medium leading-normal">
                Binary weekly cap
              </h1>
              <p className="text-Accent text-accent font-poppins text-xs md:text-lg font-light leading-normal">
                35.00%
              </p>
              <div className="transition cursor-pointer border-yellow-500 duration-500 ease-in-out hover:bg-Accent2 hover:text-black active:bg-Accent2 active:text-black w-[80px] md:w-[144px] border border-Accent2 h-[45px] mt-[20px] py-3 text-center rounded-md" onClick={handleWithdrawModalClick}>
                <h2 className="w-[80px] md:w-[143px] text-accent h-[28px] text-Accent font-semibold">
                  Withdraw
                </h2>
              </div>
            </div>
          </div>
        </div>

        {/* On Hover Div */}
        {hovered && (
          <div className=" mr-auto mb-2 lg:mr-2">
            <div className="flex flex-col gap-3 z-10">
              <h1 className="text-accent text-[1.5rem] ml-[10px]  mt-[-50px] ">
                Starter
              </h1>
              <div className="text-accent flex flex-col gap-4 px-[0.7rem] lg:px-[2rem] py-[1rem] bg-[#000] border border-primary rounded-xl">
                <div className="flex flex-col gap-2">
                  <h4 className="text-primary font-medium">
                    Staking Hub Details
                  </h4>
                  <div className="flex flex-col gap-1 ml-1">
                    {/* Repeatable details */}
                    {[
                      "Stake ratio 1.0",
                      "Auto stake ON: 02% daily reward",
                      "Auto Stake OFF : up to 0.1% daily rewards",
                    ].map((detail, index) => (
                      <p key={index} className="flex items-center gap-1">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 23 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.1984 0.0585938C5.04034 0.0585938 0.00195312 5.09699 0.00195312 11.255C0.00195312 17.4131 5.04034 22.4515 11.1984 22.4515C17.3564 22.4515 22.3948 17.4131 22.3948 11.255C22.3948 5.09699 17.3564 0.0585938 11.1984 0.0585938ZM8.95908 16.8532L3.36088 11.255L4.93957 9.67633L8.95908 13.6847L17.4572 5.18656L19.0358 6.77645L8.95908 16.8532Z"
                            fill="#FFBF00"
                          />
                        </svg>
                        <span className="text-[0.8rem]">{detail}</span>
                      </p>
                    ))}
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <h4 className="text-primary font-medium">Bonuses</h4>
                  <div className="flex flex-col gap-1 ml-1">
                    {/* Repeatable bonuses */}
                    {["7% Direct bonus", "10% binary bonus"].map(
                      (bonus, index) => (
                        <p key={index} className="flex items-center gap-1">
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.1984 0.0585938C5.04034 0.0585938 0.00195312 5.09699 0.00195312 11.255C0.00195312 17.4131 5.04034 22.4515 11.1984 22.4515C17.3564 22.4515 22.3948 17.4131 22.3948 11.255C22.3948 5.09699 17.3564 0.0585938 11.1984 0.0585938ZM8.95908 16.8532L3.36088 11.255L4.93957 9.67633L8.95908 13.6847L17.4572 5.18656L19.0358 6.77645L8.95908 16.8532Z"
                              fill="#FFBF00"
                            />
                          </svg>
                          <span className="text-[0.8rem]">{bonus}</span>
                        </p>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* {isModalOpen && <PurchaseModal func={toggleModal} />}
      <PurchaseModal show={purchaseModal} onClose={handleClosePurchaseModal} />
      {isWithdrawModalOpen && <WithdrawModal func={togglewithdrawModal} />}
      <WithdrawModal show={withdrawModal} onClose={handleCloseWithdrawModal} /> */}
    </>
  );
}
