import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { Dropdown, Space } from "antd";
import Badge from "@mui/material/Badge";
import { URL } from "../constants/constant";
function Navbar() {
  const { user, setUser, verifyToken } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  // console.log("user", user);
  const location = useLocation();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token === null) {
      verifyToken();
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (dropdownVisible) {
      setAnimationClass("fade-out");
      setTimeout(() => {
        setDropdownVisible(false);
      }, 300);
    } else {
      setDropdownVisible(true);
      setAnimationClass("fade-in");
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setAnimationClass("fade-out");
      setTimeout(() => {
        setDropdownVisible(false);
      }, 300);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const items = [
    {
      key: "1",
      label: (
        <Link to="/usersetting" className="text-accent hover:text-primary">
          Personal Settings
        </Link>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <Link to="/referral" className="text-accent hover:text-primary">
    //       Referral Program
    //     </Link>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: (
    //     <Link
    //       to="/notificationsetting"
    //       className="text-accent hover:text-primary"
    //     >
    //       Notification Center
    //     </Link>
    //   ),
    // },
  ];
  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };
  // const logout = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await fetch(`${URL}/auth/logout`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       withCredentials: true,
  //       body: JSON.stringify(user),
  //     });
  //     if (response.status == 200) {
  //       localStorage.removeItem("token");
  //       setUser(null);
  //     }
  //   } catch (error) {
  //     // console.error("Error during logout:", error);
  //     setLoading(true);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <div className="pt-8 2xl:w-4/5 mx-auto">
      <div className="flex  sm:justify-between  bg-secondary h-20 mx-4 sm:mx-8 rounded-2xl">
        <Link to="/">
          <img
            src="/icon/logo.svg"
            className="ml-1 my-auto w-40 lg:w-60 mt-2 lg:mt-[-8px] md::w-72 cursor-pointer"
            alt="Logo"
          />
        </Link>
        {user ? (
          <div className=" hidden mx-4 md:mr-[10%] my-auto sm:block">
            <div className="flex gap-4 lg:gap-14  ">
              <Link to="/">
                <p className="font-helvetica text-[14px] text-accent  lg:text-[20px] font-normal cursor-pointer leading-[25.09px] tracking-custom text-center">
                  Home
                </p>
              </Link>
              {/* <Link to="/pricingplan">
                <p className="font-sora text-[14px]  text-accent lg:text-[20px] font-normal cursor-pointer leading-[25.09px] tracking-custom text-center">
                  Shop
                </p>
              </Link> */}
              
                <Link to="/dashboard">
                  <div className="flex cursor-pointer gap-2">
                    <p className="font-helvetica text-[14px] text-accent lg:text-[20px] font-normal leading-[25.09px] tracking-custom text-center">
                      Dashboard
                    </p>
                    {/* <img
                      src="/icon/downarrow.png"
                      className="h-1.5 w-2 my-auto"
                      alt="downarrow picture"
                    /> */}
                  </div>
                </Link>
            
               <Space direction="vertical">
                <Space wrap>
                  <Dropdown
                    overlayClassName="custom-dropdown-menu"
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                  >
                    <div className="flex cursor-pointer gap-2">
                      <p className="font-helvetica text-[14px] lg:text-[20px] font-normal leading-[25.09px] tracking-custom text-accent">
                        Account
                      </p>
                      <img
                        src="/icon/downarrow.png"
                        className="h-1.5 w-2 my-auto"
                        alt="downarrow picture"
                      />
                    </div>
                  </Dropdown>
                </Space>
              </Space> 
            </div>
          </div>
        ) : (
          ""
        )}
        {!user ? (
          <div className="flex  gap-2 ">
            {/* <Link to="/signup">
              <button className="bg-primary  hidden sm:block  mt-7 sm:mt-3 px-2 h-6 sm:px-6 sm:h-14 rounded-xl hover:bg-[#f1ae1c] hover:text-accent">
                <p className="font-sora text-[10px] sm:text-[16px] text-black text-center font-medium leading-[23.94px]  hover:text-accent">
                  Connect Wallet
                </p>
              </button>
            </Link> */}
            <Link to="/login" className="hidden sm:block">
              <div className="bg-primary flex h-6  sm:h-14 px-2 sm:px-7 rounded-xl mt-7 sm:mt-3 group  hover:bg-[#f1ae1c] duration-700  mr-1 sm:mr-4">
                <button className="flex items-center ">
                  <p className="font-helvetica  text-[12px] sm:text-[16px]  hover:text-accent text-black font-medium leading-[23.94px]  ">
                    Sign In
                  </p>
                </button>
                {/* <img
                  src="/icon/downarrowblack.svg"
                  className="sm:ml-2 ml-1 w-2"
                  alt="Logo"
                /> */}
              </div>
            </Link>
          </div>
        ) : (
          <div className="flex ml-auto sm:ml-0  gap-4  sm:mr-4">
            {/* <Badge
              badgeContent={9}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "red",
                  width: 2,
                  height: 18,
                  fontSize: 8,
                  color: "accent",
                  borderRadius: "50%",
                },
              }}
              className="my-7 cursor-pointer"
            >
              <img
                src="/icon/notification.svg"
                className="size-6"
                alt="Custom Icon"
              />
            </Badge> */}

            <Link to="/">
              <button
                onClick={logout}
                className="bg-primary  hidden sm:block mr-4 mt-7 sm:mt-3 px-2 h-6 sm:px-6 sm:h-14 rounded-xl hover:bg-[#f1ae1c] hover:text-accent"
              >
                <p className="font-helvetica text-[10px] sm:text-[16px] text-black text-center font-medium leading-[23.94px]  hover:text-accent">
                  {loading ? (
                    <img
                      src="/icon/loader.svg"
                      alt="Loading..."
                      className="w-7 mx-auto h-7 animate-spin"
                    />
                  ) : (
                    "Logout"
                  )}
                </p>
              </button>
            </Link>
          </div>
        )}

        <div className="flex ml-auto sm:ml-0 items-center sm:hidden">
          <div className="block sm:hidden">
            <button
              onClick={toggleMenu}
              className="text-accent focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      {isOpen && (
        <div className="fixed top-0  w-full h-full bg-black bg-opacity-85 flex items-start justify-start z-50">
          <div className="w-4/5 bg-transparent mx-auto rounded-xl">
            <div className="flex justify-end mr-4 mt-4">
              <button
                onClick={toggleMenu}
                className="text-accent focus:outline-none"
              >
                {/* Close button */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            {user ? (
              <div className=" grid gap-10 my-auto">
                <Link to="/">
                  <p className="font-helvetica text-[20px] font-normal cursor-pointer leading-[25.09px] tracking-custom text-center">
                    Home
                  </p>
                </Link>
                {/* <Link to="/pricingplan">
                  <p className="font-sora text-[20px] font-normal cursor-pointer leading-[25.09px] tracking-custom text-center">
                    Shop
                  </p>
                </Link> */}
                {location.pathname !== "/dashboard" && (
                  <Link to="/dashboard">
                    <div className="flex items-center justify-center cursor-pointer gap-2">
                      <p className="font-helvetica text-[20px] font-normal leading-[25.09px] tracking-custom text-center">
                        Dashboard
                      </p>
                      {/* <img
                        src="/icon/downarrow.png"
                        className="h-1.5 w-2 my-auto"
                        alt="downarrow picture"
                      /> */}
                    </div>
                  </Link>
                )}
                {/* <div className="relative " ref={dropdownRef}>
                  <div
                    className="flex items-center justify-center cursor-pointer gap-2"
                    onClick={toggleDropdown}
                  >
                    <p className="font-sora text-[20px] font-normal leading-[25.09px] tracking-custom text-center">
                      Account
                    </p>
                    <img
                      src="/icon/downarrow.png"
                      className="h-1.5 w-2 my-auto"
                      alt="downarrow picture"
                    />
                  </div>
                  {dropdownVisible && (
                    <div
                      className={`absolute left-1/2 transform -translate-x-1/2 w-48 bg-transparent backdrop-blur-lg shadow-lg rounded-lg ${animationClass}`}
                    >
                      <Link to="/usersetting">
                        <button className="bg-primary mt-3 px-2 h-10 sm:h-14 rounded-xl block w-full hover:bg-[#f1ae1c] hover:text-accent">
                          <p className="font-sora text-[12px] sm:text-[16px] text-black text-center font-medium leading-[23.94px] hover:text-accent">
                            Personal Settings
                          </p>
                        </button>
                      </Link>
                      <Link to="/referral">
                        <button className="bg-primary mt-3 px-2 h-10 sm:h-14 rounded-xl block w-full hover:bg-[#f1ae1c] hover:text-accent">
                          <p className="font-sora text-[12px] sm:text-[16px] text-black text-center font-medium leading-[23.94px] hover:text-accent">
                            Referral Program
                          </p>
                        </button>
                      </Link>
                      <Link to="/notificationsetting">
                        <button className="bg-primary mt-3 px-2 h-10 sm:h-14 rounded-xl block w-full hover:bg-[#f1ae1c] hover:text-accent">
                          <p className="font-sora text-[12px] sm:text-[16px] text-black text-center font-medium leading-[23.94px] hover:text-accent">
                            Notification Center
                          </p>
                        </button>
                      </Link>
                    </div>
                  )}
                </div> */}
              </div>
            ) : (
              ""
            )}
            {!user ? (
              <div className="mt-8">
                {/* <Link to="/signup">
                  <button className="bg-primary mt-3 px-2 h-10 sm:h-14 rounded-xl block w-full hover:bg-[#f1ae1c] hover:text-accent">
                    <p className="font-sora text-[12px] sm:text-[16px] text-black text-center font-medium leading-[23.94px] hover:text-accent">
                      Connect Wallet
                    </p>
                  </button>
                </Link> */}
                <Link to="/login">
                  <button className="bg-primary mt-3 px-2 h-10 sm:h-14 rounded-xl block w-full hover:bg-[#f1ae1c] hover:text-accent">
                    <p className="font-helvetica text-[12px] sm:text-[16px] text-black text-center font-medium leading-[23.94px] hover:text-accent">
                      Sign In
                    </p>
                  </button>
                </Link>
              </div>
            ) : (
              <div className="mt-8">
                <button
                  onClick={logout}
                  className="bg-primary mt-3 px-2 h-10 sm:h-14 rounded-xl block w-full hover:bg-[#f1ae1c] hover:text-accent"
                >
                  <p className="font-helvetica text-[12px] sm:text-[16px] text-black text-center font-medium leading-[23.94px] hover:text-accent">
                    {loading ? (
                      <img
                        src="/icon/loader.svg"
                        alt="Loading..."
                        className="w-7 mx-auto h-7 animate-spin"
                      />
                    ) : (
                      "Logout"
                    )}
                  </p>
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
