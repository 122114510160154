import React from 'react'
import Routes from './pages/Routes'
 import "./App.css";
import Notification from './components/Notification';
import { UserProvider } from './contexts/UserContext';
function App() {
  return (
    <UserProvider>
    <Notification />
    <Routes/>
    </UserProvider>
  )
}

export default App
// import React, { useEffect } from 'react';
// import SumsubWebSdk from '@sumsub/websdk';

// const App = () => {
//     useEffect(() => {
//         const loadSumsub = async () => {
//             try {
//                 const response = await fetch('http://localhost:3000/sumsub/token', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({ userId: 'unique_user_id' }),
//                 });

//                 const data = await response.json();

//                 if (data.token) {
//                     const sdk = SumsubWebSdk.init(data.token, {
//                         lang: 'en',
//                         email: 'user@example.com',
//                         phone: '+1234567890',
//                         onMessage: (type, payload) => {
//                             console.log('Message:', type, payload);
//                         },
//                         onError: (error) => {
//                             console.error('Error:', error);
//                         },
//                         onLoaded: () => {
//                             console.log('SDK Loaded');
//                         },
//                     });

//                     sdk.launch('#sumsub-container');
//                 } else {
//                     console.error('Token generation failed:', data.error);
//                 }
//             } catch (error) {
//                 console.error('Failed to load Sumsub:', error);
//             }
//         };

//         loadSumsub();
//     }, []);

//     return <div id="sumsub-container" style={{ width: '100%', height: '500px', backgroundColor: 'lightgrey' }} />;
// };

// export default App;

