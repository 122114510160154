import React from "react";

const Images = {
  close: "./icon/close.svg",
  dolphin: "./icon/dolphin.svg",
};

function AlgoPoolModal({ show, onClose }) {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed z-50 inset-0 flex items-center  justify-center ">
      <div className="bg-Accent p-4 rounded-lg w-[400px] sm:w-auto animate-fadeIn">
        <div className="bg-black pt-10 2xl:pt-20 border rounded-3xl border-primary">
          <div className="relative">
            <img
              src={Images.close}
              width={30}
              height={30}
              onClick={onClose}
              className="absolute top-[-25px] 2xl:top-[-60px] right-6 cursor-pointer"
              alt="close-icon"
            />
          </div>

          <div>
            <p className="font-poppins text-[20px] sm:text-[28px] px-20 2xl:px-60 font-semibold leading-[30px] text-center text-accent ">
              AlgoX Capital
            </p>
            <p className="font-poppins text-[20px] sm:text-[26px] font-medium leading-[39px] text-center mx-auto border-b max-w-48 border-primary mt-4 2xl:mt-12 mb-6 2xl:mb-16 text-primary ">
              Algo Pool 1
            </p>
            <p className="font-poppins text-2xl font-semibold leading-[30px] text-center text-accent">
              E-Mail Authentication
            </p>
            <div className="flex space-x-2 sm:space-x-4 md:space-x-5 mt-6 justify-center">
              {[...Array(6)].map((_, index) => (
                <input
                  key={index}
                  className="w-6 h-6 sm:w-8 sm:h-8 md:w-8 md:h-8 border-2 border-primary text-primary bg-[#222222] rounded-md text-center text-lg sm:text-xl"
                  type="text"
                  maxLength="1"
                  aria-label={`OTP digit ${index + 1}`}
                />
              ))}
            </div>
            <p className="font-poppins text-2xl font-semibold leading-[30px] text-center mt-14 text-accent">
              Google Two Factor Authentication
            </p>
            <div className="flex space-x-2 sm:space-x-4 md:space-x-5 mt-9 justify-center">
              {[...Array(6)].map((_, index) => (
                <input
                  key={index}
                  className="w-6 h-6 sm:w-8 sm:h-8 md:w-8 md:h-8 border-2 border-primary text-primary bg-[#222222] rounded-md text-center text-lg sm:text-xl"
                  type="text"
                  maxLength="1"
                  aria-label={`OTP digit ${index + 1}`}
                />
              ))}
            </div>
          </div>
          <div className="flex gap-8 justify-center">
            <button className="border border-primary text-accent rounded-xl mt-6 2xl:mt-16 mb-4 2xl:mb-12 font-poppins text-[20px] font-medium leading-[30px] text-center py-3 px-10">
              Cancel
            </button>
            <button className="bg-primary rounded-xl mt-6 2xl:mt-16 mb-4 2xl:mb-12 font-poppins text-[20px] font-medium leading-[30px] text-center py-3 px-14">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlgoPoolModal;
