import React from "react";

const Images = {
  close: "./icon/close.svg",
  dolphin: "./icon/dolphin.svg",
};

function BankDepositModal({ show, onClose }) {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed z-50 inset-0 flex items-center  justify-center ">
      <div className="bg-Accent p-4 rounded-lg w-[400px] sm:w-auto animate-fadeIn">
        <div className="bg-black pt-10 2xl:pt-20 border rounded-3xl border-primary">
          <div className="relative">
            <img
              src={Images.close}
              width={30}
              height={30}
              onClick={onClose}
              className="absolute top-[-25px] 2xl:top-[-60px] right-6 cursor-pointer"
              alt="close-icon"
            />
          </div>

          <div>
            <p className="font-poppins text-[20px] sm:text-[28px] px-20 2xl:px-60 font-semibold leading-[30px] text-center text-accent ">
              AlgoX Capital
            </p>
            <p className="font-poppins text-[20px] sm:text-[26px] font-medium leading-[39px] text-center mx-auto border-b max-w-44 border-primary mt-4 2xl:mt-12 mb-6 2xl:mb-16 text-primary ">
            Bank transfer
            </p>
            <p className="font-poppins ml-[15%] text-[20px] font-normal leading-[30px] text-left text-accent">
              IBAN
            </p>
            <div className="flex justify-center">
              <input
                placeholder="DE51 1001 0010 0085 2648 15"
                className="bg-gray text-accent w-[70%] rounded-lg h-12 px-4"
              />
            </div>
          </div>
          <p className="font-poppins ml-[15%] text-[20px] font-normal mt-4 leading-[30px] text-left text-accent">
            BIC
          </p>
          <div className="flex justify-center">
            <input
              placeholder="PCDECFFK"
              className="bg-gray text-accent w-[70%] rounded-lg h-12 px-4"
            />
          </div>

          <p className="font-poppins ml-[15%] mt-4 text-[20px] font-normal leading-[30px] text-left text-accent">
            Transaction ID
          </p>
          <div className="flex justify-center">
            <input
              placeholder="123 456 789 D"
              className="bg-gray text-accent w-[70%] rounded-lg h-12 px-4"
            />
          </div>
          <div className="flex justify-center">
            <button className="bg-primary rounded-xl mt-6 2xl:mt-16 mb-4 2xl:mb-12 font-poppins text-[20px] font-medium leading-[30px] text-center py-3 px-16">
            Deposit Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankDepositModal;
